import { BoundCanProps } from "@casl/react";
import { AppAbility, Can } from "./casl";
import { Alert } from "@mui/material";

export const ThrowUnlessCan = ({ children, ...props }: BoundCanProps<AppAbility>) => {
  return (
    <Can {...props} passThrough>
      {allowed =>
        allowed ? (
          (children as React.ReactNode)
        ) : (
          <Alert severity="error">You are not allowed.</Alert>
        )
      }
    </Can>
  );
};
