import { useAtom, useSetAtom } from "jotai";
import { useQueryCustomerGroups } from "../../api/CustomerGroup";
import {
  customerGroupFilterAtom,
  globalFilterAtom,
  queryTypeFilterAtom
} from "../../api/OrderFitting";
import { Card, debounce, Tab, Tabs, TextField } from "@mui/material";

export const TabsFilter = () => {
  const { data: customerGroups } = useQueryCustomerGroups();

  const [customerGroupFilter, setCustomerGroupFilterParam] = useAtom(customerGroupFilterAtom);
  const [queryTypeFilter, setQueryTypeFilter] = useAtom(queryTypeFilterAtom);

  const setGlobalFilter = useSetAtom(globalFilterAtom);

  const handleTabsOnChange = (event: React.SyntheticEvent, value: any) => {
    const [query_type, customer_group_uuid] = value.split(":");

    setQueryTypeFilter(query_type);
    setCustomerGroupFilterParam(customer_group_uuid);
  };

  return (
    <Card sx={{ mb: 3, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      <Tabs
        value={[queryTypeFilter, customerGroupFilter].filter(Boolean).join(":")}
        onChange={handleTabsOnChange}
      >
        <Tab iconPosition="end" label="Pending" value={["pending"].join(":")} />
        <Tab iconPosition="end" label="Combined" value={["booked"].join(":")} />
        {customerGroups?.map((customerGroup: any) => {
          return (
            <Tab
              iconPosition="end"
              key={customerGroup.uuid}
              label={customerGroup.name}
              value={["booked", customerGroup.uuid].join(":")}
            />
          );
        })}
        <Tab iconPosition="end" label="Hold" value={["hold"].join(":")} />
        <Tab iconPosition="end" label="Runs" value={["runs"].join(":")} />
      </Tabs>

      <TextField
        name="search"
        placeholder="Search..."
        onChange={debounce(e => setGlobalFilter(e.target.value?.trim() || undefined), 1000)}
        type="text"
        variant="standard"
        sx={{ mx: 5 }}
      />
    </Card>
  );
};
