import React from "react";
import styled from "@emotion/styled";
import { v4 } from "uuid";
import { Divider as MuiDivider, Paper as MuiPaper } from "@mui/material";
import { spacing } from "@mui/system";
import ProductLineForm from "./ProductLineForm";
import { useMutationAddProductLine } from "../../api/ProductLine";
import PageTitleWithLoading from "../../components/PageTitleWithLoading";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const ProductLineAdd = () => {
  const mutation = useMutationAddProductLine(v4());

  return (
    <Paper p={4}>
      <PageTitleWithLoading title="Add Product Line" />

      <Divider my={6} />

      <ProductLineForm mutation={mutation} />
    </Paper>
  );
};

export default ProductLineAdd;
