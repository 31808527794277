import DataExportList from "./DataExportList.tsx";

const routes = [
  {
    path: "/data-export",
    element: <DataExportList />
  }
];

export default routes;
