import React from "react";
import styled from "@emotion/styled";
import { Divider as MuiDivider, Paper as MuiPaper } from "@mui/material";
import { spacing } from "@mui/system";
import ProductForm from "./ProductForm";
import { useMutationEditProduct } from "../../api/Product";
import PageTitleWithLoading from "../../components/PageTitleWithLoading";
import { useParams } from "react-router-dom";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const ProductEdit = () => {
  const { uuid } = useParams<string>();
  const mutation = useMutationEditProduct(uuid ?? "");

  return (
    <Paper p={4}>
      <PageTitleWithLoading title="Edit Product" />

      <Divider my={6} />

      <ProductForm mutation={mutation} uuid={uuid} isEdit />
    </Paper>
  );
};

export default ProductEdit;
