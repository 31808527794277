import React from "react";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import confirm from "../../components/Confirm";
import { generatePath, Link as RouterLink } from "react-router-dom";
import { IconButton, Tooltip } from "@mui/material";
import {
  useMutationBulkDeleteOrderStatus,
  useMutationDeleteOrderStatus,
  useQueryOrderStatuses
} from "../../api/OrderStatus";
import useAuth from "../../hooks/useAuth";
import { USER_ROLES } from "../../constants";
import { withDataGridPageProvider } from "../../contexts/DataGridPageContext";
import { GridColDef } from "@mui/x-data-grid-pro";
import DataGridPage from "../../components/data-grid/DataGridPage";
import useHealthChecker from "../../hooks/useHealthChecker";

const OrderStatusList = () => {
  const { mutateAsync: deleteItem } = useMutationDeleteOrderStatus();
  const { checkRolesAccess } = useAuth();
  const { isSuspendMutations } = useHealthChecker();

  const columns: GridColDef[] = [
    { field: "name", headerName: "Name", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      filterable: false,
      sortable: false,
      headerAlign: "right",
      align: "right",
      renderCell: ({ row }) => {
        const { uuid } = row;

        return [
          <Tooltip title="Edit" key={`edit-${uuid}`}>
            <IconButton
              size="large"
              component={RouterLink}
              to={generatePath("/order-status/:uuid/edit", { uuid })}
              disabled={!checkRolesAccess([USER_ROLES.ADMIN])}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>,
          <Tooltip title="Delete" key={`delete-${uuid}`}>
            <IconButton
              size="large"
              onClick={async () => {
                if (
                  await confirm({
                    confirmation: "You are about to delete. Are you sure?",
                    options: {
                      title: "Confirm"
                    }
                  })
                ) {
                  await deleteItem(uuid);
                }
              }}
              disabled={!checkRolesAccess([USER_ROLES.ADMIN]) || isSuspendMutations}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ];
      },
      flex: 1
    }
  ];

  return (
    <DataGridPage
      dataGridProps={{
        columns
      }}
    />
  );
};

export default withDataGridPageProvider(OrderStatusList, {
  useQueryList: useQueryOrderStatuses,
  useMutationBulkDelete: useMutationBulkDeleteOrderStatus,
  pageTitle: "Order Status",
  addButtonNavigateTo: "/order-status/add"
});
