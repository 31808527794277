import axios from "axios";
import qs from "qs";
import { apiBaseURL } from "../config.ts";

const axiosInstance = axios.create({
  baseURL: apiBaseURL,
  paramsSerializer: params =>
    qs.stringify(params, {
      // arrayFormat: "comma" note: this caused error in some pages.
    })
});

export default axiosInstance;
