import { GridFilterModel } from "@mui/x-data-grid-pro";
import { encodeToSequelizeQP } from "../utils/encodeToSequelizeQP.ts";
import { assign, isEmpty } from "lodash";

export const useDataGridFilterModelQueryParams = (filterModel?: GridFilterModel) => {
  return assign(
    {},
    ...(filterModel?.items
      ?.map(item => {
        if (!["isEmpty", "isNotEmpty"].includes(item.operator) && item.value === undefined) {
          return false;
        }

        return {
          [item.field.includes(".") ? `$${item.field}$` : item.field]: encodeToSequelizeQP(item)
        };
      })
      ?.filter(Boolean) ?? []),
    !isEmpty(filterModel?.quickFilterValues) && {
      quickFilter: filterModel?.quickFilterValues?.join(" ")
    }
  );
};
