import "@mui/lab/themeAugmentation";

import { createTheme as createMuiTheme } from "@mui/material/styles";
import variants from "./variants";
import typography from "./typography";
import breakpoints from "./breakpoints";
import components from "./components";
import shadows from "./shadows";

const createTheme = (name: string) => {
  let themeConfig = variants.find(variant => variant.name === name);

  if (!themeConfig) {
    themeConfig = variants[0];
  }

  return createMuiTheme(
    {
      spacing: 4,
      breakpoints: breakpoints,
      // @ts-expect-error quick
      components: components,
      typography: typography,
      shadows: shadows,
      palette: themeConfig.palette,
      mixins: {
        // @ts-expect-error quick
        MuiDataGrid: {
          // Pinned columns sections
          pinnedBackground: "#ffffff",
          // Headers, and top & bottom fixed rows
          containerBackground: "#ffffff"
        }
      }
    },
    {
      name: themeConfig.name,
      header: themeConfig.header,
      footer: themeConfig.footer,
      sidebar: themeConfig.sidebar
    }
  );
};

export default createTheme;
