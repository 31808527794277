import React, { Suspense } from "react";

import styled from "@emotion/styled";
import { useParams } from "react-router-dom";
import { Divider as MuiDivider, Stack } from "@mui/material";
import { spacing } from "@mui/system";
import PageTitleWithLoading from "../../components/PageTitleWithLoading";
import EventsTimelineCard from "../customer/EventLogsCard";
import { useQueryOneProduct } from "../../api/Product";
import ProductConfigCard from "./ProductConfigCard";
import ProductConfigUploaderCard from "./ProductConfigUploaderCard";
import Loader from "../../components/Loader";

const Divider = styled(MuiDivider)(spacing);

function ProductDetails() {
  const { uuid } = useParams<string>();
  const { data } = useQueryOneProduct(uuid);

  return (
    <>
      <PageTitleWithLoading title={`${data?.code}: ${data?.description}`} />

      <Divider my={6} />

      <Stack
        direction="row"
        spacing={6}
        justifyContent="flex-start"
        alignItems="stretch"
        useFlexGap
        flexWrap="wrap"
      >
        <ProductConfigUploaderCard />
        <Suspense fallback={<Loader />}>
          <ProductConfigCard />
        </Suspense>
        {/*<EventsTimelineCard stream="products" aggregate_id={uuid} />*/}
      </Stack>
    </>
  );
}

export default ProductDetails;
