import { useContext } from "react";
import { HealthCheckerContext } from "../contexts/HealthCheckerContext";

const useHealthChecker = () => {
  const context = useContext(HealthCheckerContext);

  if (!context) throw new Error("HealthCheckerContext must be placed within HealthCheckerProvider");

  return context;
};

export default useHealthChecker;
