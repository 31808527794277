import { FormControl, FormHelperText, InputLabel, OutlinedInput } from "@mui/material";
import { Field, FieldProps, useFormikContext } from "formik";
import { isEqual } from "lodash";
import React from "react";
import { RenderConfigProps } from "../OrderLineItemForm";

const IntegerRangeInput: React.VFC<RenderConfigProps> = ({ conf }) => {
  const { isSubmitting } = useFormikContext();

  const fieldName = `config_values[${conf["Unique Option Name"]}]`;
  const { warning, error: errorParams } = conf["Answer Options"];
  const ignoreWarning = isEqual(
    {
      min: warning?.min,
      max: warning?.max
    },
    {
      min: errorParams?.min,
      max: errorParams?.max
    }
  );

  return (
    <Field name={fieldName}>
      {({ field, meta: { error } }: FieldProps) => {
        const { value } = field;
        const warningMsg =
          ignoreWarning || (!!value && warning.min <= value && value <= warning.max)
            ? undefined
            : warning.msg;

        return (
          <FormControl
            error={!!error}
            disabled={isSubmitting}
            size="small"
            margin="normal"
            fullWidth
            required={conf["Is Required"] === "Y"}
          >
            <InputLabel htmlFor={fieldName}>{conf["Question Heading"]}</InputLabel>
            <OutlinedInput id={fieldName} type="number" {...field} value={field.value ?? ""} />
            <FormHelperText>{conf["Help Message"]}</FormHelperText>
            <FormHelperText>{error ?? warningMsg}</FormHelperText>
          </FormControl>
        );
      }}
    </Field>
  );
};

export default IntegerRangeInput;
