import PaymentTermConfigList from "./PaymentTermConfigList";
import PaymentTermConfigAdd from "./PaymentTermConfigAdd";
import PaymentTermConfigEdit from "./PaymentTermConfigEdit";

const routes = [
  {
    path: "/payment-term-config",
    element: <PaymentTermConfigList />
  },
  {
    path: "/payment-term-config/add",
    element: <PaymentTermConfigAdd />
  },
  {
    path: "/payment-term-config/:uuid/edit",
    element: <PaymentTermConfigEdit />
  }
];

export default routes;
