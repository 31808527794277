import { useQueryOrderFormOptionsForOutstanding } from "../../api/Order.ts";
import {
  gridFilterModelSelector,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  useGridApiContext,
  useGridSelector
} from "@mui/x-data-grid-pro";
import React, { Suspense, useDeferredValue } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  ToggleButton,
  ToggleButtonGroup
} from "@mui/material";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { endOfDay, isEqual, isValid, parseISO } from "date-fns";
import { ToolbarStatistics } from "./ToolbarStatistics.tsx";
import Loader from "../../components/Loader.tsx";
import { isEmpty } from "lodash";

export const DataGridToolbar = () => {
  const apiRef = useGridApiContext();

  const filterModel = useGridSelector(apiRef, gridFilterModelSelector);
  const filterItemQuoteAcceptedAt = filterModel?.items?.find(
    item => item.field === "quote_accepted_at"
  );
  const deferredFilterAcceptedAt = useDeferredValue(
    filterItemQuoteAcceptedAt?.value?.map((val: string | Date) =>
      isValid(val) ? val : parseISO(val as string)
    ) ?? null
  );

  const filterItemCustomerGroup = filterModel?.items?.find(
    item => item.field === "customer.customer_group.id" && item.operator === "is"
  );
  const deferredFilterCustomerGroup = useDeferredValue(filterItemCustomerGroup?.value ?? null);

  const filterItemOrderType = filterModel?.items?.find(
    item => item.field === "order_type" && item.operator === "isAnyOf"
  );
  const deferredFilterOrderType = useDeferredValue(filterItemOrderType?.value ?? []);

  const filterItemExcludeZeroReceipts = filterModel?.items?.find(
    item => item.field === "transactions_sum" && item.operator === ">"
  );
  const deferredFilterExcludeZeroReceipts = useDeferredValue(!!filterItemExcludeZeroReceipts);

  const { data: formOptions } = useQueryOrderFormOptionsForOutstanding();

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="flex-end"
      flexWrap="wrap"
      gap={3}
      m={3}
      my={4}
    >
      <Stack gap={3} direction="row" flexWrap="wrap" flexGrow={1}>
        <Suspense fallback={<Loader />}>
          <ToolbarStatistics />
        </Suspense>
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        gap={3}
      >
        <FormControl size="small">
          <InputLabel
            id="filter-customer-group-label"
            shrink
            margin="dense"
            sx={{ backgroundColor: "white", padding: 0.5 }}
          >
            Customer Group
          </InputLabel>
          <ToggleButtonGroup
            value={deferredFilterCustomerGroup}
            size="small"
            exclusive
            onChange={(e, value) => {
              if (value === null && filterItemCustomerGroup) {
                apiRef.current.deleteFilterItem(filterItemCustomerGroup);
              } else {
                apiRef.current.upsertFilterItem({
                  field: "customer.customer_group.id",
                  value,
                  id: "customer_group",
                  operator: "is"
                });
              }
            }}
          >
            {formOptions?.customer_groups?.map((customerGroup: any) => {
              return (
                <ToggleButton key={customerGroup.id} value={customerGroup.id}>
                  {customerGroup.name}
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        </FormControl>

        <DateRangePicker
          localeText={{ start: "Accepted From", end: "To" }}
          value={deferredFilterAcceptedAt}
          onChange={value => {
            if (value.every(val => val !== null)) {
              const [D1, D2] = value as [Date, Date];

              apiRef.current.upsertFilterItem({
                field: "quote_accepted_at",
                value: [D1, isEqual(D1, D2) ? endOfDay(D2) : D2],
                id: "accepted_date_range",
                operator: "date_between"
              });
            }
          }}
          slotProps={{
            textField: {
              size: "small",
              sx: { width: 100 }
            }
          }}
        />

        <FormControl size="small" sx={{ minWidth: 100 }}>
          <InputLabel id="filter-order-type-label" shrink>
            Order Type
          </InputLabel>
          <Select
            labelId="filter-order-type-label"
            value={deferredFilterOrderType}
            multiple
            displayEmpty
            label="Order Type"
            renderValue={selected => {
              if (selected.length === 0) {
                return <em>Filter</em>;
              }

              return selected.join(", ");
            }}
            onChange={e => {
              const value = e.target.value;

              if (isEmpty(value) && filterItemOrderType) {
                apiRef.current.deleteFilterItem(filterItemOrderType);
              } else {
                apiRef.current.upsertFilterItem({
                  field: "order_type",
                  value,
                  id: "order_type",
                  operator: "isAnyOf"
                });
              }
            }}
          >
            <MenuItem value="" disabled>
              <em>Order Type</em>
            </MenuItem>
            {formOptions?.order_types
              ?.filter(order_type => order_type !== "quote")
              ?.map(order_type => {
                return (
                  <MenuItem key={order_type} value={order_type}>
                    {order_type}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>

        <FormControlLabel
          control={
            <Checkbox
              size="small"
              checked={deferredFilterExcludeZeroReceipts}
              onChange={e => {
                if (!e.target.checked && filterItemExcludeZeroReceipts) {
                  apiRef.current.deleteFilterItem(filterItemExcludeZeroReceipts);
                } else {
                  apiRef.current.upsertFilterItem({
                    field: "transactions_sum",
                    id: "exclude_zero_receipts",
                    operator: ">",
                    value: 0
                  });
                }
              }}
            />
          }
          label="Exclude $0.00 receipts"
          sx={{ width: 140 }}
        />

        <GridToolbarContainer>
          <GridToolbarQuickFilter placeholder="Order #" />
          <GridToolbarFilterButton />
        </GridToolbarContainer>
      </Stack>
    </Stack>
  );
};
