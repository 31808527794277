import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import styled from "@emotion/styled";

import { Box, CssBaseline } from "@mui/material";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";

import { THEMES } from "../constants";
import createTheme from "../theme";

import GlobalStyle from "../components/GlobalStyle";
import { QueryErrorResetBoundary } from "@tanstack/react-query";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallbackMessage from "../components/ErrorFallbackMessage";
import PageLoading from "../components/PageLoading";

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

interface PresentationType {
  children?: React.ReactNode;
}

const Presentation: React.FC<PresentationType> = ({ children }) => {
  return (
    <MuiThemeProvider theme={createTheme(THEMES.DEFAULT)}>
      <Root>
        <CssBaseline />
        <GlobalStyle />
        <AppContent>
          <Box m={6}>
            {/* Had to do it this way with <Suspense> element as parent for QueryErrorResetBoundary due to some weird error: "A component suspended while responding to synchronous input. This will cause the UI to be replaced with a loading indicator. To fix, updates that suspend should be wrapped with startTransition."*/}
            <Suspense fallback={<PageLoading />}>
              <QueryErrorResetBoundary>
                {({ reset }) => (
                  <ErrorBoundary
                    onReset={reset}
                    // onError={(err, info) => console.error({ err, info })}
                    FallbackComponent={ErrorFallbackMessage}
                  >
                    {children}
                    <Outlet />
                  </ErrorBoundary>
                )}
              </QueryErrorResetBoundary>
            </Suspense>
          </Box>
        </AppContent>
      </Root>
    </MuiThemeProvider>
  );
};

export default Presentation;
