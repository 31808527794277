import React from "react";

import styled from "@emotion/styled";
import { useParams } from "react-router-dom";
import { Divider as MuiDivider, Stack } from "@mui/material";
import { spacing } from "@mui/system";
import PageTitleWithLoading from "../../components/PageTitleWithLoading";
import EventsTimelineCard from "../customer/EventLogsCard";
import { useQueryOneInventoryItem } from "../../api/InventoryItem";
import PriceGridUploaderCard from "./PriceGridUploaderCard";
import PriceGridCard from "./PriceGridCard";

const Divider = styled(MuiDivider)(spacing);

function InventoryItemDetails() {
  const { uuid } = useParams<string>();
  const { data: inventoryItem } = useQueryOneInventoryItem(uuid);
  const productSoldByGridUUID = "fcd532ec-7e65-418b-9102-0a970dc84da2";
  const isSoldByPriceGrid = inventoryItem?.product_sold_by?.uuid === productSoldByGridUUID;

  return (
    <>
      <PageTitleWithLoading title={`${inventoryItem?.code}: ${inventoryItem?.description}`} />

      <Divider my={6} />

      <Stack
        direction="row"
        spacing={6}
        justifyContent="flex-start"
        alignItems="stretch"
        useFlexGap
        flexWrap="wrap"
      >
        {isSoldByPriceGrid && (
          <>
            <PriceGridUploaderCard />
            <PriceGridCard />
          </>
        )}
        {/*<EventsTimelineCard stream="inventory_items" aggregate_id={uuid} />*/}
      </Stack>
    </>
  );
}

export default InventoryItemDetails;
