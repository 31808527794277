import { MatchConditions, PureAbility, RawRuleOf } from "@casl/ability";
import { createContext } from "react";
import { createContextualCan } from "@casl/react";
import { Abilities } from "aerp-types/types/casl-ability.mjs";

export type AppAbility = PureAbility<Abilities, any>;
const lambdaMatcher = (matchConditions: MatchConditions) => matchConditions;

export const createAbility = (rules: RawRuleOf<AppAbility>[]) => {
  return new PureAbility<Abilities, any>(rules, {
    conditionsMatcher: lambdaMatcher,
    anyAction: "Manage",
    anySubjectType: "all"
  });
};

export const caslAbility = createAbility([]);

export const AbilityContext = createContext(caslAbility);
export const Can = createContextualCan(AbilityContext.Consumer);
