import React from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { bindMenu, bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { orderCopyConfirmationDialog } from "../OrderCopyDialog";
import {
  useMutationCancelOrder,
  useMutationCopyOrder,
  useMutationOrderSetIsActive,
  useMutationSetCreditAmountOrder,
  useMutationSplitOrder,
  useQueryOneOrder
} from "../../../api/Order";
import { orderNewVariantConfirmationDialog } from "./OrderNewVariantDialog";
import { orderApplyCreditInputConfirmationDialog } from "./OrderApplyCreditInputDialog";
import confirm from "../../../components/Confirm";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSnackbar } from "notistack";
import { attachmentsDialog } from "./AttachmentsDialog.tsx";
import { orderSplitConfirmationDialog } from "../OrderSplitDialog.tsx";
import { afterOrderSplitConfirmationDialog } from "../AfterOrderSplitDialog.tsx";
import useLoadingBackdrop from "../../../hooks/useLoadingBackdrop.ts";
import { orderFinalizedPDFDialog } from "./OrderFinalizedPDFDialog.tsx";

export const OrderFinalizedOptionsButton = () => {
  const { uuid } = useParams<string>();
  const { data: order } = useQueryOneOrder(uuid);

  const { mutateAsync: copyOrder } = useMutationCopyOrder();
  const { mutateAsync: setCreditAmountOrder } = useMutationSetCreditAmountOrder();
  const { mutateAsync: cancelOrder } = useMutationCancelOrder();
  const { mutateAsync: splitOrder } = useMutationSplitOrder();
  const { mutateAsync: setIsActiveOrder } = useMutationOrderSetIsActive();
  const navigate = useNavigate();

  const loadingBackdropState = useLoadingBackdrop();

  const popupState = usePopupState({
    variant: "popover",
    popupId: `order-${uuid}`
  });

  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      <Button
        variant="outlined"
        disableElevation
        endIcon={<KeyboardArrowDownIcon />}
        {...bindTrigger(popupState)}
      >
        Options
      </Button>
      <Menu {...bindMenu(popupState)}>
        <CopyToClipboard
          text={`${window.location.origin}${generatePath("/guest/order/:uuid", {
            uuid: uuid ?? null
          })}`}
          onCopy={() =>
            enqueueSnackbar("Copied", {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right"
              }
            })
          }
        >
          <MenuItem onClick={() => popupState.close()}>Share</MenuItem>
        </CopyToClipboard>
        <MenuItem
          onClick={async () => {
            popupState.close();
            await attachmentsDialog({
              title: "Internal Attachments",
              is_internal: true,
              order_id: order?.id,
              order_uuid: order?.uuid
            });
          }}
        >
          Internal Attachments
        </MenuItem>
        <MenuItem
          onClick={async () => {
            popupState.close();
            await attachmentsDialog({
              title: "Customer Attachments",
              description:
                "WARNING: Uploaded files here will be available publicly to customer. Below are the list of files uploaded by the customer",
              is_internal: false,
              order_id: order?.id,
              order_uuid: order?.uuid
            });
          }}
        >
          Customer Attachments
        </MenuItem>
        <MenuItem
          onClick={async () => {
            popupState.close();
            uuid &&
              (await orderFinalizedPDFDialog({
                order_uuid: uuid
              }));
          }}
          divider
        >
          Print
        </MenuItem>
        <MenuItem
          disabled={!order?.is_invoiced}
          onClick={async () => {
            popupState.close();

            const creditAmount = await orderApplyCreditInputConfirmationDialog({
              default_amount: order?.credit ?? order?.sold_price,
              default_note: order?.credit_note,
              can_remove_credit: order?.credit !== null
            });

            if (creditAmount === false || !uuid) {
              return;
            }

            await setCreditAmountOrder({
              uuid,
              credit: creditAmount.credit,
              credit_note: creditAmount.credit_note
            });
          }}
          divider
        >
          Apply Credit
        </MenuItem>
        <MenuItem
          divider
          onClick={async () => {
            popupState.close();

            const order_type = await orderNewVariantConfirmationDialog({
              order_uuid: uuid
            });

            if (order_type === false || !uuid) {
              return;
            }

            let order_line_item_uuids;

            switch (order_type) {
              case "quote":
              case "adjustment":
              case "rectification":
              case "service":
              case "warranty": {
                order_line_item_uuids = await orderCopyConfirmationDialog({
                  order_id: order?.id
                });

                if (order_line_item_uuids === false || !uuid) {
                  return;
                }

                break;
              }
              case "split": {
                const split = await orderSplitConfirmationDialog({
                  order_id: order?.id
                });

                if (split === false || !uuid) {
                  return;
                }

                loadingBackdropState.increment();

                const response = await splitOrder({
                  uuid,
                  ...split
                }).finally(() => loadingBackdropState.decrement());

                if (response.split_orders) {
                  const shouldArchive = await afterOrderSplitConfirmationDialog({
                    order_id: order?.id,
                    reference_number: order?.reference_number,
                    customer_first_name: order?.customer?.first_name,
                    split_orders: response.split_orders
                  });

                  if (shouldArchive) {
                    loadingBackdropState.increment();

                    await setIsActiveOrder({
                      uuid,
                      active: null
                    }).finally(() => loadingBackdropState.decrement());
                  }
                }

                navigate("/order");

                return;
              }
            }

            loadingBackdropState.increment();

            const response = await copyOrder({
              uuid,
              order_line_item_uuids,
              order_type
            }).finally(() => loadingBackdropState.decrement());

            navigate(
              generatePath(
                ["rectification", "warranty", "service"].includes(order_type)
                  ? "/order/:uuid/edit/step-3"
                  : "/order/:uuid/edit",
                {
                  uuid: response.copied_uuid
                }
              )
            );

            return;
          }}
        >
          Create
        </MenuItem>
        <MenuItem
          disabled={order?.is_invoiced || !order?.quote_accepted_at}
          onClick={async () => {
            popupState.close();

            if (
              await confirm({
                confirmation: "You are about to cancel this. Are you sure?",
                options: {
                  title: "Confirm"
                }
              })
            ) {
              loadingBackdropState.increment();

              await cancelOrder({ uuid }).finally(() => loadingBackdropState.decrement());
            }
          }}
        >
          Cancel Order
        </MenuItem>
      </Menu>
    </>
  );
};
