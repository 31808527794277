import {
  useMutationAbstract,
  useMutationAddModel,
  useMutationBulkDeleteModelV2,
  useMutationDeleteModel,
  useMutationEditModel,
  useQueryModels,
  useQueryOneModel
} from "../hooks/useMutationFormAbstract";
import axios from "../utils/axios";
import sleep from "../utils/sleep";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

export const QUERY_KEY = "page";

export const useQueryPages = () => useQueryModels(QUERY_KEY);

export const useQueryOnePage = (uuid?: string) => useQueryOneModel<any>(QUERY_KEY, uuid);

export const useMutationAddPage = (uuid: string) => useMutationAddModel(QUERY_KEY, uuid);

export const useMutationEditPage = (uuid: string) => useMutationEditModel(QUERY_KEY, uuid);

export const useMutationPageSetPublishedAt = () => {
  interface Input {
    uuid: string;
    published_at: Date | null;
  }

  return useMutationAbstract<Input, unknown, Input>({
    mutationFn: ({ uuid, published_at }) =>
      axios
        .put(`/api/${QUERY_KEY}/${uuid}/set-published-at`, {
          published_at
        })
        .then(({ data }) => data)
        .then(async response => {
          // we have to do it this way to wait for event handlers to process changes
          await sleep(1500);

          return response;
        }),
    invalidateQueryKey: [QUERY_KEY]
  });
};

export const useMutationDeletePage = () => useMutationDeleteModel(QUERY_KEY);

export const useMutationBulkDeletePage = () => useMutationBulkDeleteModelV2(QUERY_KEY);

export const useQueryOnePagePublished = (slug?: string) =>
  useQuery<any, AxiosError>({
    enabled: !!slug,
    queryKey: [QUERY_KEY, "one", slug],
    queryFn: ({ signal }) =>
      axios
        .get(`/api/${QUERY_KEY}/${slug}/published`, {
          signal
        })
        .then(({ data }) => data),
    retry: (failureCount, error) => {
      return error?.response?.status !== 403;
    }
  });
