import { useQuery } from "@tanstack/react-query";
import assert from "node:assert";
import { processProductConfigXlsxImported } from "aldrin-erp-main-shared-functions";
import { parseWorkbook } from "../utils/parseWorkbook";

export const useProductConfigProcessor = (file: File | null, product: any | undefined) => {
  return useQuery({
    enabled: !!file && !!product,
    useErrorBoundary: false,
    queryKey: ["product-config-processor", { fileName: file?.name, product_id: product?.id }],
    queryFn: async () => {
      const { workbook, hash } = await parseWorkbook(file as File, { dense: true, sheets: 1 });

      assert.ok(workbook.SheetNames?.length === 2, "Workbook should have 2 sheets.");
      assert.ok(workbook.SheetNames[0] === "Help", 'First sheet should be named "Help"');
      assert.ok(workbook.SheetNames[1] === product.code, `Sheet name should be ${product.code}`);

      const worksheet = workbook.Sheets[product.code];

      const data = worksheet["!data"];
      assert.ok(data !== undefined, `Sheet ${product.code} is empty`);

      const configuration_options = processProductConfigXlsxImported(data);

      const { utils } = await import("xlsx");
      const html = utils.sheet_to_html(worksheet);

      return {
        configuration_options,
        html,
        hash
      };
    }
  });
};
