import { chain } from "lodash";
import { useMemo } from "react";

export const useOrderLineItemConfValues = (order_line_item?: any) =>
  useMemo(() => {
    return chain(order_line_item?.product_config?.config)
      .map(conf => {
        const isHeader = conf["Option Type"] === "H";
        const heading = conf["Question Heading"];
        const optionName = conf["Unique Option Name"];
        const value = order_line_item?.config_values?.[optionName];

        return {
          isHeader,
          heading,
          optionName,
          value
        };
      })
      .compact()
      .value();
  }, [order_line_item?.config_values, order_line_item?.product_config?.config]);
