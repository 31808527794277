import * as Yup from "yup";

const requiredHelpText = (field: string, validationSchema: Yup.AnyObjectSchema) => {
  const { fields } = validationSchema.describe();
  const fieldSchema = fields[field] ?? undefined;

  if (!fieldSchema) {
    return false;
  }

  // @ts-ignore
  const req = !!fieldSchema.tests.find(test => test.name === "required");
  // @ts-ignore
  return req || !fieldSchema.optional ? "Required" : false;
};

export default requiredHelpText;
