import {
  useMutationAddModel,
  useMutationBulkDeleteModelV2,
  useMutationDeleteModel,
  useMutationEditModel,
  useQueryModels,
  useQueryOneModel
} from "../hooks/useMutationFormAbstract";

export const useQueryLeadStatuses = () => useQueryModels("lead-status");

export const useQueryOneLeadStatus = (uuid?: string) => useQueryOneModel<any>("lead-status", uuid);

export const useMutationAddLeadStatus = (uuid: string) => useMutationAddModel("lead-status", uuid);

export const useMutationEditLeadStatus = (uuid: string) =>
  useMutationEditModel("lead-status", uuid);

export const useMutationDeleteLeadStatus = () => useMutationDeleteModel("lead-status");

export const useMutationBulkDeleteLeadStatus = () => useMutationBulkDeleteModelV2("lead-status");
