import { FormikHelpers } from "formik/dist/types";
import axios, { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { MutationResultType } from "./useMutationFormAbstract";
import * as Yup from "yup";
import { StringParam } from "serialize-query-params";
import { useQueryParam, withDefault } from "use-query-params";
import { MutateOptions } from "@tanstack/query-core";
import { isString } from "lodash";

export type AxiosErrorValidation = AxiosError<{ violations?: any; message?: string }>;

function useFormikSubmitHandler<Values>({
  mutation,
  validationSchema,
  navigate_to,
  mutateOptions
}: {
  mutation: MutationResultType;
  validationSchema: Yup.AnyObjectSchema;
  navigate_to?: string;
  mutateOptions?: MutateOptions<any, any, any, any>;
}) {
  const { mutateAsync } = mutation;
  const navigate = useNavigate();
  const [navigateToUrl] = useQueryParam("navigate_to", withDefault(StringParam, navigate_to));
  const { enqueueSnackbar } = useSnackbar();

  return async (values: Values, { setErrors }: FormikHelpers<Values>) => {
    try {
      await mutateAsync(
        validationSchema.cast(values, {
          stripUnknown: true
        }),
        {
          ...{
            onSuccess: () => navigateToUrl && navigate(navigateToUrl)
          },
          ...mutateOptions
        }
      );
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosErrorValidation;
        const responseData = axiosError.response?.data;

        setErrors(responseData?.violations ?? {});

        if (responseData?.message && isString(responseData?.message)) {
          enqueueSnackbar(responseData?.message, {
            variant: "error",
            preventDuplicate: true
          });
        }
      } else {
        // TODO: Rollbar error
        console.error(error);
        enqueueSnackbar("Something went wrong", {
          variant: "error",
          preventDuplicate: true
        });
      }
    }
  };
}

export default useFormikSubmitHandler;
