import React from "react";
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography
} from "@mui/material";
import { confirmable, ConfirmDialogProps } from "react-confirm";
import { createConfirmation } from "../../components/ReactConfirmMountPoint";
import { useOrderLineItemProductDesc } from "./components/useOrderLineItemProductDesc.ts";
import { useQueryOrderLineItemsByOrderId } from "../../api/OrderLineItem.ts";
import { useSnackbar } from "notistack";

export const AfterOrderSplitDialog = confirmable(
  ({
    show,
    proceed,
    reference_number,
    split_orders,
    customer_first_name
  }: ConfirmDialogProps<
    {
      order_id: number;
      reference_number: string;
      customer_first_name: string;
      split_orders: { uuid: string; reference_number: string; id: number }[];
    },
    boolean
  >) => {
    const handleClose = () => proceed(false);
    const { enqueueSnackbar } = useSnackbar();

    const [firstSplit, secondSplit] = split_orders;

    return (
      <Dialog
        open={show}
        disableEscapeKeyDown
        fullWidth
        maxWidth="lg"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Done</DialogTitle>
        <DialogContent>
          <Typography>Order {reference_number} split successfully</Typography>
          <br />
          <Card variant="outlined">
            <CardContent
              id={"content"}
              contentEditable
              onKeyDown={e => e.preventDefault()}
              onClick={() => {
                if (!window.getSelection) {
                  enqueueSnackbar("window.getSelection not supported", {
                    variant: "error"
                  });

                  return;
                }

                const contentEle = document.getElementById("content");
                if (contentEle) {
                  const range = document.createRange();
                  range.selectNodeContents(contentEle);
                  const selection = window.getSelection();
                  if (selection) {
                    selection.removeAllRanges();
                    selection.addRange(range);

                    document.execCommand("copy");

                    enqueueSnackbar("Copied!", {
                      variant: "success"
                    });
                  }
                }
              }}
            >
              <p>Thanks for your order!</p>

              <p>
                Hi {customer_first_name}, thank you for choosing OneSolomons for your home
                improvement project. Your order contains some products with different lead times and
                will be split into two installations. We will send you an email and SMS as soon as
                the first part of your order is ready for for installation. Please refer to these
                two new links for the progress of your order.
              </p>

              <p>Installation 1</p>
              <LineItems order_id={firstSplit.id} />
              <p>
                <a
                  href={`${window.location.origin}/guest/order/${firstSplit.uuid}`}
                  target="_blank"
                  rel="noreferrer"
                >{`${window.location.origin}/guest/order/${firstSplit.uuid}`}</a>
              </p>

              <br />
              <p>Installation 2</p>
              <LineItems order_id={secondSplit.id} />
              <p>
                <a
                  href={`${window.location.origin}/guest/order/${secondSplit.uuid}`}
                  target="_blank"
                  rel="noreferrer"
                >{`${window.location.origin}/guest/order/${secondSplit.uuid}`}</a>
              </p>
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions sx={{ marginBottom: 4, mx: 4 }}>
          <Stack direction="column" spacing={3} flexGrow={1}>
            <Typography>Do you want to archive this order now?</Typography>
            <Typography variant="caption">
              WARNING: All existing lines on the fitting board will be removed once archived
            </Typography>
          </Stack>

          <Button onClick={handleClose} color="primary">
            I&apos;ll archive later
          </Button>
          <Button onClick={() => proceed(true)} color="primary" autoFocus>
            Archive now
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

export const afterOrderSplitConfirmationDialog = createConfirmation(AfterOrderSplitDialog);

const LineItems = ({ order_id }: { order_id: number }) => {
  const { data: orderLineItems } = useQueryOrderLineItemsByOrderId(order_id);

  return orderLineItems?.map((order_line_item: any) => {
    return (
      <p key={order_line_item.uuid}>
        <LineItemDescription order_line_item={order_line_item} />
      </p>
    );
  });
};

const LineItemDescription = ({ order_line_item }: any) => {
  const productDesc = useOrderLineItemProductDesc(order_line_item);

  return [order_line_item.product?.code, productDesc].join(" | ");
};
