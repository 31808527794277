import React, { Suspense, useMemo } from "react";
import {
  Alert as MuiAlert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import AddCardIcon from "@mui/icons-material/AddCard";
import { confirmable, ConfirmDialogProps } from "react-confirm";
import { createConfirmation } from "../../../components/ReactConfirmMountPoint";
import { Field, FieldProps, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import useIsLoading from "../../../hooks/useIsLoading";
import { QUERY_KEY } from "../../../api/Order";
import { useQueryOrderTransactionFormOptions } from "../../../api/OrderTransaction";
import { formatISO, parseISO } from "date-fns";
import requiredHelpText from "../../../utils/requiredHelpText";
import { DatePicker } from "@mui/x-date-pickers";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import Loader from "../../../components/Loader";
import { v4 } from "uuid";
import useHealthChecker from "../../../hooks/useHealthChecker";
import { SetOptional } from "type-fest";

const Alert = styled(MuiAlert)(spacing);

interface Values {
  uuid: string;
  amount: number;
  date: string;
  reference_number: string;
  transaction_type: string;
  transaction_for: string;
  payment_method_uuid?: string;
  reference_transaction_uuid?: string;
  notes?: string;
  is_test_mode: boolean | null;
}

export interface TransactionFormDialogProps {
  title: string;
  description?: string;
  initial_values?: SetOptional<Values, "uuid" | "reference_number"> & {
    payment_method_raw?: string;
  };
}

export const SaveTransactionFormDialog = ({
  show,
  proceed,
  title,
  description,
  initial_values
}: ConfirmDialogProps<TransactionFormDialogProps, false | Values>) => {
  const isLoading = useIsLoading([QUERY_KEY]);
  const { isSuspendMutations } = useHealthChecker();
  const { data: formOptions } = useQueryOrderTransactionFormOptions();
  const handleClose = () => proceed(false);

  const validationSchema = Yup.object<Values>().shape({
    uuid: Yup.string().uuid().required(),
    amount: Yup.number()
      .required()
      .label("Amount")
      .when("transaction_type", {
        is: "debit",
        then: schema => schema.positive(),
        otherwise: schema => schema.negative()
      }),
    date: Yup.string().datetime({ allowOffset: true }).required().label("Date"),
    reference_number: Yup.string().required().label("Reference #"),
    transaction_type: Yup.string().required().oneOf(["debit", "credit"]),
    transaction_for: Yup.string()
      .required()
      .oneOf(["deposit", "pre-delivery", "final", "reversal"]),
    status: Yup.string()
      .required()
      .default("success")
      .oneOf(formOptions?.status ?? []),
    payment_method_uuid: Yup.string()
      .uuid()
      .label("Payment Method")
      .when("transaction_type", {
        is: "debit",
        then: schema =>
          schema.required().oneOf(formOptions?.payment_methods?.map(({ uuid }: any) => uuid)),
        otherwise: schema => schema.optional()
      }),
    notes: Yup.string().optional(),
    reference_transaction_uuid: Yup.string().uuid().optional(),
    is_test_mode: Yup.boolean().defined().nullable()
  });

  const formik = useFormik<Values>({
    initialValues: useMemo<Values>(
      () => ({
        uuid: v4(),
        amount: 0,
        date: formatISO(new Date()),
        reference_number: "",
        transaction_type: "",
        transaction_for: "",
        payment_method_uuid: "",
        notes: "",
        is_test_mode: false,
        ...initial_values
      }),
      [initial_values]
    ),
    enableReinitialize: true,
    validationSchema,
    onSubmit: values => {
      const validated = validationSchema.cast(values, {
        stripUnknown: true
      });
      proceed(validated);
    }
  });

  const { errors, handleBlur, handleChange, handleSubmit, touched, values, dirty } = formik;

  const isTransactionReversalSpecific = !!values?.reference_transaction_uuid;

  return (
    <Dialog open={show} onClose={handleClose}>
      <FormikProvider value={formik}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            {Boolean(values.is_test_mode) && (
              <Alert severity="warning" mb={4}>
                <AlertTitle>TEST ORDER</AlertTitle>
                Payments will be marked as TEST mode.
              </Alert>
            )}
            {!!description && <DialogContentText>{description}</DialogContentText>}

            <TextField
              name="amount"
              label="Amount"
              value={values.amount ?? ""}
              error={Boolean(touched.amount && errors.amount)}
              fullWidth
              helperText={
                <>
                  {(touched.amount && errors.amount) ||
                    requiredHelpText("amount", validationSchema)}
                </>
              }
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              margin="normal"
              disabled={isTransactionReversalSpecific || isLoading}
            />

            <Field name="date" value={values.date}>
              {({ field, form, meta }: FieldProps) => {
                return (
                  <DatePicker
                    label="Date"
                    value={parseISO(meta.value)}
                    onChange={(newValue: any) => {
                      if (newValue) {
                        form.setFieldValue(field.name, formatISO(newValue));
                      }
                    }}
                    slots={{
                      textField: (params: any) => (
                        <TextField
                          {...params}
                          helperText={
                            (meta.touched && meta.error) ||
                            requiredHelpText("date", validationSchema)
                          }
                          error={Boolean(meta.touched && meta.error)}
                          fullWidth
                          margin="normal"
                          disabled={isLoading}
                        />
                      )
                    }}
                  />
                );
              }}
            </Field>

            <TextField
              name="reference_number"
              label="Reference #"
              value={values.reference_number ?? ""}
              error={Boolean(touched.reference_number && errors.reference_number)}
              fullWidth
              helperText={
                <>
                  {(touched.reference_number && errors.reference_number) ||
                    requiredHelpText("reference_number", validationSchema)}
                </>
              }
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              margin="normal"
              disabled={isLoading}
            />

            {isTransactionReversalSpecific && !!initial_values?.payment_method_raw ? (
              <TextField
                name="payment_method_raw"
                label="Payment Method"
                value={initial_values?.payment_method_raw ?? ""}
                fullWidth
                variant="outlined"
                margin="normal"
                disabled
              />
            ) : (
              <Field name="payment_method_uuid">
                {({ field, meta }: FieldProps) => (
                  <FormControl
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!meta.error}
                    disabled={isTransactionReversalSpecific}
                  >
                    <InputLabel id="payment-method-select-label">Payment Method</InputLabel>
                    <Select
                      labelId="payment-method-select-label"
                      {...field}
                      value={field.value ?? ""}
                    >
                      {formOptions?.payment_methods?.map((payment_method: any) => (
                        <MenuItem key={payment_method.uuid} value={payment_method.uuid}>
                          {payment_method.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{meta.error}</FormHelperText>
                  </FormControl>
                )}
              </Field>
            )}

            <TextField
              name="notes"
              label="Notes"
              value={values.notes ?? ""}
              error={Boolean(touched.notes && errors.notes)}
              fullWidth
              helperText={
                <>
                  {(touched.notes && errors.notes) || requiredHelpText("notes", validationSchema)}
                </>
              }
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              margin="normal"
              disabled={isLoading}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              type="submit"
              color="primary"
              disabled={
                (!dirty && !isTransactionReversalSpecific) || isLoading || isSuspendMutations
              }
              startIcon={<AddCardIcon />}
            >
              {values?.transaction_for === "reversal" ? "Add Transaction" : "Add Payment"}
            </Button>
          </DialogActions>
        </form>
      </FormikProvider>
    </Dialog>
  );
};

export const saveTransactionFormDialog = createConfirmation(
  confirmable((props: ConfirmDialogProps<TransactionFormDialogProps, false | Values>) => (
    <Suspense fallback={<Loader />}>
      <SaveTransactionFormDialog {...props} />
    </Suspense>
  ))
);
