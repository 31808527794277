import React from "react";
import styled from "@emotion/styled";
import { v4 } from "uuid";
import { Divider as MuiDivider, Paper as MuiPaper } from "@mui/material";
import { spacing } from "@mui/system";
import CompanyForm from "./CompanyForm";
import { useMutationAddCompany } from "../../api/Company";
import PageTitleWithLoading from "../../components/PageTitleWithLoading";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const CompanyAdd = () => {
  const mutation = useMutationAddCompany(v4());

  return (
    <Paper p={4}>
      <PageTitleWithLoading title="Add Company" />

      <Divider my={6} />

      <CompanyForm mutation={mutation} />
    </Paper>
  );
};

export default CompanyAdd;
