import { useIsRestoring } from "@tanstack/react-query";
import PageLoading from "./PageLoading.tsx";

export const IsRestoringQueryCache = ({ children }: { children: React.ReactNode }) => {
  const isRestoring = useIsRestoring();

  if (isRestoring) {
    return <PageLoading />;
  }

  return children;
};
