import React from "react";

import styled from "@emotion/styled";
import { useParams } from "react-router-dom";
import { Card, Divider as MuiDivider, Stack } from "@mui/material";
import { spacing } from "@mui/system";
import PageTitleWithLoading from "../../components/PageTitleWithLoading";
import { useQueryOneLead } from "../../api/Lead";
import LeadDetailsCard from "./LeadDetailsCard";
import EventsTimelineCard from "../customer/EventLogsCard";
import CustomerDetailsCardV2 from "../customer/CustomerDetailsCardV2";
import { Can } from "../../casl";
import useSetPageTitle from "../../hooks/useSetPageTitle.ts";

const Divider = styled(MuiDivider)(spacing);

function LeadDetails() {
  useSetPageTitle("Lead");
  const { uuid } = useParams();
  const { data } = useQueryOneLead(uuid);

  return (
    <>
      <PageTitleWithLoading title={`Lead #: ${data?.lead_number}`} />

      <Divider my={6} />

      <Stack
        direction="row"
        spacing={6}
        justifyContent="flex-start"
        alignItems="stretch"
        useFlexGap
        flexWrap="wrap"
      >
        <LeadDetailsCard lead_uuid={uuid} />
        <Card>
          <CustomerDetailsCardV2 customer_uuid={data?.customer?.uuid} />
        </Card>
        <Can I="Read" a="event_store">
          <EventsTimelineCard stream="leads" aggregate_id={uuid} />
        </Can>
      </Stack>
    </>
  );
}

export default LeadDetails;
