// Themes
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO"
};

export enum USER_ROLES {
  SUPER_ADMIN = "SUPER_ADMIN",
  ADMIN = "ADMIN",
  SALES_REP = "SALES_REP",
  OFFICE = "OFFICE",
  PUBLIC = "PUBLIC",
  FITTER = "FITTER"
}
