import OutstandingOrdersList from "./OutstandingOrdersList";

const routes = [
  {
    path: "/outstanding-orders",
    element: <OutstandingOrdersList />
  }
];

export default routes;
