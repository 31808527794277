import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField
} from "@mui/material";
import { confirmable, ConfirmDialogProps } from "react-confirm";
import { createConfirmation } from "../../../components/ReactConfirmMountPoint";
import { useFormik } from "formik";
import * as Yup from "yup";

export const OrderApplyCreditInputDialog = confirmable(
  ({
    show,
    proceed,
    default_amount,
    default_note,
    can_remove_credit
  }: ConfirmDialogProps<
    { default_amount?: number; default_note?: string; can_remove_credit?: boolean },
    false | { credit: number | null; credit_note: string | null }
  >) => {
    const handleClose = () => proceed(false);

    const [removeCredit, setRemoveCredit] = useState(false);

    const validationSchema = Yup.object().shape({
      credit_amount: Yup.number().defined().nullable().label("Credit Amount"),
      credit_note: Yup.string().defined().nullable().label("Credit Reason")
    });

    const formik = useFormik({
      initialValues: {
        credit_amount: default_amount,
        credit_note: default_note
      },
      enableReinitialize: true,
      validationSchema,
      onSubmit: values => {
        const validated = validationSchema.cast(values, {
          stripUnknown: true
        });
        proceed({
          credit: validated.credit_amount,
          credit_note: validated.credit_note
        });
      }
    });

    const { errors, handleBlur, handleChange, handleSubmit, touched, values, setFieldValue } =
      formik;

    useEffect(() => {
      if (removeCredit) {
        setFieldValue("credit_amount", null);
        setFieldValue("credit_note", null);
      } else {
        setFieldValue("credit_amount", default_amount);
      }
    }, [removeCredit]);

    return (
      <Dialog
        open={show}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle id="alert-dialog-title">Apply Credit</DialogTitle>
          <DialogContent>
            {!!can_remove_credit && (
              <FormGroup>
                <FormControlLabel
                  control={<Switch />}
                  onChange={(e, checked) => {
                    setRemoveCredit(checked);
                  }}
                  label="Remove"
                />
              </FormGroup>
            )}

            <TextField
              name="credit_amount"
              label="Credit Amount"
              value={values.credit_amount ?? ""}
              error={Boolean(touched.credit_amount && errors.credit_amount)}
              fullWidth
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              margin="normal"
              disabled={removeCredit}
            />

            <TextField
              name="credit_note"
              label="Credit Note"
              value={values.credit_note ?? ""}
              error={Boolean(touched.credit_note && errors.credit_note)}
              fullWidth
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              margin="normal"
              disabled={removeCredit}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              autoFocus
              disabled={Boolean(touched.credit_amount && errors.credit_amount)}
            >
              Confirm
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
);

export const orderApplyCreditInputConfirmationDialog = createConfirmation(
  OrderApplyCreditInputDialog
);
