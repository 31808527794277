import React from "react";
import styled from "@emotion/styled";
import { v4 } from "uuid";
import { Divider as MuiDivider, Paper as MuiPaper } from "@mui/material";
import { spacing } from "@mui/system";
import KeyValueForm from "./KeyValueForm";
import { useMutationAddKeyValue } from "../../api/KeyValue";
import PageTitleWithLoading from "../../components/PageTitleWithLoading";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const KeyValueAdd = () => {
  const mutation = useMutationAddKeyValue(v4());

  return (
    <Paper p={4}>
      <PageTitleWithLoading title="Add Key Value" />

      <Divider my={6} />

      <KeyValueForm mutation={mutation} />
    </Paper>
  );
};

export default KeyValueAdd;
