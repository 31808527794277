import React from "react";
import styled from "@emotion/styled";
import { v4 } from "uuid";
import { Divider as MuiDivider, Paper as MuiPaper } from "@mui/material";
import { spacing } from "@mui/system";
import AccountGroupForm from "./AccountGroupForm";
import { useMutationAddAccountGroup } from "../../api/AccountGroup";
import PageTitleWithLoading from "../../components/PageTitleWithLoading";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const AccountGroupAdd = () => {
  const mutation = useMutationAddAccountGroup(v4());

  return (
    <Paper p={4}>
      <PageTitleWithLoading title="Add Account Group" />

      <Divider my={6} />

      <AccountGroupForm mutation={mutation} />
    </Paper>
  );
};

export default AccountGroupAdd;
