import React from "react";
import styled from "@emotion/styled";
import { Divider as MuiDivider, Paper as MuiPaper } from "@mui/material";
import { spacing } from "@mui/system";
import PaymentMethodForm from "./PaymentMethodForm";
import { useMutationEditPaymentMethod } from "../../api/PaymentMethod";
import PageTitleWithLoading from "../../components/PageTitleWithLoading";
import { useParams } from "react-router-dom";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const PaymentMethodEdit = () => {
  const { uuid } = useParams<string>();
  const mutation = useMutationEditPaymentMethod(uuid ?? "");

  return (
    <Paper p={4}>
      <PageTitleWithLoading title="Edit Payment Method" />

      <Divider my={6} />

      <PaymentMethodForm mutation={mutation} uuid={uuid} />
    </Paper>
  );
};

export default PaymentMethodEdit;
