import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { updateCheckerIntervalMillis } from "../config.ts";

export const useQueryCheckUpdate = () => {
  return useQuery<any, AxiosError>({
    enabled: !!updateCheckerIntervalMillis,
    queryKey: ["frontend-check-update"],
    suspense: false,
    useErrorBoundary: false,
    refetchOnWindowFocus: true,
    refetchInterval: updateCheckerIntervalMillis,
    retry: (failureCount, error) => {
      if (failureCount >= 3) {
        return false;
      }

      return [0, 503].includes(error.response?.status ?? 0);
    },
    queryFn: () => {
      return fetch("/build.json")
        .then(response => response.json())
        .then(data => BUILD_VERSION < data?.build_version);
    }
  });
};
