import React from "react";
import styled from "@emotion/styled";

import {
  Avatar,
  Badge,
  Grid,
  List as MuiList,
  ListItem,
  ListItemButton,
  ListItemText as MuiListItemText,
  Typography
} from "@mui/material";

import useAuth from "../../hooks/useAuth";
import { uptimeStatusURL } from "../../config.ts";

const Footer = styled.div`
  background-color: ${props => props.theme.sidebar.footer.background} !important;
  padding: ${props => props.theme.spacing(2.75)} ${props => props.theme.spacing(4)};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const FooterText = styled(Typography)`
  color: ${props => props.theme.sidebar.footer.color};
`;

const FooterBadge = styled(Badge)`
  margin-right: ${props => props.theme.spacing(1)};
  span {
    background-color: ${props => props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${props => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

const ListItemText = styled(MuiListItemText)`
  span {
    color: ${props => props.theme.footer.color};
  }
`;

const List = styled(MuiList)`
  background-color: ${props => props.theme.sidebar.background};
`;

const SidebarFooter: React.FC = ({ ...rest }) => {
  const { user } = useAuth();

  return (
    <>
      <Footer {...rest}>
        <Grid container spacing={2}>
          <Grid item>
            <FooterBadge
              overlap="circular"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
              variant="dot"
            >
              {!!user && <Avatar alt={user.email} />}
            </FooterBadge>
          </Grid>
          <Grid item>
            <FooterText variant="body2">{user?.email}</FooterText>
          </Grid>
        </Grid>
      </Footer>
      <List dense>
        {!!uptimeStatusURL && (
          <ListItemButton component="a" target="_blank" href={uptimeStatusURL}>
            <ListItemText primary="Status Page" />
          </ListItemButton>
        )}
        <ListItem>
          <ListItemText primary={`© ${new Date().getFullYear()} - Aldrin ERP`} />
        </ListItem>
        {typeof BUILD_VERSION !== "undefined" && (
          <ListItem>
            <ListItemText primary={`v${BUILD_VERSION}`} />
          </ListItem>
        )}
      </List>
    </>
  );
};

export default SidebarFooter;
