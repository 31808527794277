import { get, isObject, merge, omitBy, reduce, reduceRight, set } from "lodash";
import assert from "node:assert";
import { dataFieldReservedKeywords } from "../product/processProductConfigXlsxImported";
import { calculatePriceFromGrid } from "./calculatePriceFromGrid";
import { dineroAUDFromFloat } from "../utils/dineroAUDFromFloat";
import { add, Dinero, isPositive, multiply, toDecimal, transformScale, up } from "dinero.js";
import { dineroAUD } from "../utils/dineroAUD";
import { enAULocaleFormatTransformer } from "../utils/enAULocaleFormatTransformer";

export interface CalculateOrderLineItemPriceType {
  config_values: any;
  config_options: any;
  inventory_items: any;
  markup_percentage?: number;
}

export interface PriceConfigType {
  price: Dinero<number>;
  markup: Dinero<number>;
  priceDecimal: string;
  markupDecimal: string;
  markup_percentage?: number;
  config: {
    [x: string]: {
      [y: string]: string | number;
    } & { subtotalPrice: Dinero<number>; unitPrice: Dinero<number> };
  };
}

enum SoldBy {
  grid = 1,
  quantity,
  width,
  height,
  area,
  perimeter
}

const defaultCurrencyFormatTransformer = enAULocaleFormatTransformer();

export const calculateOrderLineItemPrice = ({
  config_values,
  config_options,
  inventory_items,
  markup_percentage
}: CalculateOrderLineItemPriceType): PriceConfigType => {
  const availableInventoryItemCodes = inventory_items?.map((item: any) => item.code) ?? [];

  const _priceConfigurations = reduceRight(
    config_options,
    (_result, conf) => {
      const uniqueOptionName = conf["Unique Option Name"];
      const relatedOptionName = conf["Value applies to Price from Related Option Name"];
      const optionName = relatedOptionName || uniqueOptionName || null;
      assert.ok(optionName, "[Edge Case] optionName should not be null");

      const relatedDataField = conf["Related Column Data Field"] || null;

      // For debugging
      // const logger = (...args: any[]) => {
      //   ["ITEM1QTY", "MISCQTY"].includes(uniqueOptionName) && console.log(...args);
      // };

      const inventoryCodeForPricing = conf["Inventory Code for Pricing"];
      const inventoryCode =
        inventoryCodeForPricing || get(config_values, [uniqueOptionName, "code"]);

      const value = get(config_values, uniqueOptionName);

      if (relatedDataField && !isObject(value)) {
        assert.ok(
          dataFieldReservedKeywords.includes(relatedDataField),
          `[Edge Case] relatedDataField ${relatedDataField} is not in (${dataFieldReservedKeywords.join(
            ", "
          )})`
        );

        _result = set(
          _result,
          [optionName, relatedDataField],
          get(config_values, uniqueOptionName, 1)
        );

        if (
          inventoryCodeForPricing &&
          availableInventoryItemCodes.includes(inventoryCodeForPricing)
        ) {
          const value = get(config_values, uniqueOptionName);

          if (value) {
            _result = merge(
              {
                [optionName]: soldByDefaults(
                  inventoryCodeForPricing,
                  config_values,
                  inventory_items
                )
              },
              _result,
              {
                [optionName]: {
                  code: inventoryCodeForPricing,
                  label:
                    inventory_items?.find((item: any) => item.code === inventoryCodeForPricing)
                      ?.description ?? "N/A",
                  question: conf["Question Heading"]
                }
              }
            );
          }
        }

        return _result;
      }

      if (!availableInventoryItemCodes.includes(inventoryCode)) {
        return _result;
      }

      return merge(
        { [optionName]: soldByDefaults(inventoryCode, config_values, inventory_items) },
        _result,
        { [optionName]: value },
        {
          [optionName]: {
            question: conf["Question Heading"]
          }
        }
      );
    },
    {}
  );

  const markupPerc = transformScale(
    dineroAUDFromFloat((markup_percentage ?? 0) / 100),
    3,
    up
  ).toJSON();

  const config = omitBy(_priceConfigurations, (conf: any) => !conf.code);
  const _price = reduce(config, (sum, conf: any) => add(conf.subtotalPrice, sum), dineroAUD(0));
  const markup = multiply(_price, {
    amount: markupPerc.amount,
    scale: markupPerc.scale
  });
  const price = add(_price, markup);
  const markupDecimal = toDecimal(transformScale(markup, 2), defaultCurrencyFormatTransformer);
  const priceDecimal = toDecimal(transformScale(price, 2), defaultCurrencyFormatTransformer);

  // console.dir(
  //   { markupDecimal, price: price.toJSON(), priceDecimal, config, markup: markup.toJSON() },
  //   { depth: null }
  // );

  return { price, priceDecimal, config, markup, markupDecimal, markup_percentage };
};

const soldByDefaults = (
  code: string,
  config_values: CalculateOrderLineItemPriceType["config_values"],
  inventory_items: CalculateOrderLineItemPriceType["inventory_items"]
) => {
  const inventoryItem: any | undefined = inventory_items?.find((item: any) => item.code === code);

  if (!inventoryItem) {
    return null;
  }

  const { product_sold_by_id, price, price_grid_active } = inventoryItem;

  switch (product_sold_by_id) {
    case SoldBy.quantity: {
      return {
        soldBy: product_sold_by_id,
        QTY: Number(config_values["QTY"] ?? 1),
        unitPrice: dineroAUDFromFloat(price),
        get unitPriceDecimal() {
          return toDecimal(this.unitPrice, defaultCurrencyFormatTransformer);
        },
        get isAvailable() {
          // Andrew - prevent these products from being added to the cart but ONLY if they are priced by grid. Other items that are priced by quantity but have a $0.00 warning should be able to be added.
          return true;
        },
        get subtotalPrice() {
          return transformScale(
            multiply(this.unitPrice, {
              amount: this.QTY
            }),
            2
          );
        },
        get subtotalPriceDecimal() {
          return toDecimal(this.subtotalPrice, defaultCurrencyFormatTransformer);
        }
      };
    }
    case SoldBy.grid: {
      return {
        soldBy: product_sold_by_id,
        QTY: Number(config_values["QTY"] ?? 1),
        ITEMWIDTH: Number(config_values["ITEMWIDTH"] ?? 1),
        ITEMHEIGHT: Number(config_values["ITEMHEIGHT"] ?? 1),
        ITEMDEPTH: Number(config_values["ITEMDEPTH"]?.depthValue ?? 1),
        get unitPrice() {
          return dineroAUDFromFloat(
            calculatePriceFromGrid(
              this.ITEMWIDTH,
              this.ITEMHEIGHT,
              this.ITEMDEPTH,
              price_grid_active?.width,
              price_grid_active?.height_depth,
              price_grid_active?.matrix
            )
          );
        },
        get unitPriceDecimal() {
          return toDecimal(this.unitPrice, defaultCurrencyFormatTransformer);
        },
        get isAvailable() {
          return isPositive(this.unitPrice);
        },
        get subtotalPrice() {
          return transformScale(
            multiply(this.unitPrice, {
              amount: this.QTY
            }),
            2
          );
        },
        get subtotalPriceDecimal() {
          return toDecimal(this.subtotalPrice, defaultCurrencyFormatTransformer);
        }
      };
    }
    case SoldBy.width: {
      return {
        soldBy: product_sold_by_id,
        QTY: Number(config_values["QTY"] ?? 1),
        ITEMWIDTH: Number(config_values["ITEMWIDTH"] ?? 0),
        get unitPrice() {
          return multiply(dineroAUDFromFloat(price), {
            amount: this.ITEMWIDTH,
            scale: 3
          });
        },
        get unitPriceDecimal() {
          return toDecimal(this.unitPrice, defaultCurrencyFormatTransformer);
        },
        get isAvailable() {
          return isPositive(this.unitPrice);
        },
        get subtotalPrice() {
          return transformScale(
            multiply(this.unitPrice, {
              amount: this.QTY
            }),
            2
          );
        },
        get subtotalPriceDecimal() {
          return toDecimal(this.subtotalPrice, defaultCurrencyFormatTransformer);
        }
      };
    }
    case SoldBy.height: {
      return {
        soldBy: product_sold_by_id,
        QTY: Number(config_values["QTY"] ?? 1),
        ITEMHEIGHT: Number(config_values["ITEMHEIGHT"] ?? 0),
        get unitPrice() {
          return multiply(dineroAUDFromFloat(price), {
            amount: this.ITEMHEIGHT,
            scale: 3
          });
        },
        get unitPriceDecimal() {
          return toDecimal(this.unitPrice, defaultCurrencyFormatTransformer);
        },
        get isAvailable() {
          return isPositive(this.unitPrice);
        },
        get subtotalPrice() {
          return transformScale(
            multiply(this.unitPrice, {
              amount: this.QTY
            }),
            2
          );
        },
        get subtotalPriceDecimal() {
          return toDecimal(this.subtotalPrice, defaultCurrencyFormatTransformer);
        }
      };
    }
    case SoldBy.perimeter: {
      return {
        soldBy: product_sold_by_id,
        QTY: Number(config_values["QTY"] ?? 1),
        ITEMWIDTH: Number(config_values["ITEMWIDTH"] ?? 0),
        ITEMHEIGHT: Number(config_values["ITEMHEIGHT"] ?? 0),
        get unitPrice() {
          return multiply(dineroAUDFromFloat(price), {
            amount: this.ITEMWIDTH * 2 + this.ITEMHEIGHT * 2,
            scale: 3
          });
        },
        get unitPriceDecimal() {
          return toDecimal(this.unitPrice, defaultCurrencyFormatTransformer);
        },
        get isAvailable() {
          return isPositive(this.unitPrice);
        },
        get subtotalPrice() {
          return transformScale(
            multiply(this.unitPrice, {
              amount: this.QTY
            }),
            2
          );
        },
        get subtotalPriceDecimal() {
          return toDecimal(this.subtotalPrice, defaultCurrencyFormatTransformer);
        }
      };
    }
    case SoldBy.area: {
      return {
        soldBy: product_sold_by_id,
        QTY: Number(config_values["QTY"] ?? 1),
        ITEMWIDTH: Number(config_values["ITEMWIDTH"] ?? 0),
        ITEMHEIGHT: Number(config_values["ITEMHEIGHT"] ?? 0),
        get unitPrice() {
          return multiply(dineroAUDFromFloat(price), {
            amount: this.ITEMHEIGHT * this.ITEMWIDTH,
            scale: 6
          });
        },
        get unitPriceDecimal() {
          return toDecimal(this.unitPrice, defaultCurrencyFormatTransformer);
        },
        get isAvailable() {
          return isPositive(this.unitPrice);
        },
        get subtotalPrice() {
          return transformScale(
            multiply(this.unitPrice, {
              amount: this.QTY
            }),
            2
          );
        },
        get subtotalPriceDecimal() {
          return toDecimal(this.subtotalPrice, defaultCurrencyFormatTransformer);
        }
      };
    }
  }

  return null;
};
