import {
  useMutationAddModel,
  useMutationBulkDeleteModelV2,
  useMutationDeleteModel,
  useMutationEditModel,
  useQueryModels,
  useQueryOneModel
} from "../hooks/useMutationFormAbstract";

export const QUERY_KEY = "product-line";

export const useQueryProductLines = () => useQueryModels(QUERY_KEY);

export const useQueryOneProductLine = (uuid?: string) => useQueryOneModel<any>(QUERY_KEY, uuid);

export const useMutationAddProductLine = (uuid: string) => useMutationAddModel(QUERY_KEY, uuid);

export const useMutationEditProductLine = (uuid: string) => useMutationEditModel(QUERY_KEY, uuid);

export const useMutationDeleteProductLine = () => useMutationDeleteModel(QUERY_KEY);

export const useMutationBulkDeleteProductLine = () => useMutationBulkDeleteModelV2(QUERY_KEY);
