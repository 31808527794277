import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import { AutocompleteProps } from "@mui/material/Autocomplete/Autocomplete";
import { useField, useFormikContext } from "formik";
import { find, isEqual } from "lodash";

type OptionType = string | { label: string; id: string };

type ComponentType = {
  label: string;
  name: string;
  startAdornment?: React.ReactNode;
  helpText?: false | string;
} & Omit<AutocompleteProps<OptionType, undefined, undefined, boolean>, "renderInput">;

const AutocompleteField: React.VFC<ComponentType> = ({
  label,
  name,
  startAdornment,
  helpText,
  ...autocompleteProps
}) => {
  const [field, meta, helpers] = useField(name);
  const { submitCount } = useFormikContext();

  const getOptionValueFromFieldValue = (value: string) => {
    if (autocompleteProps.freeSolo) {
      return value;
    }

    return (
      find(autocompleteProps.options, option => {
        return typeof option === "string" ? option === value : option.id === value;
      }) ?? null
    );
  };

  const [inputValue, setInputValue] = React.useState("");

  const setValue = (value?: string) => {
    helpers.setValue(value);
    if (meta.value !== value) {
      if (meta.error) {
        helpers.setError(undefined);
      }
    }
  };

  return (
    <>
      <Autocomplete
        defaultValue={getOptionValueFromFieldValue(meta.initialValue)}
        value={getOptionValueFromFieldValue(meta.value) ?? null}
        onChange={(event: any, newValue: OptionType | null) => {
          const _value = typeof newValue === "string" ? newValue : newValue?.id;

          setValue(_value);
        }}
        inputValue={inputValue ?? ""}
        onInputChange={(event, newInputValue) => {
          if (autocompleteProps.freeSolo) {
            setValue(newInputValue);
          }

          setInputValue(newInputValue);
        }}
        isOptionEqualToValue={(option, value) => isEqual(option, value)}
        {...autocompleteProps}
        onBlur={field.onBlur}
        renderInput={params => (
          <TextField
            {...params}
            label={label}
            helperText={(submitCount && meta.error) || helpText}
            margin="normal"
            error={Boolean(submitCount && meta.error)}
            name={name}
            InputProps={{
              ...params.InputProps,
              startAdornment
            }}
          />
        )}
      />
    </>
  );
};

export default AutocompleteField;
