import { calculateOrderLineItemPrice, PriceConfigType } from "aldrin-erp-main-shared-functions";
import { CalculateOrderLineItemPriceType } from "aldrin-erp-main-shared-functions/src/inventory_item/calculateOrderLineItemPrice";
import { useMemo } from "react";
import { useQueryInventoryItemsByProductID } from "../../../api/InventoryItem.ts";

interface Props {
  product_id?: number;
  config_values?: CalculateOrderLineItemPriceType["config_values"];
  config_options?: CalculateOrderLineItemPriceType["config_options"];
  markup_percentage?: CalculateOrderLineItemPriceType["markup_percentage"];
}

export const useOrderLinePriceConfig = ({
  product_id,
  config_options,
  markup_percentage,
  config_values
}: Props) => {
  const { data: inventoryItems } = useQueryInventoryItemsByProductID(product_id);

  return useMemo<PriceConfigType | undefined>(() => {
    if (!inventoryItems || !config_values || !config_options) {
      return undefined;
    }

    return calculateOrderLineItemPrice({
      config_values,
      config_options,
      inventory_items: inventoryItems,
      markup_percentage
    });
  }, [config_values, config_options, inventoryItems, markup_percentage]);
};
