import React from "react";

import styled from "@emotion/styled";
import { useParams } from "react-router-dom";
import { Divider as MuiDivider } from "@mui/material";
import { spacing } from "@mui/system";
import useSetPageTitle from "../../../hooks/useSetPageTitle.ts";
import { SalesRepDashboardPartial } from "./SalesRepDashboardPartial.tsx";

const Divider = styled(MuiDivider)(spacing);

function SalesRepDetails() {
  const { uuid } = useParams<string>();
  useSetPageTitle("Sales Rep");

  return (
    <>
      <Divider my={6} />

      {uuid && <SalesRepDashboardPartial uuid={uuid} />}
    </>
  );
}

export default SalesRepDetails;
