import React from "react";
import { Card } from "@mui/material";
import useSetPageTitle from "../../hooks/useSetPageTitle.ts";
import { DataGridServerIndexBasedPaginated } from "../../components/data-grid-v2/DataGridServerIndexBasedPaginated.tsx";
import { DataGridToolbar } from "./DataGridToolbar.tsx";
import { useLeadListColumns } from "./useLeadListColumns.tsx";
import { useQueryParamAvailability } from "../order/OrderList.tsx";
import { useMutationBulkDeleteLead } from "../../api/Lead.ts";

const LeadList = () => {
  const { mutateAsync: bulkDelete } = useMutationBulkDeleteLead();
  const [availability] = useQueryParamAvailability();
  useSetPageTitle("Leads");
  const columns = useLeadListColumns({ availability });
  return (
    <Card sx={{ height: "87vh" }}>
      <DataGridServerIndexBasedPaginated
        apiEndpoint="lead"
        autosizeOnMount={false}
        columns={columns}
        initialState={{
          columns: {
            columnVisibilityModel: {
              "customer.uuid": false,
              "sales_rep_user.uuid": false
            }
          }
        }}
        slots={{
          toolbar: DataGridToolbar
        }}
        slotProps={{
          toolbar: {
            bulkDelete
          }
        }}
      />
    </Card>
  );
};

export default LeadList;
