import { UseQueryOptions } from "@tanstack/react-query";
import {
  useMutationAbstract,
  useMutationAddModel,
  useMutationBulkDeleteModelV2,
  useMutationDeleteModel,
  useMutationEditModel,
  useMutationPatchModel,
  useQueryFormOptionsModel,
  useQueryModels,
  useQueryOneModel
} from "../hooks/useMutationFormAbstract";
import { useEventHandledPromise } from "../hooks/useEventHandledPromise.ts";
import axios from "../utils/axios.ts";
import sleep from "../utils/sleep.ts";
import { useChannel } from "@harelpls/use-pusher";

export const useQueryLeads = () => useQueryModels("lead");

export const useQueryOneLead = (uuid?: string, options?: UseQueryOptions<any>) =>
  useQueryOneModel<any>("lead", uuid, options);

export const useQueryLeadFormOptions = () =>
  useQueryFormOptionsModel<{
    customers: any;
    lead_source: any;
    lead_status: any;
    marketing: any;
    sales_rep_user: any;
    next_available_reference_number: number;
  }>("lead");

export const useMutationAddLead = (uuid: string) => useMutationAddModel("lead", uuid);

export const useMutationEditLead = (uuid: string) => useMutationEditModel("lead", uuid);

export const useMutationPatchLead = (uuid: string) => useMutationPatchModel("lead", uuid);

export const useMutationSetStatusLostLead = () => {
  interface Response {}

  const channel = useChannel("StatusLostSet");
  const eventHandledPromise = useEventHandledPromise<Response>(channel);

  return useMutationAbstract<Response, unknown, { uuid: string }>({
    mutationKey: ["lead", "set-status-lost"],
    mutationFn: ({ uuid }) =>
      axios
        .patch<Response>(`/api/lead/${uuid}/set-status-lost`)
        .then(({ data }) => data)
        .then(data => {
          return Promise.race<Response>([
            eventHandledPromise(uuid as string, data),
            sleep(2500, data)
          ]);
        })
  });
};

export const useMutationSetCallBackAtAndNotesLead = () => {
  interface Response {}

  const channel = useChannel("CallBackAtAndNotesSet");
  const eventHandledPromise = useEventHandledPromise<Response>(channel);

  return useMutationAbstract<
    Response,
    unknown,
    { uuid: string; call_back_at: string; call_back_notes: string }
  >({
    mutationKey: ["lead", "set-call-back-at-and-notes"],
    mutationFn: ({ uuid, ...input }) =>
      axios
        .patch<Response>(`/api/lead/${uuid}/set-call-back-at-and-notes`, input)
        .then(({ data }) => data)
        .then(data => {
          return Promise.race<Response>([
            eventHandledPromise(uuid as string, data),
            sleep(2500, data)
          ]);
        })
  });
};

export const useMutationDeleteLead = () => useMutationDeleteModel("lead");

export const useMutationBulkDeleteLead = () => useMutationBulkDeleteModelV2("lead");
