import {
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select
} from "@mui/material";
import { Field, FieldProps, useFormikContext } from "formik";
import { isEmpty } from "lodash";
import React, { useMemo } from "react";
import { useDeepCompareMemoize } from "use-deep-compare-effect";
import { RenderConfigProps } from "../OrderLineItemForm";
import { useHandleLookbackFieldValueChanged } from "../../../../hooks/useHandleLookbackFieldValueChanged";
import CloseIcon from "@mui/icons-material/Close";

const DropdownList: React.VFC<RenderConfigProps> = ({ conf, index }) => {
  const { lookback, lookbackValueLabel } = useHandleLookbackFieldValueChanged({ conf });
  const { isSubmitting } = useFormikContext();
  const fieldName = `config_values[${conf["Unique Option Name"]}]`;

  const selectOptions = useMemo(() => {
    if (lookbackValueLabel && lookback) {
      return (conf["Answer Options"] as any[])?.filter(option => {
        return option["lookbackValue"] === lookbackValueLabel;
      });
    }

    return conf["Answer Options"];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, useDeepCompareMemoize([conf["Answer Options"], lookbackValueLabel, lookback]));

  const isSelectInputDisabled =
    isEmpty(selectOptions) || (!isEmpty(lookback) && isEmpty(lookbackValueLabel));

  const required = !isSelectInputDisabled && conf["Is Required"] === "Y";

  return (
    <Field name={fieldName}>
      {({ field, form, meta: { error } }: FieldProps) => {
        return (
          <FormControl
            fullWidth
            disabled={isSubmitting || isSelectInputDisabled}
            margin="normal"
            error={!!error}
            required={required}
            size="small"
          >
            <InputLabel id={`${index}-label`}>{conf["Question Heading"]}</InputLabel>
            <Select
              labelId={`${index}-label`}
              {...field}
              value={field.value?.label ?? ""}
              onChange={({ target }) => {
                form.setFieldValue(
                  field.name,
                  selectOptions?.find((opt: any) => opt.label === target.value)
                );
              }}
              input={<OutlinedInput label={conf["Question Heading"]} />}
              error={!!error}
            >
              <MenuItem divider value={undefined}>
                <ListItemIcon>
                  <CloseIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Clear</ListItemText>
              </MenuItem>
              {selectOptions?.map((option: any) => (
                <MenuItem key={option.label} value={option.label}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {error ?? (!isEmpty(conf["Help Message"]) && conf["Help Message"])}
            </FormHelperText>
          </FormControl>
        );
      }}
    </Field>
  );
};

export default DropdownList;
