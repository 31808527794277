import { toDecimalFromFloat } from "aldrin-erp-main-shared-functions";
import { useDataGridFilterModelQueryParams } from "../../hooks/useDataGridFilterModelQueryParams";
import { gridFilterModelSelector, useGridApiContext, useGridSelector } from "@mui/x-data-grid-pro";
import { TextField } from "@mui/material";
import { useQueryOrderReceivablesStatistics } from "../../api/OrderReceivable";

export const ToolbarStatistics = () => {
  const apiRef = useGridApiContext();
  const filterModel = useGridSelector(apiRef, gridFilterModelSelector);
  const filterQueryParams = useDataGridFilterModelQueryParams(filterModel);
  const { data: stats } = useQueryOrderReceivablesStatistics(filterQueryParams);

  return (
    <>
      <TextField
        label="Total Outstanding"
        inputProps={{
          readOnly: true
        }}
        InputLabelProps={{
          shrink: true
        }}
        InputProps={{
          disableUnderline: true
        }}
        value={toDecimalFromFloat(Number(stats?.totalOutstandingAmount) ?? 0.0)}
        size="small"
        variant="standard"
        sx={{ width: 100 }}
      />

      <TextField
        label="30 Days"
        inputProps={{
          readOnly: true
        }}
        InputLabelProps={{
          shrink: true
        }}
        InputProps={{
          disableUnderline: true
        }}
        value={toDecimalFromFloat(Number(stats?.totalOutstandingAmount30Days) ?? 0.0)}
        size="small"
        variant="standard"
        sx={{ width: 150 }}
      />

      <TextField
        label="60 Days"
        inputProps={{
          readOnly: true
        }}
        InputLabelProps={{
          shrink: true
        }}
        InputProps={{
          disableUnderline: true
        }}
        value={toDecimalFromFloat(Number(stats?.totalOutstandingAmount60Days) ?? 0.0)}
        size="small"
        variant="standard"
        sx={{ width: 150 }}
      />

      <TextField
        label="90 Days"
        inputProps={{
          readOnly: true
        }}
        InputLabelProps={{
          shrink: true
        }}
        InputProps={{
          disableUnderline: true
        }}
        value={toDecimalFromFloat(Number(stats?.totalOutstandingAmount90Days) ?? 0.0)}
        size="small"
        variant="standard"
        sx={{ width: 150 }}
      />

      <TextField
        label="Older"
        inputProps={{
          readOnly: true
        }}
        InputLabelProps={{
          shrink: true
        }}
        InputProps={{
          disableUnderline: true
        }}
        value={toDecimalFromFloat(Number(stats?.totalOutstandingAmountOlder) ?? 0.0)}
        size="small"
        variant="standard"
        sx={{ width: 180 }}
      />
    </>
  );
};
