import React from "react";
import styled from "@emotion/styled";
import { Divider as MuiDivider, Paper as MuiPaper } from "@mui/material";
import { spacing } from "@mui/system";
import LeadStatusForm from "./LeadStatusForm";
import { useMutationEditLeadStatus } from "../../api/LeadStatus";
import PageTitleWithLoading from "../../components/PageTitleWithLoading";
import { useParams } from "react-router-dom";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const LeadStatusEdit = () => {
  const { uuid } = useParams<string>();
  const mutation = useMutationEditLeadStatus(uuid ?? "");

  return (
    <Paper p={4}>
      <PageTitleWithLoading title="Edit Lead Status" />

      <Divider my={6} />

      <LeadStatusForm mutation={mutation} uuid={uuid} />
    </Paper>
  );
};

export default LeadStatusEdit;
