import { useContext } from "react";
import { DataGridPageContext } from "../contexts/DataGridPageContext";

const useDataGridPage = () => {
  const context = useContext(DataGridPageContext);

  if (!context) throw new Error("DataGridPageContext must be placed within DataGridPageProvider");

  return context;
};

export default useDataGridPage;
