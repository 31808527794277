import asyncComponent from "../../components/Async.tsx";

const EventStoreList = asyncComponent(() => import("./EventStoreList"));

const routes = [
  {
    path: "/event-store",
    element: <EventStoreList />
  }
];

export default routes;
