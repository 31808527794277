import { useContext } from "react";
import { PageTitleContext } from "../contexts/PageTitleContext";

const useGetPageTitle = () => {
  const context = useContext(PageTitleContext);

  if (!context) throw new Error("PageTitleContext must be placed within PageTitleProvider");

  return context?.pageTitle;
};

export default useGetPageTitle;
