import {
  useMutationAddModel,
  useMutationBulkDeleteModelV2,
  useMutationDeleteModel,
  useMutationEditModel,
  useQueryFormOptionsModel,
  useQueryModels,
  useQueryOneModel
} from "../hooks/useMutationFormAbstract";

export const QUERY_KEY = "payment-term-config";

export const useQueryPaymentTermConfigs = () => useQueryModels(QUERY_KEY);

export const useQueryOnePaymentTermConfig = (uuid?: string) =>
  useQueryOneModel<any>(QUERY_KEY, uuid);

export const useMutationAddPaymentTermConfig = (uuid: string) =>
  useMutationAddModel(QUERY_KEY, uuid);

export const useMutationEditPaymentTermConfig = (uuid: string) =>
  useMutationEditModel(QUERY_KEY, uuid);

export const useMutationDeletePaymentTermConfig = () => useMutationDeleteModel(QUERY_KEY);

export const useMutationBulkDeletePaymentTermConfig = () => useMutationBulkDeleteModelV2(QUERY_KEY);

export const useQueryPaymentTermConfigFormOptions = () =>
  useQueryFormOptionsModel<{
    customer_types: string[];
    customer_groups: any;
    order_types: string[];
  }>(QUERY_KEY);
