import React from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { confirmable, ConfirmDialogProps } from "react-confirm";
import { createConfirmation } from "../../components/ReactConfirmMountPoint.ts";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { useDataExport } from "../../api/DataExport.ts";
import { v4 } from "uuid";

export interface ExportedRunsProps {}

interface Values {
  uuid: string;
  start_date: string;
  end_date: string;
}

export const ExportProductsByValueDialogComponent = ({
  show,
  proceed
}: ConfirmDialogProps<ExportedRunsProps, false | string>) => {
  const { mutateAsync: dataExport } = useDataExport("products-by-value");
  const handleClose = () => {
    proceed(false);
  };

  const validationSchema = Yup.object<Values>().shape({
    uuid: Yup.string().uuid().required(),
    start_date: Yup.date().required(),
    end_date: Yup.date().required()
  });

  const formik = useFormik<Values>({
    initialValues: {
      start_date: "",
      end_date: "",
      uuid: v4()
    },
    validationSchema,
    onSubmit: async values => {
      const validated = validationSchema.cast(values, {
        stripUnknown: true
      });
      await dataExport(validated);

      proceed(validated.uuid);
    }
  });

  const { handleSubmit, dirty, setFieldValue } = formik;
  return (
    <Dialog open={show} onClose={handleClose} fullWidth>
      <FormikProvider value={formik}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Export Products by Value</DialogTitle>
          <DialogContent>
            <Box sx={{ my: 3 }}>
              <DateRangePicker
                localeText={{ start: "From", end: "To" }}
                onChange={([start, end]) => {
                  setFieldValue("start_date", start);
                  setFieldValue("end_date", end);
                }}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit" color="primary" disabled={!dirty}>
              Export
            </Button>
          </DialogActions>
        </form>
      </FormikProvider>
    </Dialog>
  );
};

export const exportProductsByValueDialog = createConfirmation(
  confirmable((props: ConfirmDialogProps<ExportedRunsProps, false | string>) => (
    <ExportProductsByValueDialogComponent {...props} />
  ))
);
