import React, { Suspense } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import { confirmable, ConfirmDialogProps } from "react-confirm";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { useQueryOrderFormOptions } from "../../api/Order";
import AutocompleteField from "../../components/AutocompleteField";
import { createConfirmation } from "../../components/ReactConfirmMountPoint";
import Loader from "../../components/Loader";

interface Values {
  sales_rep_uuid: string;
}

interface Props {
  title: string;
  description?: string;
}

export const SelectSalesRepFormDialog = ({
  show,
  proceed,
  title,
  description
}: ConfirmDialogProps<Props, false | string>) => {
  const { data: formOptions } = useQueryOrderFormOptions();
  const handleClose = () => proceed(false);

  const validationSchema = Yup.object<Values>().shape({
    sales_rep_uuid: Yup.string()
      .uuid()
      .required()
      .label("Sales Rep")
      .oneOf(formOptions?.sales_rep_users?.map(({ uuid }: any) => uuid) ?? [])
  });

  const formik = useFormik<Values>({
    initialValues: {
      sales_rep_uuid: ""
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: values => {
      const validated = validationSchema.cast(values, {
        stripUnknown: true
      });
      proceed(validated?.sales_rep_uuid);
    }
  });

  const { handleSubmit, dirty } = formik;

  return (
    <Dialog open={show} onClose={handleClose} fullWidth maxWidth="lg">
      <FormikProvider value={formik}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            {!!description && <DialogContentText>{description}</DialogContentText>}

            <AutocompleteField
              label="Sales Rep"
              name="sales_rep_uuid"
              options={formOptions?.sales_rep_users?.map((item: any) => ({
                label: item.email,
                id: item.uuid
              }))}
              fullWidth
              openOnFocus
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit" color="primary" disabled={!dirty}>
              Ok
            </Button>
          </DialogActions>
        </form>
      </FormikProvider>
    </Dialog>
  );
};

export const selectSalesRepFormDialog = createConfirmation(
  confirmable((props: ConfirmDialogProps<Props, false | string>) => (
    <Suspense fallback={<Loader />}>
      <SelectSalesRepFormDialog {...props} />
    </Suspense>
  ))
);
