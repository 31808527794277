import { TextField } from "@mui/material";
import { GridRenderEditCellParams } from "@mui/x-data-grid";
import { useGridApiContext } from "@mui/x-data-grid-pro";
import { DatePicker } from "@mui/x-date-pickers";
import { useState } from "react";

export const DataGridDatePicker = (props: GridRenderEditCellParams) => {
  const { id, value, field } = props;
  const apiRef = useGridApiContext();
  const [open, setOpen] = useState(true);

  return (
    <DatePicker
      label="Call Back Date"
      disablePast
      autoFocus
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      value={value}
      onChange={(newValue: any) => {
        if (newValue) {
          apiRef.current.setEditCellValue({
            id,
            field,
            value: newValue
          });
          apiRef.current.stopCellEditMode({ id, field });
        }
      }}
      slots={{
        textField: (params: any) => (
          <TextField
            {...params}
            // helperText={meta.touched && meta.error}
            // error={Boolean(meta.touched && meta.error)}
            label={null}
            variant="standard"
            margin="none"
          />
        )
      }}
    />
  );
};
