import React from "react";
import styled from "@emotion/styled";
import { Divider as MuiDivider, Paper as MuiPaper } from "@mui/material";
import { spacing } from "@mui/system";
import PageTitleWithLoading from "../../components/PageTitleWithLoading";
import { useParams } from "react-router-dom";
import { useMutationEditUser } from "../../api/User";
import UserForm from "./UserForm";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const UserEdit = () => {
  const { uuid } = useParams<string>();
  const mutation = useMutationEditUser(uuid ?? "");

  return (
    <Paper p={4}>
      <PageTitleWithLoading title="Edit User" />

      <Divider my={6} />

      <UserForm mutation={mutation} uuid={uuid} />
    </Paper>
  );
};

export default UserEdit;
