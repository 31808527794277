import { Box, Card, Menu, MenuItem, Paper, Stack, Typography } from "@mui/material";
import {
  GridSlotProps,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter
} from "@mui/x-data-grid-pro";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import React from "react";
import { MoreVertical } from "react-feather";
import { Helmet } from "react-helmet-async";

declare module "@mui/x-data-grid-pro" {
  interface ToolbarPropsOverrides {
    pageTitle: string;
  }
}

export const DataGridPageToolbarV2 = ({ pageTitle }: GridSlotProps["toolbar"]) => {
  // const apiRef = useGridApiContext();
  // const selection = useGridSelector(apiRef, gridRowSelectionStateSelector);
  // const location = useLocation();
  // const { isSuspendMutations } = useHealthChecker();

  return (
    <Stack
      spacing={4}
      direction="row"
      margin={4}
      alignItems="center"
      justifyContent="space-between"
    >
      <Helmet title={pageTitle} />

      <Typography variant="h3">{pageTitle}</Typography>
      {/*<PopupState variant="popover" popupId="demo-popup-menu">*/}
      {/*  {popupState => (*/}
      {/*    <>*/}
      {/*      <MoreVertical*/}
      {/*        fontSize="small"*/}
      {/*        style={{ color: "gray", cursor: "pointer" }}*/}
      {/*        {...bindTrigger(popupState)}*/}
      {/*      />*/}
      {/*      <Menu {...bindMenu(popupState)} sx={{ width: 320 }}>*/}
      {/*        <MenuItem*/}
      {/*        // onClick={() => {*/}
      {/*        //   handleDelete();*/}
      {/*        // }}*/}
      {/*        // disabled={isDeleting}*/}
      {/*        >*/}
      {/*          Delete*/}
      {/*        </MenuItem>*/}
      {/*      </Menu>*/}
      {/*    </>*/}
      {/*  )}*/}
      {/*</PopupState>*/}

      <Box sx={{ flexGrow: 1 }} />

      <GridToolbarContainer>
        <GridToolbarQuickFilter />
        <GridToolbarFilterButton />
      </GridToolbarContainer>
    </Stack>
  );
};
