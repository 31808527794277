import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import { confirmable, ConfirmDialogProps } from "react-confirm";

const ConfirmDialog = ({
  show,
  proceed,
  confirmation,
  options
}: ConfirmDialogProps<
  {
    confirmation: React.ReactNode;
    options: { title?: string; confirmLabel?: string; cancelLabel?: string };
  },
  boolean
>) => {
  const handleClose = (result: boolean) => () => proceed(result);

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {options?.title && <DialogTitle id="alert-dialog-title">{options.title}</DialogTitle>}
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{confirmation}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose(false)} color="primary">
          {options?.cancelLabel || "Cancel"}
        </Button>
        <Button onClick={handleClose(true)} color="primary" autoFocus>
          {options?.confirmLabel || "Confirm"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default confirmable(ConfirmDialog);
