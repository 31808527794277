import React from "react";
import styled from "@emotion/styled";
import { v4 } from "uuid";
import { Divider as MuiDivider, Paper as MuiPaper } from "@mui/material";
import { spacing } from "@mui/system";
import { useMutationAddProduct } from "../../api/Product";
import PageTitleWithLoading from "../../components/PageTitleWithLoading";
import ProductForm from "./ProductForm";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const ProductAdd = () => {
  const mutation = useMutationAddProduct(v4());

  return (
    <Paper p={4}>
      <PageTitleWithLoading title="Add Product" />

      <Divider my={6} />

      <ProductForm mutation={mutation} />
    </Paper>
  );
};

export default ProductAdd;
