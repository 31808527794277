import { useParams } from "react-router-dom";
import {
  CardActions,
  CardContent,
  Link as MuiLink,
  Stack,
  Typography as MuiTypography
} from "@mui/material";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import { Call as CallIcon } from "@mui/icons-material";
import React from "react";
import styled from "@emotion/styled";
import { spacing, SpacingProps } from "@mui/system";
import { useQueryOneUser } from "../../../api/User.ts";

interface TypographyProps extends SpacingProps {
  component?: string;
}
const Typography = styled(MuiTypography)<TypographyProps>(spacing);

const AboutIcon = styled.span`
  display: flex;
  svg {
    width: 14px;
    height: 14px;
  }
`;

function SalesRepCard({ sales_rep_user_uuid }: { sales_rep_user_uuid?: string }) {
  const { uuid } = useParams();
  const { data: sales_rep } = useQueryOneUser(sales_rep_user_uuid ?? uuid);

  return (
    <>
      <CardContent>
        <Typography gutterBottom variant="h3" component="div">
          {sales_rep?.full_name}
        </Typography>

        {!!sales_rep?.phone_number && (
          <Stack direction="row" spacing={2} my={2} alignItems="center">
            <AboutIcon>
              <CallIcon />
            </AboutIcon>
            <MuiLink href={`tel:${sales_rep.phone_number}`}>{sales_rep.phone_number}</MuiLink>
          </Stack>
        )}
        {!!sales_rep?.email && (
          <Stack direction="row" spacing={2} my={2} alignItems="center">
            <AboutIcon>
              <AlternateEmailIcon />
            </AboutIcon>
            <MuiLink href={`mailto:${sales_rep.email}`}>{sales_rep.email}</MuiLink>
          </Stack>
        )}
      </CardContent>
      <CardActions></CardActions>
    </>
  );
}

export default SalesRepCard;
