import CustomersList from "./CustomersList";
import CustomerAdd from "./CustomerAdd";
import CustomerEdit from "./CustomerEdit";
import CustomerDetails from "./CustomerDetails";

const routes = [
  {
    path: "/customers",
    element: <CustomersList />
  },
  {
    path: "/customers/add",
    element: <CustomerAdd />
  },
  {
    path: "/customers/:uuid/edit",
    element: <CustomerEdit />
  },
  {
    path: "/customers/:uuid/details",
    element: <CustomerDetails />
  }
];

export default routes;
