import React from "react";
import {
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography
} from "@mui/material";
import { CalculateOrderLineItemPriceType } from "aldrin-erp-main-shared-functions/src/inventory_item/calculateOrderLineItemPrice";
import { Feature } from "use-feature";
import styled from "@emotion/styled";
import async from "../../../components/Async";
import { useOrderLinePriceConfig } from "./useOrderLinePriceConfig.ts";

const Code = async(() => import("../../../components/Code"));

interface Props {
  product_id?: number;
  config_values?: CalculateOrderLineItemPriceType["config_values"];
  config_options?: CalculateOrderLineItemPriceType["config_options"];
  markup_percentage?: CalculateOrderLineItemPriceType["markup_percentage"];
}

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 1200
  }
});

export const PricingBreakdownTable = (props: Props) => {
  const priceConfig = useOrderLinePriceConfig(props);

  return (
    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
      <TableHead>
        <TableRow>
          <TableCell>Code</TableCell>
          <TableCell>Inventory Item</TableCell>
          <TableCell>Field</TableCell>
          <TableCell align="right">Width</TableCell>
          <TableCell align="right">Height</TableCell>
          <TableCell align="right">Depth</TableCell>
          <TableCell align="right">Qty.</TableCell>
          <TableCell align="right">Unit Price</TableCell>
          <TableCell align="right">Sub-total</TableCell>
          <Feature name="FEATURE_DEBUG_ORDER">
            <TableCell align="right">Info</TableCell>
          </Feature>
        </TableRow>
      </TableHead>
      <TableBody>
        {priceConfig?.config &&
          Object.entries(priceConfig?.config).map(([optionName, conf]) => (
            <TableRow
              hover
              key={optionName}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="right">{conf.code}</TableCell>
              <TableCell component="th" scope="row">
                {conf.label}
              </TableCell>
              <TableCell component="th" scope="row">
                {conf.question ?? "N/A"}
              </TableCell>
              <TableCell align="right">{conf.ITEMWIDTH}</TableCell>
              <TableCell align="right">{conf.ITEMHEIGHT}</TableCell>
              <TableCell align="right">{conf.ITEMDEPTH}</TableCell>
              <TableCell align="right">{conf.QTY}</TableCell>
              <TableCell align="right">
                {conf.unitPriceDecimal}{" "}
                {!conf.isAvailable && <Chip label="(N/A)" color="error" size="small" />}
              </TableCell>
              <TableCell align="right">{conf.subtotalPriceDecimal}</TableCell>
              <Feature name="FEATURE_DEBUG_ORDER">
                <TableCell align="right">
                  <CustomWidthTooltip title={<Code>{JSON.stringify(conf, null, "\t")}</Code>}>
                    <Chip label={"info"} size="small" />
                  </CustomWidthTooltip>
                </TableCell>
              </Feature>
            </TableRow>
          ))}

        {!!priceConfig?.markup_percentage && (
          <TableRow selected>
            <TableCell rowSpan={1} />
            <TableCell colSpan={7} align="right">
              <Typography variant="subtitle2">Markup +{priceConfig?.markup_percentage}%</Typography>
            </TableCell>
            <TableCell align="right">{priceConfig?.markupDecimal}</TableCell>
          </TableRow>
        )}

        <TableRow selected>
          <TableCell rowSpan={2} />
          <TableCell colSpan={7} align="right">
            <Typography variant="subtitle2">Total</Typography>
          </TableCell>
          <TableCell align="right">{priceConfig?.priceDecimal}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
