import {
  useMutationAbstract,
  useMutationBulkDeleteModelV2,
  useMutationDeleteModel,
  useQueryModels,
  useQueryOneModel
} from "../hooks/useMutationFormAbstract";
import axios from "../utils/axios";
import sleep from "../utils/sleep";
import * as InventoryItem from "../api/InventoryItem";

export const QUERY_KEY = "price-grid";

export const useQueryPriceGrids = () => useQueryModels(QUERY_KEY);

export const useQueryOnePriceGrid = (uuid?: string) => useQueryOneModel<any>(QUERY_KEY, uuid);

export const useMutationAddPriceGrid = (uuid: string) => {
  return useMutationAbstract({
    invalidateQueryKey: [InventoryItem.QUERY_KEY],
    mutationKey: [QUERY_KEY],
    mutationFn: data =>
      axios
        .post(`/api/${QUERY_KEY}`, {
          ...data,
          uuid
        })
        .then(({ data }) => data)
        .then(async response => {
          // we have to do it this way to wait for event handlers to process changes
          await sleep(1500);

          return response;
        })
  });
};

export const useMutationDeletePriceGrid = () => useMutationDeleteModel(QUERY_KEY);

export const useMutationBulkDeletePriceGrid = () => useMutationBulkDeleteModelV2(QUERY_KEY);
