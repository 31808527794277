import OrderList from "../pages/order/OrderList.tsx";
import useAuth from "../hooks/useAuth.ts";
import { useMemo } from "react";
import SalesRepDashboard from "../pages/user/sales_rep/SalesRepDashboard.tsx";

export const DynamicDashboard = () => {
  const { user } = useAuth();

  return useMemo(() => {
    if (user && user.roles.includes("SALES_REP")) {
      return <SalesRepDashboard sales_rep_user_uuid={user.uuid} />;
    }

    return <OrderList />;
  }, [user]);
};
