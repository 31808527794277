import React from "react";
import { Field, FieldProps, Formik } from "formik";
import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField as MuiTextField,
  Theme
} from "@mui/material";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { spacing, SpacingProps } from "@mui/system";
import { MutationResultType } from "../../hooks/useMutationFormAbstract";
import { QUERY_KEY, useQueryCompanyFormOptions, useQueryOneCompany } from "../../api/Company";
import { Refresh as RefreshIcon } from "@mui/icons-material";
import useIsLoading from "../../hooks/useIsLoading";
import useHealthChecker from "../../hooks/useHealthChecker";
import useFormikSubmitHandler from "../../hooks/useFormikSubmitHandler";
import { useTheme } from "@mui/material/styles";
import { SuccessResponse, UppyFile } from "@uppy/core";
import UploaderModal from "../../components/UploaderModal";
import { BooleanParam, useQueryParam, withDefault } from "use-query-params";
import { Feature } from "use-feature";

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

function getStyles(name: string, options: readonly string[], theme: Theme) {
  return {
    fontWeight:
      options.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium
  };
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required().max(255),
  image: Yup.string().url().optional().nullable(),
  address: Yup.string().max(255).nullable(),
  phone: Yup.string().max(100).nullable(),
  email: Yup.string().email().nullable(),
  accounts_email: Yup.string().email().nullable(),
  bank: Yup.string().max(100).nullable(),
  abn: Yup.string().max(100).nullable(),
  acn: Yup.string().max(100).nullable(),
  bsb: Yup.string().max(100).nullable(),
  acc: Yup.string().max(100).nullable(),
  customer_group_uuids: Yup.array().of(Yup.string().uuid())
});

interface Values {
  name?: string;
  image?: string;
  address?: string;
  phone?: string;
  email?: string;
  accounts_email?: string;
  bank?: string;
  abn?: string;
  acn?: string;
  bsb?: string;
  acc?: string;
  customer_group_uuids?: any[];
}

const CompanyForm: React.VFC<{
  mutation: MutationResultType;
  uuid?: string;
}> = ({ mutation, uuid }) => {
  const { data } = useQueryOneCompany(uuid);
  const isLoading = useIsLoading([QUERY_KEY]);
  const { isSuspendMutations } = useHealthChecker();
  const { data: formOptions } = useQueryCompanyFormOptions();
  const theme = useTheme();

  const handleSubmit = useFormikSubmitHandler<Values>({
    mutation,
    navigate_to: "/company",
    validationSchema
  });

  const [openUploadModal, setOpenUploadModal] = useQueryParam(
    "open-upload-modal",
    withDefault(BooleanParam, false)
  );

  return (
    <Formik
      initialValues={{
        name: "",
        image: "",
        address: "",
        phone: "",
        email: "",
        accounts_email: "",
        bank: "",
        abn: "",
        acn: "",
        bsb: "",
        acc: "",
        customer_group_uuids: [],
        ...data
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        dirty,
        setValues,
        setFieldValue
      }) => (
        <Card mb={6}>
          <CardContent>
            <form onSubmit={handleSubmit}>
              <TextField
                name="name"
                label="Name"
                value={values.name ?? ""}
                error={Boolean(touched.name && errors.name)}
                fullWidth
                helperText={touched.name && errors.name}
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                variant="outlined"
                my={2}
                disabled={isLoading}
              />
              <TextField
                name="image"
                label="Image URL"
                value={values.image ?? ""}
                error={Boolean(touched.image && errors.image)}
                fullWidth
                helperText={touched.image && errors.image}
                onBlur={handleBlur}
                onChange={handleChange}
                type="url"
                variant="outlined"
                my={2}
                disabled={isLoading}
                onClick={() => setOpenUploadModal(true)}
              />

              {values.image && (
                <Card>
                  <CardContent>
                    <img src={values.image} alt="" height={200} />
                  </CardContent>
                </Card>
              )}

              <UploaderModal
                open={openUploadModal}
                onRequestClose={() => setOpenUploadModal(false)}
                onUploadSuccess={(file: UppyFile | undefined, response: SuccessResponse) => {
                  setFieldValue("image", response.uploadURL);
                }}
                note={"Max 20 files. Each file size should be less than 64 MB"}
                closeAfterFinish
              />

              <TextField
                name="address"
                label="Address"
                value={values.address ?? ""}
                error={Boolean(touched.address && errors.address)}
                fullWidth
                helperText={touched.address && errors.address}
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                variant="outlined"
                my={2}
                disabled={isLoading}
              />

              <TextField
                name="phone"
                label="Phone"
                value={values.phone ?? ""}
                error={Boolean(touched.phone && errors.phone)}
                fullWidth
                helperText={touched.phone && errors.phone}
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                variant="outlined"
                my={2}
                disabled={isLoading}
              />

              <TextField
                name="email"
                label="Email"
                value={values.email ?? ""}
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                variant="outlined"
                my={2}
                disabled={isLoading}
              />

              <TextField
                name="accounts_email"
                label="Accounts Email"
                value={values.accounts_email ?? ""}
                error={Boolean(touched.accounts_email && errors.accounts_email)}
                fullWidth
                helperText={touched.accounts_email && errors.accounts_email}
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                variant="outlined"
                my={2}
                disabled={isLoading}
              />

              <TextField
                name="bank"
                label="Bank"
                value={values.bank ?? ""}
                error={Boolean(touched.bank && errors.bank)}
                fullWidth
                helperText={touched.bank && errors.bank}
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                variant="outlined"
                my={2}
                disabled={isLoading}
              />

              <TextField
                name="abn"
                label="ABN"
                value={values.abn ?? ""}
                error={Boolean(touched.abn && errors.abn)}
                fullWidth
                helperText={touched.abn && errors.abn}
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                variant="outlined"
                my={2}
                disabled={isLoading}
              />

              <TextField
                name="acn"
                label="ACN"
                value={values.acn ?? ""}
                error={Boolean(touched.acn && errors.acn)}
                fullWidth
                helperText={touched.acn && errors.acn}
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                variant="outlined"
                my={2}
                disabled={isLoading}
              />

              <TextField
                name="bsb"
                label="BSB"
                value={values.bsb ?? ""}
                error={Boolean(touched.bsb && errors.bsb)}
                fullWidth
                helperText={touched.bsb && errors.bsb}
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                variant="outlined"
                my={2}
                disabled={isLoading}
              />

              <TextField
                name="acc"
                label="ACC"
                value={values.acc ?? ""}
                error={Boolean(touched.acc && errors.acc)}
                fullWidth
                helperText={touched.acc && errors.acc}
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                variant="outlined"
                my={2}
                disabled={isLoading}
              />

              <Field name="customer_group_uuids">
                {({ field, form }: FieldProps) => {
                  return (
                    <FormControl fullWidth disabled={isLoading} margin="normal">
                      <InputLabel id="customer-group-label">Customer Group</InputLabel>
                      <Select
                        labelId="customer-group-label"
                        multiple
                        value={field.value || []}
                        onChange={({ target }) => {
                          form.setFieldValue(field.name, target.value);
                        }}
                        input={<OutlinedInput label="Marketing" />}
                        renderValue={selected => (
                          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                            {selected.map((value: any) => (
                              <Chip
                                key={value}
                                label={
                                  formOptions?.customer_groups?.find(
                                    ({ uuid }: any) => uuid === value
                                  )?.name
                                }
                              />
                            ))}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                      >
                        {formOptions?.customer_groups?.map(({ uuid, name }: any) => (
                          <MenuItem
                            key={uuid}
                            value={uuid}
                            style={getStyles(name, formOptions?.customer_groups, theme)}
                          >
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  );
                }}
              </Field>

              <Grid container justifyContent="space-between">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  mt={3}
                  disabled={!dirty || isLoading || isSuspendMutations}
                >
                  Submit
                </Button>

                <Feature name="FEATURE_SEED_DATA">
                  <Button
                    variant="contained"
                    color="primary"
                    mt={3}
                    onClick={async () => {
                      const { faker } = await import("@faker-js/faker");

                      setValues(prevValues => ({
                        ...prevValues,
                        name: faker.random.words(2),
                        image: faker.internet.url(),
                        address: faker.address.streetAddress(),
                        phone: faker.phone.phoneNumber(),
                        email: faker.internet.email()
                      }));
                    }}
                  >
                    <RefreshIcon /> Seed Data
                  </Button>
                </Feature>
              </Grid>
            </form>
          </CardContent>
        </Card>
      )}
    </Formik>
  );
};

export default CompanyForm;
