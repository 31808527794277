import { PriceGridConfType } from "./processInventoryItemPriceGridXlsxImported";
import { findIndex } from "lodash";
import assert from "node:assert";

export const calculatePriceFromGrid = (
  specifiedWidth: number,
  specifiedHeight: number,
  specifiedDepth: number,
  widthValues: PriceGridConfType["width"],
  heightDepthValues: PriceGridConfType["height_depth"],
  matrixValues: PriceGridConfType["matrix"]
): number => {
  assert.ok(specifiedWidth >= 0, "Specified width should be >= 0");
  assert.ok(specifiedHeight >= 0, "Specified height should be >= 0");
  assert.ok(specifiedDepth >= 0, "Specified depth should be >= 0");

  const widthIndex = findIndex(widthValues, width => specifiedWidth <= width);
  const heightIndex = findIndex(
    heightDepthValues?.[specifiedDepth],
    height => specifiedHeight <= height
  );

  return matrixValues?.[specifiedDepth]?.[heightIndex - 1]?.[widthIndex - 1] ?? 0;
};
