import UserRoleList from "./UserRoleList";
import UserRoleAdd from "./UserRoleAdd";
import UserRoleEdit from "./UserRoleEdit";

const routes = [
  {
    path: "/user-role",
    element: <UserRoleList />
  },
  {
    path: "/user-role/add",
    element: <UserRoleAdd />
  },
  {
    path: "/user-role/:uuid/edit",
    element: <UserRoleEdit />
  }
];

export default routes;
