import React from "react";
import { Formik } from "formik";
import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Chip,
  FormControl as MuiFormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField as MuiTextField,
  Typography
} from "@mui/material";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { spacing, SpacingProps } from "@mui/system";
import { MutationResultType } from "../../hooks/useMutationFormAbstract";
import { useQueryUserFormOptions } from "../../api/User";
import useIsLoading from "../../hooks/useIsLoading";
import useHealthChecker from "../../hooks/useHealthChecker";
import useFormikSubmitHandler from "../../hooks/useFormikSubmitHandler";
import { Refresh as RefreshIcon } from "@mui/icons-material";
import { USER_ROLES } from "../../constants";
import { Feature } from "use-feature";

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const FormControl = styled(MuiFormControl)(spacing);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

interface ButtonProps extends SpacingProps {
  component?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

const validationSchema = Yup.object().shape({
  email: Yup.string().trim().max(255).email().required("Required"),
  roles: Yup.array(Yup.string()).min(1).required("Required"),
  first_name: Yup.string().trim().min(2).max(255).optional(),
  last_name: Yup.string().trim().min(2).max(255).optional(),
  phone_number: Yup.string().trim().min(2).max(255).optional()
});

interface Values {
  email?: string;
  roles?: string[];
  first_name?: string;
  last_name?: string;
  phone_number?: string;
}

const UserInviteForm: React.VFC<{
  mutation: MutationResultType;
}> = ({ mutation }) => {
  const { data: formOptionsData } = useQueryUserFormOptions();
  const isLoading = useIsLoading(["users"]);
  const { isSuspendMutations } = useHealthChecker();

  const handleSubmit = useFormikSubmitHandler<Values>({
    mutation,
    navigate_to: "/users",
    validationSchema
  });

  return (
    <Formik
      initialValues={{
        email: "",
        phone_number: "",
        first_name: "",
        last_name: ""
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, touched, values, dirty, setValues }) => (
        <Card mb={6}>
          <CardContent>
            <form onSubmit={handleSubmit}>
              <Typography variant="body2" gutterBottom>
                The user will receive an email to set their own password.
              </Typography>

              <TextField
                name="email"
                label="Email"
                value={values.email ?? ""}
                error={Boolean(touched?.email && errors?.email)}
                fullWidth
                helperText={touched.email && errors.email}
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                variant="outlined"
                my={2}
                disabled={isLoading}
              />

              <FormControl fullWidth error={Boolean(touched.roles && errors.roles)} my={2}>
                <InputLabel id="demo-multiple-chip-label">Roles</InputLabel>
                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  name="roles"
                  value={values.roles ?? []}
                  onChange={handleChange}
                  error={Boolean(touched.roles && errors.roles)}
                  input={<OutlinedInput id="select-multiple-chip" label="Roles" />}
                  renderValue={selected => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map(value => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                  disabled={isLoading}
                >
                  {formOptionsData?.roles.map((name: string) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                name="first_name"
                label="First Name"
                value={values.first_name ?? ""}
                error={Boolean(touched.first_name && errors.first_name)}
                fullWidth
                helperText={touched.first_name && errors.first_name}
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                variant="outlined"
                my={2}
                disabled={isLoading}
              />

              <TextField
                name="last_name"
                label="Last Name"
                value={values.last_name ?? ""}
                error={Boolean(touched.last_name && errors.last_name)}
                fullWidth
                helperText={touched.last_name && errors.last_name}
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                variant="outlined"
                my={2}
                disabled={isLoading}
              />

              <TextField
                name="phone_number"
                label="Phone Number"
                value={values.phone_number ?? ""}
                error={Boolean(touched.phone_number && errors.phone_number)}
                fullWidth
                helperText={touched.phone_number && errors.phone_number}
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                variant="outlined"
                my={2}
                disabled={isLoading}
              />

              <Grid container justifyContent="space-between">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  mt={3}
                  disabled={!dirty || isLoading || isSuspendMutations}
                >
                  Send invite
                </Button>

                <Feature name="FEATURE_SEED_DATA">
                  <Button
                    variant="contained"
                    color="primary"
                    mt={3}
                    onClick={async () => {
                      const { faker } = await import("@faker-js/faker");

                      setValues(prevValues => {
                        const fname = faker.name.firstName();
                        const lname = faker.name.lastName();

                        return {
                          ...prevValues,
                          email: faker.internet.email(fname, lname),
                          first_name: fname,
                          last_name: lname,
                          phone_number: faker.phone.number(),
                          roles: faker.helpers.arrayElements(Object.values(USER_ROLES))
                        };
                      });
                    }}
                  >
                    <RefreshIcon /> Seed Data
                  </Button>
                </Feature>
              </Grid>
            </form>
          </CardContent>
        </Card>
      )}
    </Formik>
  );
};

export default UserInviteForm;
