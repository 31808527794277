import OrderStatusList from "./OrderStatusList";
import OrderStatusAdd from "./OrderStatusAdd";
import OrderStatusEdit from "./OrderStatusEdit";

const routes = [
  {
    path: "/order-status",
    element: <OrderStatusList />
  },
  {
    path: "/order-status/add",
    element: <OrderStatusAdd />
  },
  {
    path: "/order-status/:uuid/edit",
    element: <OrderStatusEdit />
  }
];

export default routes;
