import { toDecimalFromFloat } from "aldrin-erp-main-shared-functions";
import { useDataGridFilterModelQueryParams } from "../../hooks/useDataGridFilterModelQueryParams";
import {
  gridFilterModelSelector,
  gridPaginationRowCountSelector,
  useGridApiContext,
  useGridSelector
} from "@mui/x-data-grid-pro";
import { useQueryOutstandingOrdersStatistics } from "../../api/Order";
import { TextField } from "@mui/material";

export const ToolbarStatistics = () => {
  const apiRef = useGridApiContext();
  const filterModel = useGridSelector(apiRef, gridFilterModelSelector);
  const filterQueryParams = useDataGridFilterModelQueryParams(filterModel);
  const { data: stats } = useQueryOutstandingOrdersStatistics(filterQueryParams);
  const rowCount = useGridSelector(apiRef, gridPaginationRowCountSelector);

  return (
    <>
      <TextField
        label="Deposits Held"
        inputProps={{
          readOnly: true
        }}
        InputLabelProps={{
          shrink: true
        }}
        InputProps={{
          disableUnderline: true
        }}
        value={toDecimalFromFloat(Number(stats?.depositsHeld) ?? 0.0)}
        size="small"
        variant="standard"
        sx={{ width: 100 }}
      />

      <TextField
        label="Total Value"
        inputProps={{
          readOnly: true
        }}
        InputLabelProps={{
          shrink: true
        }}
        InputProps={{
          disableUnderline: true
        }}
        value={toDecimalFromFloat(Number(stats?.totalValue) ?? 0.0)}
        size="small"
        variant="standard"
        sx={{ width: 100 }}
      />

      <TextField
        label="Total Count"
        inputProps={{
          readOnly: true
        }}
        InputLabelProps={{
          shrink: true
        }}
        InputProps={{
          disableUnderline: true
        }}
        value={rowCount ?? 0}
        size="small"
        variant="standard"
        sx={{ width: 80 }}
      />
    </>
  );
};
