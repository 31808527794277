import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  useMutationAbstract,
  useQueryFormOptionsModel,
  useQueryModelsV2,
  useQueryOneModel
} from "../hooks/useMutationFormAbstract";
import axios from "../utils/axios";
import sleep from "../utils/sleep";
import { v4 } from "uuid";
import { useState } from "react";

export const QUERY_KEY = "order-receivable";

export const useQueryOrderReceivables = (query?: any) => useQueryModelsV2(QUERY_KEY, query);

export const useQueryOneOrderReceivable = (uuid?: string) => useQueryOneModel<any>(QUERY_KEY, uuid);

export const useQueryOrderReceivablesStatistics = (query: any) =>
  useQuery<{
    totalOutstandingAmount: number;
    totalOutstandingAmount30Days: number;
    totalOutstandingAmount60Days: number;
    totalOutstandingAmount90Days: number;
    totalOutstandingAmountOlder: number;
  }>({
    queryKey: [`${QUERY_KEY}/statistics`, "statistics", query]
  });

export const useQueryOrderReceivableFormOptions = () =>
  useQueryFormOptionsModel<{
    status: string[];
    users: any[];
    customer_groups: any[];
    customers: any[];
  }>(QUERY_KEY);

export const useMutationSaveOrderReceivable = () => {
  const queryClient = useQueryClient();

  const [idempotencyKey, setIdempotencyKey] = useState(v4());

  return useMutationAbstract<{
    order_uuid: string;
    status?: string | null;
    detail?: string | null;
    assigned_to_user_uuid?: string | null;
  }>({
    mutationKey: [QUERY_KEY, "save"],
    mutationFn: ({ order_uuid, ...data }) => {
      return axios
        .post(`/api/${QUERY_KEY}/${order_uuid}`, data, {
          headers: {
            "Idempotency-Key": idempotencyKey
          }
        })
        .then(({ data }) => data)
        .then(async response => {
          // we have to do it this way to wait for event handlers to process changes
          await sleep(1500);

          setIdempotencyKey(v4());

          await queryClient.invalidateQueries([QUERY_KEY]);

          return response;
        });
    }
  });
};
