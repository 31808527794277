import React from "react";
import { Formik } from "formik";
import {
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  TextField as MuiTextField,
  Typography
} from "@mui/material";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { spacing, SpacingProps } from "@mui/system";
import { MutationResultType } from "../../hooks/useMutationFormAbstract";
import {
  QUERY_KEY,
  useQueryInventoryItemFormOptions,
  useQueryOneInventoryItem
} from "../../api/InventoryItem";
import { Refresh as RefreshIcon } from "@mui/icons-material";
import useIsLoading from "../../hooks/useIsLoading";
import useHealthChecker from "../../hooks/useHealthChecker";
import useFormikSubmitHandler from "../../hooks/useFormikSubmitHandler";
import AutocompleteField from "../../components/AutocompleteField";
import { useNavigate } from "react-router-dom";
import { Feature } from "use-feature";

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

interface Values {
  code: string;
  description?: string;
  manufacture_time?: number;
  install_duration?: number;
  lead_time?: number;
  price?: number;
  status?: string;
  is_qbcc?: boolean;
  is_check_measure?: boolean;
  is_outsourced?: boolean;
  is_powder_coat?: boolean;
  is_fabric?: boolean;
  product_sold_by_uuid?: string;
  product_line_uuid?: string;
}

const InventoryItemForm: React.VFC<{
  mutation: MutationResultType;
  uuid?: string;
  isEdit?: boolean;
}> = ({ mutation, uuid }) => {
  const { data } = useQueryOneInventoryItem(uuid);
  const { data: formOptions } = useQueryInventoryItemFormOptions();
  const isLoading = useIsLoading([QUERY_KEY]);
  const { isSuspendMutations } = useHealthChecker();
  const navigate = useNavigate();

  const productSoldByOptions =
    formOptions?.product_sold_by?.map((item: any) => {
      return {
        label: item.name,
        id: item.uuid
      };
    }) ?? [];

  const productSoldByGridUUID = "fcd532ec-7e65-418b-9102-0a970dc84da2";

  const productLineOptions =
    formOptions?.product_lines?.map((item: any) => {
      return {
        label: item.name,
        id: item.uuid
      };
    }) ?? [];

  const validationSchema = Yup.object().shape({
    code: Yup.string().required().max(255),
    description: Yup.string().max(255).optional().nullable(),
    manufacture_time: Yup.number().integer().optional().nullable(),
    install_duration: Yup.number().integer().optional().nullable(),
    lead_time: Yup.number().integer().optional().nullable(),
    price: Yup.number().optional().nullable(),
    status: Yup.string().max(255).optional().nullable(),
    product_line_uuid: Yup.string()
      .uuid()
      .required()
      .label("Line")
      .nullable()
      .oneOf(productLineOptions.map(({ id }: any) => id)),
    product_sold_by_uuid: Yup.string()
      .uuid()
      .required()
      .label("Sold By")
      .nullable()
      .oneOf(productSoldByOptions.map(({ id }: any) => id)),
    is_qbcc: Yup.boolean().label("Is QBCC?"),
    is_check_measure: Yup.boolean().label("Is Check Measure?"),
    is_outsourced: Yup.boolean().label("Is Outsourced?").nullable(),
    is_powder_coat: Yup.boolean().label("Is Powder Coat?").nullable(),
    is_fabric: Yup.boolean().label("Is Fabric?").nullable()
  });

  const handleSubmit = useFormikSubmitHandler<Values>({
    mutation,
    navigate_to: "/inventory-item",
    validationSchema,
    mutateOptions: {
      onSuccess: (data, variables) => {
        navigate(
          `/inventory-item/${data.uuid}/details?open-upload-price-grid-modal=${
            variables.product_sold_by_uuid === productSoldByGridUUID ? 1 : 0
          }`
        );
      }
    }
  });

  return (
    <Formik<Values>
      initialValues={{
        code: "",
        description: "",
        manufacture_time: "",
        product_sold_by_uuid: null,
        product_line_uuid: null,
        price: "",
        is_qbcc: false,
        is_check_measure: false,
        is_outsourced: false,
        is_powder_coat: false,
        is_fabric: false,
        ...data
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, touched, values, dirty, setValues }) => (
        <Card mb={6}>
          <CardContent>
            <form onSubmit={handleSubmit}>
              <TextField
                name="code"
                label="Inventory Code"
                value={values.code ?? ""}
                error={Boolean(touched.code && errors.code)}
                fullWidth
                helperText={touched.code && errors.code}
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                variant="outlined"
                my={2}
                disabled={isLoading}
              />

              <TextField
                name="description"
                label="Description"
                value={values.description ?? ""}
                error={Boolean(touched.code && errors.description)}
                fullWidth
                helperText={touched.code && errors.code}
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                variant="outlined"
                my={2}
                disabled={isLoading}
              />

              <AutocompleteField
                label="Choose inventory line"
                name="product_line_uuid"
                options={productLineOptions}
                fullWidth
                openOnFocus
                disabled={isLoading}
              />

              <AutocompleteField
                label="Choose what the item is sold by"
                name="product_sold_by_uuid"
                options={productSoldByOptions}
                fullWidth
                openOnFocus
                disabled={isLoading}
              />

              {values.product_sold_by_uuid === productSoldByGridUUID ? null : (
                <TextField
                  name="price"
                  label="Amount the item is sold by"
                  value={values.price ?? 0}
                  error={Boolean(touched.price && errors.price)}
                  fullWidth
                  helperText={touched.price && errors.price}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="number"
                  variant="outlined"
                  my={2}
                  disabled={isLoading}
                />
              )}

              {values.product_sold_by_uuid === productSoldByGridUUID && (
                <Typography variant="caption" paragraph>
                  You will be asked to import a spreadsheet for the price grid after submitting.
                </Typography>
              )}

              <TextField
                name="manufacture_time"
                label="The time it takes to manufacture the item"
                value={values.manufacture_time ?? 0}
                error={Boolean(touched.manufacture_time && errors.manufacture_time)}
                fullWidth
                helperText={touched.manufacture_time && errors.manufacture_time}
                onBlur={handleBlur}
                onChange={handleChange}
                type="number"
                variant="outlined"
                my={2}
                disabled={isLoading}
                InputProps={{
                  endAdornment: <InputAdornment position="end">minutes</InputAdornment>
                }}
              />

              <TextField
                name="install_duration"
                label="The time it takes to install the item"
                value={values.install_duration ?? 0}
                error={Boolean(touched.install_duration && errors.install_duration)}
                fullWidth
                helperText={touched.install_duration && errors.install_duration}
                onBlur={handleBlur}
                onChange={handleChange}
                type="number"
                variant="outlined"
                my={2}
                disabled={isLoading}
                InputProps={{
                  endAdornment: <InputAdornment position="end">minutes</InputAdornment>
                }}
              />

              <TextField
                name="lead_time"
                label="The time it takes to prepare the item"
                value={values.lead_time ?? 0}
                error={Boolean(touched.lead_time && errors.lead_time)}
                fullWidth
                helperText={touched.lead_time && errors.lead_time}
                onBlur={handleBlur}
                onChange={handleChange}
                type="number"
                variant="outlined"
                my={2}
                disabled={isLoading}
                InputProps={{
                  endAdornment: <InputAdornment position="end">days</InputAdornment>
                }}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.is_qbcc}
                    value={true}
                    name="is_qbcc"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                }
                label="Is QBCC?"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.is_check_measure}
                    value={true}
                    name="is_check_measure"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                }
                label="Is Check Measure?"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.is_outsourced ?? false}
                    value={true}
                    name="is_outsourced"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                }
                label="Is Outsourced?"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.is_powder_coat ?? false}
                    value={true}
                    name="is_powder_coat"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                }
                label="Is Powder Coat?"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.is_fabric ?? false}
                    value={true}
                    name="is_fabric"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                }
                label="Is Fabric?"
              />

              <Grid container justifyContent="space-between">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  mt={3}
                  disabled={!dirty || isLoading || isSuspendMutations}
                >
                  Submit
                </Button>

                <Feature name="FEATURE_SEED_DATA">
                  <Button
                    variant="contained"
                    color="primary"
                    mt={3}
                    onClick={async () => {
                      const { faker } = await import("@faker-js/faker");

                      setValues(prevValues => ({
                        ...prevValues,
                        code: faker.random.alphaNumeric(10),
                        description: faker.lorem.paragraph(),
                        manufacture_time: faker.datatype.number(5000),
                        install_duration: faker.datatype.number(5000),
                        lead_time: faker.datatype.number(5000),
                        price: faker.datatype.number(5000),
                        product_line_uuid: faker.helpers.arrayElement(
                          productLineOptions.map(({ id }: any) => id)
                        ),
                        product_sold_by_uuid: faker.helpers.arrayElement(
                          productSoldByOptions.map(({ id }: any) => id)
                        ),
                        is_qbcc: faker.datatype.boolean(),
                        is_check_measure: faker.datatype.boolean()
                      }));
                    }}
                  >
                    <RefreshIcon /> Seed Data
                  </Button>
                </Feature>
              </Grid>
            </form>
          </CardContent>
        </Card>
      )}
    </Formik>
  );
};

export default InventoryItemForm;
