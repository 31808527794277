import { useQuery } from "@tanstack/react-query";
import { healthCheckerIntervalMillis } from "../config";
import { AxiosError } from "axios";

export const useQueryHealthChecks = () => {
  return useQuery<any, AxiosError>({
    queryKey: ["healthcheck"],
    useErrorBoundary: error => {
      return [0, 503].includes(error.response?.status ?? 0);
    },
    refetchOnWindowFocus: true,
    refetchInterval: healthCheckerIntervalMillis,
    retry: (failureCount, error) => {
      if (failureCount >= 3) {
        return false;
      }

      return [0, 503].includes(error.response?.status ?? 0);
    },
    meta: { cache: false }
  });
};
