import { useFormikContext } from "formik";
import { get } from "lodash";
import { useEffect } from "react";

interface Props {
  conf: any;
}

export const useHandleLookbackFieldValueChanged = ({ conf }: Props) => {
  const { isSubmitting, values, setFieldValue, touched, dirty } = useFormikContext();

  const fieldName = `config_values[${conf["Unique Option Name"]}]`;
  const lookback = conf["Look Back @ BUZ Column Name"];
  const lookbackValueLabel: string | undefined = get(values, `config_values.${lookback}.label`);
  const isLookbackTouched = Boolean(get(touched, `config_values.${lookback}`));

  useEffect(() => {
    if (dirty && isLookbackTouched && !isSubmitting) {
      setFieldValue(fieldName, undefined);
    }
  }, [lookbackValueLabel, fieldName, setFieldValue, isLookbackTouched, dirty, isSubmitting]);

  return { lookback, lookbackValueLabel };
};
