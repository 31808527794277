import { CellObject } from "xlsx";
import {
  chain,
  compact,
  dropRightWhile,
  head,
  isEmpty,
  isNil,
  isUndefined,
  omitBy,
  slice,
  takeWhile,
  uniqBy,
  unzip,
  zipObject
} from "lodash";
import assert from "node:assert";
import { array, lazy, mixed, number, object, ref, string } from "yup";

const booleanYesOrNo = string()
  .notRequired()
  .test("should-be-Y-N", `$\{path} should be Y, N or empty. Value: $\{value}`, val =>
    ["", "Y", "N"].includes(val as string)
  );

const stringShouldBeUppercase = string().test(
  "should-be-all-uppercase",
  `$\{path} should be all UPPERCASE. Value: $\{value}`,
  val => val === val?.toUpperCase()
);

const stringShouldBeOneOfPreviousUniqueOptions = lazy((_, options) => {
  return stringShouldBeUppercase.notRequired().oneOf(
    [
      "",
      ...(takeWhile(options.context?.optionsNames, name => {
        return name !== options.parent["Unique Option Name"];
      }) as string[])
    ],
    params => {
      // Example output: [3].Look Back @ BUZ Column Name must be one of the following values: (empty string), FRAMECOLOURTYPE, FRAMECOLOUR
      return `${params.path} must be one of the following values: ${[
        "(empty string)",
        // @ts-expect-error quick
        ...compact(params.resolved ?? []).filter((val: string) => val === val.toUpperCase())
      ].join(", ")}`;
    }
  );
});

export const processProductConfigXlsxImported = (data: CellObject[][]) => {
  const values = data?.map(row => row.map(({ v }) => v));
  const unzipped = unzip(values);
  const headers = ["Unique Option Name", ...slice(head(unzipped), 1, 16), "Answer Options"];
  assert.deepStrictEqual(headers, assertExpectedHeaders, "Headers array is not as expected.");

  const columns = slice(unzipped, 1);

  const configurations = uniqBy(
    columns.map(column => {
      const answerOptions = dropRightWhile(slice(column, 16), isUndefined);
      const conf = zipObject(headers, [...slice(column, 0, 16), answerOptions]);

      return {
        ...conf,
        "Answer Options": answerOptions
      };
    }),
    "Unique Option Name"
  );

  assert.ok(
    columns.length === configurations.length,
    "Unique Option Name array is not unique, there are duplicates"
  );

  const confSchema = array().of(
    object({
      "Unique Option Name": stringShouldBeUppercase.required(),
      "Inventory Code for Pricing": stringShouldBeUppercase.notRequired(),
      "Value applies to Price from Related Option Name": stringShouldBeOneOfPreviousUniqueOptions,
      "Related Column Data Field": stringShouldBeUppercase
        // .when here makes sure 'Value applies to Price from Related Option Name' is not empty when 'Related Column Data Field' is set
        // TODO: Not sure with the case where 'Value applies to Price from Related Option Name' is empty
        .when(["Value applies to Price from Related Option Name"], {
          is: (relatedOptionName: string) => !isEmpty(relatedOptionName),
          then: schema =>
            schema.required(
              `$\{path} is a required field because value is not empty for: "Value applies to Price from Related Option Name" `
            ),
          otherwise: schema => schema.notRequired()
        })
        .oneOf(["", ...dataFieldReservedKeywords]), // https://buzsoftware.atlassian.net/wiki/spaces/HELP/pages/679215132/Explaining+the+Group+options+table
      "Related Column is Add (Y) to else Set to (N)": booleanYesOrNo, // TODO: not tested, don't what is this for
      "Look Back @ BUZ Column Name": stringShouldBeOneOfPreviousUniqueOptions,
      "Question Heading": string().required(),
      "Option Type": string()
        .required()
        .when(["Related Column Data Field", "Unique Option Name"], {
          is: (relatedColumnDataField: string, uniqueOptionName: string) =>
            (relatedColumnDataField || uniqueOptionName) === "ITEMDEPTH",
          then: schema => schema.oneOf(["L"], "${path} L is required for ITEMDEPTH input"),
          otherwise: schema => schema.oneOf(assertExpectedOptionTypes)
        }),
      "Is Required": booleanYesOrNo,
      "Can Mass Update": booleanYesOrNo, // TODO - not tested, currently not used in Buz as this is for future buz feature.
      "Clear value when Copied to new item": booleanYesOrNo, // TODO: not tested, don't what is this for
      "Is Not for Online Wholesale Ordering": booleanYesOrNo, // Don't need this. Buz notes: If you have customer who login to BUZ to place orders you can also hide these questions from them by inputting a “Y” in the “Is Not for Online Wholesale Ordering” Row
      "Is Not for Shopping Cart Ordering": booleanYesOrNo, // TODO: not tested, don't what is this for
      "Use First valid Answer as default": booleanYesOrNo, // TODO - not tested
      "Help Message": string().notRequired(),
      "Picture URL": string().url().notRequired(),
      "Answer Options": mixed()
        .when(["Option Type", "Look Back @ BUZ Column Name"], {
          // TODO Price grid
          is: (optionType: string, lookBack: string) => optionType === "L" && isEmpty(lookBack),
          then: () =>
            array()
              .of(mixed()) // TODO: Change to proper object().shape()...
              .transform(values =>
                chain(values)
                  .map(val => zipObject(["label", "code"], val.split("|")))
                  .compact()
                  .value()
              )
        })
        .when(["Option Type", "Look Back @ BUZ Column Name"], {
          is: (optionType: string, lookBack: string) => optionType === "L" && !isEmpty(lookBack),
          then: () =>
            array()
              .of(mixed()) // TODO: Change to proper object().shape()...
              .transform(values => {
                return chain(values)
                  .map(val => zipObject(["lookbackValue", "label", "code"], val.split("|")))
                  .value();
              })
        })
        .when(["Option Type", "Related Column Data Field", "Unique Option Name"], {
          is: (optionType: string, relatedColumnDataField: string, uniqueOptionName: string) =>
            optionType === "L" && (relatedColumnDataField || uniqueOptionName) === "ITEMDEPTH",
          then: () =>
            array()
              .of(
                object()
                  .shape({
                    depthValue: number().required(),
                    label: string().required()
                  })
                  .required("${path} value is invalid")
              )
              .transform(values => {
                return chain(values)
                  .map(val => {
                    if (!isNaN(Number(val))) {
                      return zipObject(["depthValue", "label"], [Number(val), Number(val)]);
                    }

                    const regex = /^(?<value>(?:[1-9][0-9]*)|(?:[0]+))\.\s(?<label>[^\d].*)/;
                    const regExpMatches = regex.exec(val);

                    if (!regExpMatches || !regExpMatches.groups) {
                      return null;
                    }

                    return zipObject(
                      ["depthValue", "label"],
                      [Number(regExpMatches.groups.value), regExpMatches.groups.label]
                    );
                  })
                  .value();
              })
        })
        .when("Option Type", {
          is: "R",
          then: () =>
            object()
              .shape({
                warning: object({
                  min: number().integer().required(),
                  max: number().integer().required().moreThan(ref("min")),
                  msg: string().required()
                }).required(),
                error: object().when({
                  is: (value: any) => !isEmpty(value),
                  then: schema =>
                    schema
                      .shape({
                        min: number().integer().required(),
                        max: number().integer().required().moreThan(ref("min")),
                        msg: string().required()
                      })
                      .required()
                })
              })
              .transform(value =>
                omitBy(
                  {
                    warning: zipObject(["min", "max", "msg"], value.slice(0, 3)),
                    error: omitBy(zipObject(["min", "max", "msg"], value.slice(3, 6)), isNil)
                  },
                  isEmpty
                )
              )
        })
        .when(["Option Type", "Look Back @ BUZ Column Name"], {
          is: (optionType: string, lookBack: string) =>
            ["&", "$"].includes(optionType) && !isEmpty(lookBack),
          then: () =>
            object().transform(values => {
              return {
                enable_input_if_lookback_value_includes: chain(values)
                  .map(val => val.split("|"))
                  .flatten()
                  .compact()
                  .value()
              };
            })
        })
    })
  );

  const confValidated = confSchema.validateSync(configurations, {
    abortEarly: false,
    context: {
      optionsNames: configurations?.map((conf: any) => conf["Unique Option Name"])
    }
  });
  // confValidated?.map(v => console.dir(v, { depth: null }));

  return confValidated;
};

// https://buzsoftware.atlassian.net/wiki/spaces/HELP/pages/679182427/Group+Options+-+Question+type#Setup
const assertExpectedOptionTypes = ["L", "$", "R", "&", "#", "D", "N", "H", "F"];

export const dataFieldReservedKeywords = [
  "QTY",
  "ITEMWIDTH",
  "ITEMHEIGHT",
  "ITEMDEPTH",
  "RRP",
  "AMT"
];

const assertExpectedHeaders = [
  "Unique Option Name",
  "Inventory Code for Pricing",
  "Value applies to Price from Related Option Name",
  "Related Column Data Field",
  "Related Column is Add (Y) to else Set to (N)",
  "Look Back @ BUZ Column Name",
  "Question Heading",
  "Option Type",
  "Is Required",
  "Can Mass Update",
  "Clear value when Copied to new item",
  "Is Not for Online Wholesale Ordering",
  "Is Not for Shopping Cart Ordering",
  "Use First valid Answer as default",
  "Help Message",
  "Picture URL",
  "Answer Options"
];
