import React, { Suspense, useMemo } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from "@mui/material";
import { confirmable, ConfirmDialogProps } from "react-confirm";
import { Field, FieldProps, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { formatISO, parseISO } from "date-fns";
import { DatePicker } from "@mui/x-date-pickers";
import useHealthChecker from "../../hooks/useHealthChecker.ts";
import { createConfirmation } from "../../components/ReactConfirmMountPoint.ts";
import Loader from "../../components/Loader.tsx";

interface Values {
  uuid: string;
  call_back_at: string;
  call_back_notes: string;
}

interface Props {
  uuid: string;
  call_back_at: string | null;
  call_back_notes: string | null;
}

export const CallBackDateDialog = ({
  show,
  proceed,
  uuid,
  call_back_at,
  call_back_notes
}: ConfirmDialogProps<Props, false | Values>) => {
  const { isSuspendMutations } = useHealthChecker();

  const handleClose = () => proceed(false);

  const validationSchema = Yup.object<Values>().shape({
    uuid: Yup.string().uuid().required(),
    call_back_at: Yup.string().datetime({ allowOffset: true }).required().label("Call Back Date"),
    call_back_notes: Yup.string().max(255).required().label("Notes")
  });

  const formik = useFormik<Values>({
    initialValues: useMemo(
      () => ({
        uuid,
        call_back_at: call_back_at || "",
        call_back_notes: call_back_notes || ""
      }),
      [uuid, call_back_at, call_back_notes]
    ),
    enableReinitialize: true,
    validationSchema,
    onSubmit: values => {
      const validated = validationSchema.cast(values, {
        stripUnknown: true
      });

      proceed(validated);
    }
  });

  const { handleSubmit, values } = formik;

  return (
    <Dialog open={show} onClose={handleClose} fullWidth maxWidth="sm">
      <FormikProvider value={formik}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Set Call Back Date</DialogTitle>
          <DialogContent>
            <Field name="call_back_at" value={values.call_back_at}>
              {({ field, form, meta }: FieldProps) => {
                return (
                  <DatePicker
                    label="Call Back Date"
                    disablePast
                    value={parseISO(meta.value)}
                    onChange={(newValue: any) => {
                      if (newValue) {
                        form.setFieldValue(field.name, formatISO(newValue));
                      }
                    }}
                    slots={{
                      textField: (params: any) => (
                        <TextField
                          {...params}
                          helperText={meta.touched && meta.error}
                          error={Boolean(meta.touched && meta.error)}
                          fullWidth
                          margin="normal"
                        />
                      )
                    }}
                  />
                );
              }}
            </Field>

            <Field name="call_back_notes" value={values.call_back_notes}>
              {({ field, meta }: FieldProps) => {
                return (
                  <TextField
                    name={field.name}
                    label="Notes"
                    value={field.value}
                    fullWidth
                    helperText={meta.touched && meta.error}
                    error={Boolean(meta.touched && meta.error)}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    type="text"
                    variant="outlined"
                    margin="normal"
                  />
                );
              }}
            </Field>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit" color="primary" disabled={isSuspendMutations}>
              Submit
            </Button>
          </DialogActions>
        </form>
      </FormikProvider>
    </Dialog>
  );
};

export const setCallBackDateDialog = createConfirmation(
  confirmable((props: ConfirmDialogProps<Props, false | Values>) => (
    <Suspense fallback={<Loader />}>
      <CallBackDateDialog {...props} />
    </Suspense>
  ))
);
