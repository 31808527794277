import React from "react";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import confirm from "../../components/Confirm";
import { generatePath, Link as RouterLink } from "react-router-dom";
import { Chip as MuiChip, IconButton, Link, Tooltip } from "@mui/material";
import {
  useMutationBulkDeleteUser,
  useMutationDeleteUser,
  useMutationRestoreDeletedUser,
  useQueryUsers
} from "../../api/User";
import useAuth from "../../hooks/useAuth";
import { USER_ROLES } from "../../constants";
import { withDataGridPageProvider } from "../../contexts/DataGridPageContext";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import DataGridPage from "../../components/data-grid/DataGridPage";
import useHealthChecker from "../../hooks/useHealthChecker";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import useSetPageTitle from "../../hooks/useSetPageTitle.ts";

const Chip = styled(MuiChip)(spacing);

const UserList = () => {
  useSetPageTitle("Users");
  const { mutateAsync: deleteItem } = useMutationDeleteUser();
  const { mutateAsync: restoreDeletedUser } = useMutationRestoreDeletedUser();
  const { checkRolesAccess } = useAuth();
  const { isSuspendMutations } = useHealthChecker();

  const columns: GridColDef[] = [
    {
      field: "email",
      headerName: "Email",
      resizable: true,
      width: 200,
      renderCell: (params: GridRenderCellParams) => {
        const { value, row } = params;
        const { uuid, roles, verified_at } = row;

        if (!verified_at || !roles?.includes("SALES_REP")) {
          return value;
        }

        return (
          <>
            <Link
              component={RouterLink}
              underline="none"
              to={generatePath("/users/:uuid/sales-rep/details", { uuid })}
            >
              {value}
            </Link>
          </>
        );
      }
    },
    {
      field: "full_name",
      headerName: "Full Name",
      flex: 1,
      valueGetter: (value, row) => [row.first_name, row.last_name].join(" ")
    },
    {
      field: "roles",
      headerName: "Roles",
      flex: 1,
      renderCell: ({ row }) =>
        row?.roles?.map((role: string) => <Chip label={role} key={role} m={1} />)
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: ({ row }) => [
        !row?.password && <Chip label={"Invitation Sent"} key={1} m={1} />,
        !!row?.password && <Chip label={"Email Verified"} key={2} m={1} />
      ]
    },
    {
      field: "actions",
      headerName: "Actions",
      filterable: false,
      sortable: false,
      headerAlign: "right",
      align: "right",
      renderCell: ({ row }) => {
        const { uuid, deletedAt } = row;

        return [
          <Tooltip title="Edit" key={`edit-${uuid}`}>
            <IconButton
              size="small"
              component={RouterLink}
              to={generatePath("/users/:uuid/edit", { uuid })}
              disabled={Boolean(deletedAt || !checkRolesAccess([USER_ROLES.ADMIN]))}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>,

          deletedAt ? (
            <Tooltip title="Restore" key={`restore-${uuid}`}>
              <IconButton
                size="small"
                onClick={async () => {
                  if (
                    await confirm({
                      confirmation: "You are about to restore. Are you sure?",
                      options: {
                        title: "Confirm"
                      }
                    })
                  ) {
                    await restoreDeletedUser(uuid);
                  }
                }}
                disabled={!checkRolesAccess([USER_ROLES.ADMIN])}
              >
                <RestoreFromTrashIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Delete" key={`delete-${uuid}`}>
              <IconButton
                size="small"
                onClick={async () => {
                  if (
                    await confirm({
                      confirmation: "You are about to delete. Are you sure?",
                      options: {
                        title: "Confirm"
                      }
                    })
                  ) {
                    await deleteItem(uuid);
                  }
                }}
                disabled={!checkRolesAccess([USER_ROLES.ADMIN]) || isSuspendMutations}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )
        ];
      },
      flex: 1
    }
  ];

  return (
    <DataGridPage
      dataGridProps={{
        columns
      }}
    />
  );
};

export default withDataGridPageProvider(UserList, {
  useQueryList: useQueryUsers,
  useMutationBulkDelete: useMutationBulkDeleteUser,
  addButtonNavigateTo: "/users/invite"
});
