import React from "react";

export default function useMenuIcon() {
  const [anchorElMenu, setAnchorElMenu] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorElMenu);
  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElMenu(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  return { openMenu, anchorElMenu, handleClickMenu, handleCloseMenu };
}
