import { chain, get } from "lodash";
import { useMemo } from "react";

export const useOrderLineItemProductDesc = (order_line_item?: any) =>
  useMemo(() => {
    const { config_values, product } = order_line_item;
    const location = get(config_values, "LOCATION.label", get(config_values, "LOCATION"));
    const isLocationOther = location === "Other";
    const locationOther = get(
      config_values,
      "LOCATION_OTHER.label",
      get(config_values, "LOCATION_OTHER")
    );
    const opening = get(config_values, "OPENING.label", get(config_values, "OPENING"));

    return chain([
      product?.description,
      isLocationOther ? undefined : location,
      locationOther,
      opening
    ])
      .compact()
      .uniq()
      .join(" | ")
      .value();
  }, [order_line_item]);
