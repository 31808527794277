import React from "react";
import styled from "@emotion/styled";
import { v4 } from "uuid";
import { Divider as MuiDivider, Paper as MuiPaper } from "@mui/material";
import { spacing } from "@mui/system";
import ProductSoldByForm from "./ProductSoldByForm";
import { useMutationAddProductSoldBy } from "../../api/ProductSoldBy";
import PageTitleWithLoading from "../../components/PageTitleWithLoading";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const ProductSoldByAdd = () => {
  const mutation = useMutationAddProductSoldBy(v4());

  return (
    <Paper p={4}>
      <PageTitleWithLoading title="Add Product Sold By" />

      <Divider my={6} />

      <ProductSoldByForm mutation={mutation} />
    </Paper>
  );
};

export default ProductSoldByAdd;
