import React, { useEffect, useRef } from "react";
import { Card } from "@mui/material";
import { DataGridServerIndexBasedPaginated } from "../../components/data-grid-v2/DataGridServerIndexBasedPaginated.tsx";
import { DataGridToolbar } from "./DataGridToolbar.tsx";
import { LeadListColumnsProps, useLeadListColumns } from "./useLeadListColumns.tsx";
import { useQueryParamAvailability } from "../order/OrderList.tsx";
import { GridApiPro, GridFilterItem } from "@mui/x-data-grid-pro";

const LeadListWithQuery: React.VFC<
  { upsertFilterItems?: GridFilterItem[] } & Omit<LeadListColumnsProps, "availability">
> = ({ upsertFilterItems, isInSalesRepDashboardPage, isInCustomerDetailsPage }) => {
  const [availability] = useQueryParamAvailability();
  const columns = useLeadListColumns({
    availability,
    isInCustomerDetailsPage,
    isInSalesRepDashboardPage
  });

  const dataGridRef = useRef<Pick<GridApiPro, "upsertFilterItems" | "deleteFilterItem">>();

  useEffect(() => {
    if (dataGridRef.current && upsertFilterItems) {
      dataGridRef.current.upsertFilterItems(upsertFilterItems);
    }
  }, [upsertFilterItems]);

  return (
    <Card sx={{ height: "87vh" }}>
      <DataGridServerIndexBasedPaginated
        ref={dataGridRef}
        apiEndpoint="lead"
        autosizeOnMount={false}
        columns={columns}
        checkboxSelection={false}
        initialState={{
          columns: {
            columnVisibilityModel: {
              "customer.uuid": false,
              "sales_rep_user.uuid": false
            }
          }
        }}
        slots={{
          toolbar: DataGridToolbar
        }}
      />
    </Card>
  );
};

export default LeadListWithQuery;
