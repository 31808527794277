import LogEmailList from "./LogEmailList";

const routes = [
  {
    path: "/logs/emails",
    element: <LogEmailList />
  }
];

export default routes;
