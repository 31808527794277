import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import { RenderConfigProps } from "../OrderLineItemForm";

const Heading: React.VFC<RenderConfigProps> = ({ conf }) => {
  return (
    <Box my={2} flexGrow={1}>
      <Typography variant="h5" gutterBottom>
        {conf["Question Heading"]}
      </Typography>
      <Divider />
    </Box>
  );
};

export default Heading;
