import React from "react";

import styled from "@emotion/styled";
import { Divider as MuiDivider } from "@mui/material";
import { spacing } from "@mui/system";
import useSetPageTitle from "../../../hooks/useSetPageTitle.ts";
import { SalesRepDashboardPartial } from "./SalesRepDashboardPartial.tsx";

const Divider = styled(MuiDivider)(spacing);

function SalesRepDashboard({ sales_rep_user_uuid }: { sales_rep_user_uuid?: string }) {
  useSetPageTitle("Dashboard");
  return (
    <>
      <Divider my={6} />

      {sales_rep_user_uuid && <SalesRepDashboardPartial uuid={sales_rep_user_uuid} />}
    </>
  );
}

export default SalesRepDashboard;
