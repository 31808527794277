import React from "react";
import { Chip, List } from "@mui/material";
import { QUERY_KEY, useQueryOneOrder } from "../../../api/Order";
import { useParams } from "react-router-dom";
import useIsLoading from "../../../hooks/useIsLoading";
import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";
import { useOrderMonetaryValues } from "../components/useOrderMonetaryValues";
import { toDecimalFromFloat } from "aldrin-erp-main-shared-functions";
import { LoadableListItem } from "../components/LoadableListItem";
import { Feature } from "use-feature";

const CartSummaryTable = () => {
  const { uuid } = useParams<string>();
  const { data: order } = useQueryOneOrder(uuid);
  const isLoading = useIsLoading([QUERY_KEY]);

  const monetaryValues = useOrderMonetaryValues(order);

  return (
    <List dense>
      <LoadableListItem
        label="Subtotal:"
        value={monetaryValues.subtotal}
        divider
        isLoading={isLoading}
      />
      {!["warranty", "rectification"].includes(order?.order_type) && order?.discount > 0 && (
        <LoadableListItem
          label="Saving:"
          value={
            <>
              {monetaryValues.saving}
              {order?.discount > 0 && (
                <Feature name="FEATURE_DEBUG_ORDER">
                  <Chip label={`Less ${order?.discount}%`} size="small" sx={{ ml: 2 }} />
                </Feature>
              )}
            </>
          }
          divider
          isLoading={isLoading}
        />
      )}

      <LoadableListItem
        label="Total inc. GST:"
        value={monetaryValues.soldPrice}
        divider
        labelStyle={{ fontSize: 16, fontWeight: "bold" }}
        isLoading={isLoading}
      />
      <LoadableListItem
        label="GST:"
        value={monetaryValues.gstAmount}
        divider={Boolean(order?.quote_accepted_at || order?.credit)}
        isLoading={isLoading}
      />
      {order?.credit && (
        <LoadableListItem
          label="Less Credit:"
          value={toDecimalFromFloat((order?.credit ?? 0) * -1)}
          divider
          isLoading={isLoading}
        />
      )}
      {order?.quote_accepted_at && (
        <LoadableListItem
          label="Outstanding Balance:"
          value={monetaryValues.outstanding_amount}
          isLoading={isLoading}
          divider={false}
        />
      )}
    </List>
  );
};

export default CartSummaryTable;
