import { useQuery } from "@tanstack/react-query";
import { parseWorkbook } from "../utils/parseWorkbook";

export const useXlsxRead = (location_url?: string) => {
  return useQuery({
    enabled: !!location_url,
    queryKey: ["xlsx-file", location_url],
    queryFn: async ({ signal }) => {
      if (!location_url) {
        return null;
      }

      const fileResponse = await fetch(location_url, { signal });

      const { workbook } = await parseWorkbook(await fileResponse.blob(), {
        dense: true,
        sheets: 1
      });

      return workbook;
    }
  });
};
