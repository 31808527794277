import SalesRepDashboard from "./sales_rep/SalesRepDashboard.tsx";

const routes = [
  {
    path: "/dashboard/sales-rep",
    element: <SalesRepDashboard />
  }
];

export default routes;
