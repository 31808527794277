import LeadSourceList from "./LeadSourceList";
import LeadSourceAdd from "./LeadSourceAdd";
import LeadSourceEdit from "./LeadSourceEdit";

const routes = [
  {
    path: "/lead-source",
    element: <LeadSourceList />
  },
  {
    path: "/lead-source/add",
    element: <LeadSourceAdd />
  },
  {
    path: "/lead-source/:uuid/edit",
    element: <LeadSourceEdit />
  }
];

export default routes;
