import { CellObject } from "xlsx";
import { chain, head, isNumber, last, mapValues, trim, zipObject } from "lodash";
import { array, boolean, date, number, object, string } from "yup";
import { parse } from "date-fns";

export type PriceGridConfType = {
  title: string;
  dateFrom: Date;
  active: boolean;
  width: number[];
  height_depth: {
    [depth: number]: number[];
  };
  matrix: {
    [x: number]: number[][];
  };
};

export const processInventoryItemPriceGridXlsxImported = (
  data: CellObject[][]
): PriceGridConfType => {
  const firstGrid = chain(data)
    .takeWhile(row => !!row)
    .map(row => row?.map(({ v }) => v));

  const headers = resolveHeaders(firstGrid.value());
  const width = firstGrid
    .slice(1)
    .head()
    .filter(isNumber)
    .thru(value => [0, ...value])
    .value();

  const height_depth = firstGrid
    .slice(2)
    .groupBy(row => Number(last(row)))
    .mapValues(rows => rows.map(row => head(row)))
    .mapValues(values => [0, ...values])
    .value();

  const matrix = firstGrid
    .slice(2)
    .map(row => row.slice(1))
    .groupBy(row => Number(last(row)))
    .mapValues(values => values.map(row => row.slice(0, -1)))
    .value();

  const arrayOfNumbers = array(number().min(0));

  const gridSchema = object({
    width: arrayOfNumbers,
    height_depth: object(mapValues(matrix, () => arrayOfNumbers)),
    matrix: object(mapValues(matrix, () => array(arrayOfNumbers)))
  });

  const gridValidated = gridSchema.validateSync(
    {
      width,
      height_depth,
      matrix
    },
    {
      abortEarly: false
    }
  );

  return {
    ...headers,
    ...gridValidated
  } as PriceGridConfType;
};

const resolveHeaders = (rows: (string | number | boolean | Date | undefined)[][]) => {
  const headValue = head(head(rows)) as string;
  const headerRaw = zipObject(
    ["title", "dateFrom", "active"],
    headValue.split(",").map(v => trim(v, " -"))
  );

  const headerSchema = object({
    title: string().required(),
    dateFrom: date()
      .required()
      .transform((_, originalValue) => {
        return parse(originalValue.replace("Date From ", ""), "dd/MM/yyyy", new Date());
      }),
    active: boolean()
      .required()
      .transform(v => v.replace("Active: ", "") === "True")
  });

  // TODO: Error handling
  const headersValidated = headerSchema.validateSync(headerRaw, {
    abortEarly: false
  });

  return headersValidated;
};
