import { useMutationAbstract, useQueryOneModel } from "../hooks/useMutationFormAbstract.ts";
import axios from "../utils/axios.ts";

export const QUERY_KEY = "debtor-statement-export";

export const useQueryOneDebtorStatementExport = (uuid?: string) =>
  useQueryOneModel<any>(QUERY_KEY, uuid, {
    retry: 15,
    retryDelay: 1000
  });

export const useDebtorStatementExport = () => {
  return useMutationAbstract({
    mutationKey: [QUERY_KEY],
    mutationFn: input => axios.post(`/api/${QUERY_KEY}`, input).then(({ data }) => data)
  });
};
