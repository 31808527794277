import MarketingList from "./MarketingList";
import MarketingAdd from "./MarketingAdd";
import MarketingEdit from "./MarketingEdit";

const routes = [
  {
    path: "/marketing",
    element: <MarketingList />
  },
  {
    path: "/marketing/add",
    element: <MarketingAdd />
  },
  {
    path: "/marketing/:uuid/edit",
    element: <MarketingEdit />
  }
];

export default routes;
