import React from "react";
import styled from "@emotion/styled";
import { v4 } from "uuid";
import { Divider as MuiDivider, Paper as MuiPaper } from "@mui/material";
import { spacing } from "@mui/system";
import PaymentMethodForm from "./PaymentMethodForm";
import { useMutationAddPaymentMethod } from "../../api/PaymentMethod";
import PageTitleWithLoading from "../../components/PageTitleWithLoading";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const PaymentMethodAdd = () => {
  const mutation = useMutationAddPaymentMethod(v4());

  return (
    <Paper p={4}>
      <PageTitleWithLoading title="Add Payment Method" />

      <Divider my={6} />

      <PaymentMethodForm mutation={mutation} />
    </Paper>
  );
};

export default PaymentMethodAdd;
