import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import { Paper, Typography } from "@mui/material";

import Logo from "../../vendor/logo.svg?react";
import SetPasswordComponent from "../../components/auth/SetPassword";

const Brand = styled(Logo)`
  fill: ${props => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
  margin-top: 32px;
`;

const Wrapper = styled(Paper)`
  padding: ${props => props.theme.spacing(6)};

  ${props => props.theme.breakpoints.up("md")} {
    padding: ${props => props.theme.spacing(10)};
  }
`;

function SetPassword() {
  return (
    <React.Fragment>
      <Brand />
      <Wrapper>
        <Helmet title="Set Password" />

        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Set Password
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          You are required to set your password to activate your account.
        </Typography>

        <SetPasswordComponent />
      </Wrapper>
    </React.Fragment>
  );
}

export default SetPassword;
