import {
  useMutationAddModel,
  useMutationBulkDeleteModelV2,
  useMutationDeleteModel,
  useMutationEditModel,
  useQueryModels,
  useQueryOneModel
} from "../hooks/useMutationFormAbstract";

export const QUERY_KEY = "key-value";

export const useQueryKeyValues = () => useQueryModels(QUERY_KEY);

export const useQueryOneKeyValue = (uuid?: string) => useQueryOneModel<any>(QUERY_KEY, uuid);

export const useMutationAddKeyValue = (uuid: string) => useMutationAddModel(QUERY_KEY, uuid);

export const useMutationEditKeyValue = (uuid: string) => useMutationEditModel(QUERY_KEY, uuid);

export const useMutationDeleteKeyValue = () => useMutationDeleteModel(QUERY_KEY);

export const useMutationBulkDeleteKeyValue = () => useMutationBulkDeleteModelV2(QUERY_KEY);
