import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { Divider as MuiDivider, Paper as MuiPaper } from "@mui/material";
import { spacing } from "@mui/system";
import { useMutationEditOrder, useQueryOneOrder } from "../../api/Order";
import PageTitleWithLoading from "../../components/PageTitleWithLoading";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import OrderForm from "./form/OrderForm";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const OrderEdit = () => {
  const { uuid } = useParams<string>();
  const { data: order } = useQueryOneOrder(uuid);
  const mutation = useMutationEditOrder(uuid ?? "");
  const navigate = useNavigate();

  useEffect(() => {
    if (order?.is_quote_finalized && uuid) {
      navigate(
        generatePath("/order/:uuid/finalized-quote", {
          uuid
        })
      );
    }
  }, [order?.is_quote_finalized, uuid]);

  return (
    <Paper p={4}>
      <PageTitleWithLoading title={`Order ${order?.reference_number}`} />

      <Divider my={6} />

      <OrderForm mutation={mutation} uuid={uuid} />
    </Paper>
  );
};

export default OrderEdit;
