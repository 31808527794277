import { Transformer } from "dinero.js";

export const enAULocaleFormatTransformer: (
  options?: Intl.NumberFormatOptions
) => Transformer<number, string, string> =
  (options?: Intl.NumberFormatOptions) =>
  ({ value, currency }) =>
    Number(value).toLocaleString("en-AU", {
      style: "currency",
      currency: currency.code,
      ...options
    });
