import React, { useEffect, useRef } from "react";
import { Card } from "@mui/material";
import { DataGridServerIndexBasedPaginated } from "../../components/data-grid-v2/DataGridServerIndexBasedPaginated.tsx";
import { useOrderListColumns } from "../order/components/useOrderListColumns.tsx";
import { DataGridToolbar } from "../order/DataGridToolbar.tsx";
import { useQueryParamAvailability } from "../order/OrderList.tsx";
import { GridApiPro, GridFilterItem } from "@mui/x-data-grid-pro";

const OrderListWithQuery: React.VFC<{
  upsertFilterItems?: GridFilterItem[];
}> = ({ upsertFilterItems }) => {
  const [availability] = useQueryParamAvailability();

  const columns = useOrderListColumns({ availability, isInCustomerDetailsPage: true });

  const dataGridRef = useRef<Pick<GridApiPro, "upsertFilterItems" | "deleteFilterItem">>();

  useEffect(() => {
    if (dataGridRef.current && upsertFilterItems) {
      dataGridRef.current.upsertFilterItems(upsertFilterItems);
    }
  }, [upsertFilterItems]);

  return (
    <Card sx={{ height: "87vh" }}>
      <DataGridServerIndexBasedPaginated
        ref={dataGridRef}
        apiEndpoint="order"
        autosizeOnMount={false}
        initialState={{
          columns: {
            columnVisibilityModel: {
              is_quote_finalized_at: false,
              "customer.uuid": false,
              "customer.customer_group.id": false,
              created_at: true,
              is_invoiced_at: false,
              quote_accepted_at: false,
              active: false,
              deleted_at: false,
              "sales_rep.uuid": false
            }
          },
          sorting: {
            sortModel: [
              {
                field: "reference_number",
                sort: "desc"
              }
            ]
          }
        }}
        columns={columns}
        checkboxSelection={false}
        slots={{
          toolbar: DataGridToolbar
        }}
        slotProps={{
          toolbar: {
            additionalQueryParams: { availability }
          }
        }}
        additionalQueryParams={{ availability }}
      />
    </Card>
  );
};

export default OrderListWithQuery;
