import InventoryItemList from "./InventoryItemList";
import InventoryItemAdd from "./InventoryItemAdd";
import InventoryItemEdit from "./InventoryItemEdit";
import InventoryItemDetails from "./InventoryItemDetails";

const routes = [
  {
    path: "/inventory-item",
    element: <InventoryItemList />
  },
  {
    path: "/inventory-item/add",
    element: <InventoryItemAdd />
  },
  {
    path: "/inventory-item/:uuid/edit",
    element: <InventoryItemEdit />
  },
  {
    path: "/inventory-item/:uuid/details",
    element: <InventoryItemDetails />
  }
];

export default routes;
