import { useQuery } from "@tanstack/react-query";
import assert from "node:assert";
import { processInventoryItemPriceGridXlsxImported } from "aldrin-erp-main-shared-functions";
import { parseWorkbook } from "../utils/parseWorkbook";

export const usePriceGridProcessor = (file: File | null, inventoryItem: any | undefined) => {
  return useQuery({
    enabled: !!file && !!inventoryItem,
    useErrorBoundary: false,
    queryKey: [
      "inventory-item-processor",
      { fileName: file?.name, inventory_item_id: inventoryItem?.id }
    ],
    queryFn: async () => {
      const { workbook, hash } = await parseWorkbook(file as File, { dense: true, sheets: 1 });

      assert.ok(workbook.SheetNames?.length === 2, "Workbook should have 2 sheets.");
      assert.ok(workbook.SheetNames[0] === "Help", 'First sheet should be named "Help"');
      assert.ok(
        workbook.SheetNames[1] === inventoryItem.code,
        `Sheet name should be ${inventoryItem.code}`
      );

      const worksheet = workbook.Sheets[inventoryItem.code];

      const data = worksheet["!data"];
      assert.ok(data !== undefined, `Sheet ${inventoryItem.code} is empty`);

      const config = processInventoryItemPriceGridXlsxImported(data);

      const { utils } = await import("xlsx");
      const html = utils.sheet_to_html(worksheet);

      return {
        config,
        html,
        hash
      };
    }
  });
};
