import React from "react";
import {
  Button,
  Card,
  CardHeader,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  Stack
} from "@mui/material";
import { confirmable, ConfirmDialogProps } from "react-confirm";
import { useQueryOrderLineItemsByOrderId } from "../../api/OrderLineItem";
import { createConfirmation } from "../../components/ReactConfirmMountPoint";
import { AccordionOrderLineItem } from "./components/AccordionOrderLineItem";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

function not(a: readonly string[], b: readonly string[]) {
  return a.filter(value => !b.includes(value));
}

function intersection(a: readonly string[], b: readonly string[]) {
  return a.filter(value => b.includes(value));
}

function union(a: readonly string[], b: readonly string[]) {
  return [...a, ...not(b, a)];
}

export const OrderSplitDialog = confirmable(
  ({
    show,
    proceed,
    order_id
  }: ConfirmDialogProps<
    { order_id: number },
    false | { left_order_line_item_uuids: string[]; right_order_line_item_uuids: string[] }
  >) => {
    const { data: orderLineItems } = useQueryOrderLineItemsByOrderId(order_id, {
      suspense: false
    });

    const handleClose = () => proceed(false);

    const [checked, setChecked] = React.useState<readonly string[]>([]);
    const [left, setLeft] = React.useState<string[]>(
      orderLineItems?.map((item: any) => item.uuid) ?? []
    );
    const [right, setRight] = React.useState<string[]>([]);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const handleToggle = (value: string) => () => {
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      setChecked(newChecked);
    };

    const numberOfChecked = (items: readonly string[]) => intersection(checked, items).length;

    const handleToggleAll = (items: readonly string[]) => () => {
      if (numberOfChecked(items) === items.length) {
        setChecked(not(checked, items));
      } else {
        setChecked(union(checked, items));
      }
    };

    const handleCheckedRight = () => {
      setRight(right.concat(leftChecked));
      setLeft(not(left, leftChecked));
      setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
      setLeft(left.concat(rightChecked));
      setRight(not(right, rightChecked));
      setChecked(not(checked, rightChecked));
    };

    const customList = (title: React.ReactNode, items: readonly string[]) => (
      <>
        <CardHeader
          sx={{ px: 2, py: 1 }}
          avatar={
            <Checkbox
              onClick={handleToggleAll(items)}
              checked={numberOfChecked(items) === items.length && items.length !== 0}
              indeterminate={
                numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
              }
              disabled={items.length === 0}
              inputProps={{
                "aria-label": "all items selected"
              }}
            />
          }
          title={title}
          subheader={`${numberOfChecked(items)}/${items.length} selected`}
        />
        <Divider />
        <List dense component="div" role="list">
          {orderLineItems
            ?.filter((order_line_item: any) => items.includes(order_line_item.uuid))
            ?.map((order_line_item: any) => {
              return (
                <AccordionOrderLineItem
                  key={order_line_item.uuid}
                  order_line_item={order_line_item}
                  multi_select
                  checked={checked.includes(order_line_item.uuid)}
                  onChange={handleToggle(order_line_item.uuid)}
                />
              );
            })}
        </List>
      </>
    );

    return (
      <Dialog
        open={show}
        onClose={handleClose}
        fullWidth
        maxWidth="xl"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Split Order</DialogTitle>
        <DialogContent>
          <Card>
            <Stack
              gap={4}
              direction="column"
              justifyContent="space-between"
              alignItems="center"
              flexWrap="wrap"
            >
              <div>{customList("Split 1", left)}</div>
              <div>
                <Grid container direction="row" sx={{ alignItems: "center" }}>
                  <IconButton
                    sx={{ my: 0.5 }}
                    size="small"
                    onClick={handleCheckedRight}
                    disabled={leftChecked.length === 0}
                    aria-label="move selected right"
                  >
                    <ArrowDownwardIcon />
                  </IconButton>
                  <Button
                    sx={{ my: 0.5 }}
                    size="small"
                    onClick={handleCheckedLeft}
                    disabled={rightChecked.length === 0}
                    aria-label="move selected left"
                  >
                    <ArrowUpwardIcon />
                  </Button>
                </Grid>
              </div>
              <div>{customList("Split 2", right)}</div>
            </Stack>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() =>
              proceed({ left_order_line_item_uuids: left, right_order_line_item_uuids: right })
            }
            color="primary"
            autoFocus
            disabled={right?.length === 0 || left?.length === 0}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

export const orderSplitConfirmationDialog = createConfirmation(OrderSplitDialog);
