import React, { Suspense } from "react";
import { Card, CardContent, Stack } from "@mui/material";
import useSetPageTitle from "../../../hooks/useSetPageTitle.ts";

import { Theme, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { useQueryUserFormOptions } from "../../../api/User.ts";
import Loader from "../../../components/Loader.tsx";
import SalesRepStatisticsCard from "../../user/sales_rep/SalesRepStatisticsCard.tsx";
import { SalesRepDashboardTables } from "../../user/sales_rep/SalesRepDashboardTables.tsx";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

function getStyles(name: string, personName: readonly string[], theme: Theme) {
  return {
    fontWeight: personName.includes(name)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular
  };
}

function SalesRepDashboard() {
  useSetPageTitle("Dashboard");

  const { data: formOptions } = useQueryUserFormOptions();

  const theme = useTheme();
  const [selectedUuids, setSelectedUuids] = React.useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof selectedUuids>) => {
    const {
      target: { value }
    } = event;
    setSelectedUuids(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <>
      <Stack
        direction="row"
        spacing={6}
        justifyContent="flex-start"
        alignItems="stretch"
        useFlexGap
        flexWrap="wrap"
      >
        <Card sx={{ width: 300 }}>
          <CardContent>
            <FormControl sx={{ m: 1 }} fullWidth>
              <InputLabel id="demo-multiple-chip-label">Select one or more Sales Rep</InputLabel>
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={selectedUuids}
                onChange={handleChange}
                input={
                  <OutlinedInput id="select-multiple-chip" label="Select one or more Sales Rep" />
                }
                renderValue={selected => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map(value => (
                      <Chip
                        key={value}
                        label={
                          formOptions?.sales_rep_users?.find(user => user.uuid === value)?.full_name
                        }
                      />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {formOptions?.sales_rep_users?.map(user => (
                  <MenuItem
                    key={user.uuid}
                    value={user.uuid}
                    style={getStyles(user.uuid, selectedUuids, theme)}
                  >
                    {user.full_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </CardContent>
        </Card>
        <Card>
          <Suspense fallback={<Loader />}>
            <SalesRepStatisticsCard sales_rep_user_uuids={selectedUuids} />
          </Suspense>
        </Card>
      </Stack>

      <Suspense fallback={<Loader />}>
        <SalesRepDashboardTables sales_rep_user_uuids={selectedUuids} />
      </Suspense>
    </>
  );
}

export default SalesRepDashboard;
