import {
  useMutationAddModel,
  useMutationBulkDeleteModelV2,
  useMutationDeleteModel,
  useMutationEditModel,
  useQueryModels,
  useQueryOneModel
} from "../hooks/useMutationFormAbstract";

export const useQueryMarketings = () => useQueryModels("marketing");

export const useQueryOneMarketing = (uuid?: string) => useQueryOneModel<any>("marketing", uuid);

export const useMutationAddMarketing = (uuid: string) => useMutationAddModel("marketing", uuid);

export const useMutationEditMarketing = (uuid: string) => useMutationEditModel("marketing", uuid);

export const useMutationDeleteMarketing = () => useMutationDeleteModel("marketing");

export const useMutationBulkDeleteMarketing = () => useMutationBulkDeleteModelV2("marketing");
