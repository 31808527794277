import { Grid } from "@mui/material";

interface Props {
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  children: React.ReactNode;
}

export const IconText = ({ startIcon, endIcon, children }: Props) => (
  <Grid container spacing={1} mt={1}>
    {!!startIcon && <Grid item>{startIcon}</Grid>}

    <Grid item>{children}</Grid>

    {!!endIcon && <Grid item>{endIcon}</Grid>}
  </Grid>
);
