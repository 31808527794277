import { createContext } from "react";
import { useCounter } from "usehooks-ts";
import { Backdrop, CircularProgress } from "@mui/material";

const LoadingBackdropContext = createContext<ReturnType<typeof useCounter> | undefined>(undefined);

interface LoadingBackdropProviderProps {
  children: React.ReactNode;
}

function LoadingBackdropProvider({ children }: LoadingBackdropProviderProps) {
  const loadingState = useCounter(0);

  return (
    <LoadingBackdropContext.Provider value={loadingState}>
      {children}

      <Backdrop
        sx={theme => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={loadingState.count > 0}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </LoadingBackdropContext.Provider>
  );
}

export { LoadingBackdropProvider, LoadingBackdropContext };
