import React from "react";
import { Formik } from "formik";
import {
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Grid,
  TextField as MuiTextField
} from "@mui/material";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { spacing, SpacingProps } from "@mui/system";
import { MutationResultType } from "../../hooks/useMutationFormAbstract";
import { QUERY_KEY, useQueryOnePage } from "../../api/Page";
import { Refresh as RefreshIcon } from "@mui/icons-material";
import useIsLoading from "../../hooks/useIsLoading";
import useHealthChecker from "../../hooks/useHealthChecker";
import useFormikSubmitHandler from "../../hooks/useFormikSubmitHandler";
import { useNavigate } from "react-router-dom";
import { Feature } from "use-feature";
import asyncComponent from "../../components/Async.tsx";

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const RichTextEditor = asyncComponent(() => import("./RichTextEditor"));

interface ButtonProps extends SpacingProps {
  component?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

const validationSchema = Yup.object().shape({
  title: Yup.string().required().max(255),
  content: Yup.string().required(),
  slug: Yup.string().max(255)
});

interface Values {
  title?: string;
  content?: string;
  slug?: string;
}

const PageForm: React.VFC<{
  mutation: MutationResultType;
  uuid?: string;
}> = ({ mutation, uuid }) => {
  const navigate = useNavigate();
  const { data } = useQueryOnePage(uuid);
  const isLoading = useIsLoading([QUERY_KEY]);
  const { isSuspendMutations } = useHealthChecker();

  const handleSubmit = useFormikSubmitHandler<Values>({
    mutation,
    navigate_to: "/pages",
    validationSchema
  });

  return (
    <Formik
      initialValues={{
        title: "",
        content: "",
        slug: "",
        ...data
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, touched, values, dirty, setValues }) => (
        <Card mb={6}>
          <CardContent>
            <form onSubmit={handleSubmit}>
              <TextField
                name="title"
                label="Title"
                value={values.title ?? ""}
                error={Boolean(touched.title && errors.title)}
                fullWidth
                helperText={touched.title && errors.title}
                onBlur={e => {
                  handleBlur(e);
                  const slug = values.title
                    ? values.title
                        .toLowerCase()
                        .replace(/\s+/g, "-")
                        .replace(/[^a-z0-9-]+/g, "")
                        .replace(/^-+|-+$/g, "")
                    : "";
                  const fullSlug = `${slug}`;
                  setValues(prevValues => ({
                    ...prevValues,
                    slug: fullSlug
                  }));
                }}
                onChange={handleChange}
                type="text"
                variant="outlined"
                my={2}
                disabled={isLoading}
              />

              <RichTextEditor
                value={values.content ?? ""}
                onChange={(content: string) =>
                  handleChange({ target: { name: "content", value: content } })
                }
              />

              <Grid container justifyContent="space-between" mt={16}>
                <Grid item>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    mt={3}
                    disabled={!dirty || isLoading || isSuspendMutations}
                  >
                    Save
                  </Button>

                  <Button
                    variant="contained"
                    color="secondary"
                    mt={3}
                    ml={3}
                    onClick={() => navigate("/pages")}
                  >
                    Cancel
                  </Button>
                </Grid>

                <Grid item>
                  <Feature name="FEATURE_SEED_DATA">
                    <Button
                      variant="contained"
                      color="primary"
                      mt={3}
                      onClick={async () => {
                        const { faker } = await import("@faker-js/faker");

                        const randomTitle = faker.random.words(2);
                        const slug = randomTitle
                          .toLowerCase()
                          .replace(/\s+/g, "-")
                          .replace(/[^a-z0-9-]+/g, "")
                          .replace(/^-+|-+$/g, "");

                        setValues(prevValues => ({
                          ...prevValues,
                          title: randomTitle,
                          content: faker.lorem.paragraph(),
                          slug: slug
                        }));
                      }}
                    >
                      <RefreshIcon /> Seed Data
                    </Button>
                  </Feature>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      )}
    </Formik>
  );
};

export default PageForm;
