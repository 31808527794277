import LeadStatusList from "./LeadStatusList";
import LeadStatusAdd from "./LeadStatusAdd";
import LeadStatusEdit from "./LeadStatusEdit";

const routes = [
  {
    path: "/lead-status",
    element: <LeadStatusList />
  },
  {
    path: "/lead-status/add",
    element: <LeadStatusAdd />
  },
  {
    path: "/lead-status/:uuid/edit",
    element: <LeadStatusEdit />
  }
];

export default routes;
