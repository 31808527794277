import React from "react";

import { Typography } from "@mui/material";
import styled from "@emotion/styled";

const Container = styled.div`
  max-width: 520px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  padding: ${props => props.theme.spacing(6)};
  text-align: center;
  background: transparent;

  ${props => props.theme.breakpoints.up("md")} {
    padding: ${props => props.theme.spacing(10)};
  }
`;

const PageLoading = () => {
  return (
    <Container>
      <Wrapper>
        <Typography component="h3" variant="h5" align="center" color="text.secondary" gutterBottom>
          Please wait...
        </Typography>
      </Wrapper>
    </Container>
  );
};

export default PageLoading;
