import React from "react";
import styled from "@emotion/styled";
import { v4 } from "uuid";
import { Divider as MuiDivider, Paper as MuiPaper } from "@mui/material";
import { spacing } from "@mui/system";
import PageForm from "./PageForm";
import { useMutationAddPage } from "../../api/Page";
import PageTitleWithLoading from "../../components/PageTitleWithLoading";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const PageAdd = () => {
  const mutation = useMutationAddPage(v4());

  return (
    <Paper p={4}>
      <PageTitleWithLoading title="Add Page" />

      <Divider my={6} />

      <PageForm mutation={mutation} />
    </Paper>
  );
};

export default PageAdd;
