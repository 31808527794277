import React from "react";
import { UseMutationResult, UseQueryResult } from "@tanstack/react-query";
import {
  NumberParam,
  QueryParamConfig,
  SetQuery,
  useQueryParams,
  withDefault
} from "use-query-params";
import { decodeJson, encodeJson } from "serialize-query-params";
import {
  GridColumnVisibilityModel,
  GridFilterModel,
  GridRowSelectionModel,
  GridSortModel
} from "@mui/x-data-grid-pro";
import { PageInfo } from "aerp-types/types/sequelize.mjs";
import { GridPaginationModel } from "@mui/x-data-grid/models/gridPaginationProps";

export const SelectionModelParam: QueryParamConfig<
  GridRowSelectionModel | undefined,
  GridRowSelectionModel | undefined
> = {
  encode: encodeJson,
  decode: decodeJson
};

export const PageInfoParam: QueryParamConfig<
  Omit<PageInfo, "hasNextPage" | "hasPreviousPage"> | undefined,
  Omit<PageInfo, "hasNextPage" | "hasPreviousPage"> | undefined
> = {
  encode: encodeJson,
  decode: decodeJson
};

export const PaginationParam: QueryParamConfig<
  GridPaginationModel | undefined,
  GridPaginationModel | undefined
> = {
  encode: encodeJson,
  decode: decodeJson
};

export const SortModelParam: QueryParamConfig<
  GridSortModel | undefined,
  GridSortModel | undefined
> = {
  encode: encodeJson,
  decode: decodeJson
};

export const FilterModelParam: QueryParamConfig<
  GridFilterModel | undefined,
  GridFilterModel | undefined
> = {
  encode: encodeJson,
  decode: decodeJson
};

export const ColumnVisibilityModelParam: QueryParamConfig<
  GridColumnVisibilityModel | undefined,
  GridColumnVisibilityModel | undefined
> = {
  encode: encodeJson,
  decode: decodeJson
};

export interface DataGridPageType {
  state: {
    selected?: GridRowSelectionModel;
    sorting?: GridSortModel;
    filter?: GridFilterModel;
    columnVisibility?: GridColumnVisibilityModel;
    pageSize?: number;
    page?: number;
  };
  setState: SetQuery<{
    selected?: typeof SelectionModelParam;
    sorting?: typeof SortModelParam;
    filter?: typeof FilterModelParam;
    pageSize?: typeof NumberParam;
    page?: typeof NumberParam;
    columnVisibility?: typeof ColumnVisibilityModelParam;
  }>;
  query: UseQueryResult<Records>;
  mutationBulkDelete?: UseMutationResult;
  pageTitle?: string;
  addButtonNavigateTo?: string;
}

const DataGridPageContext = React.createContext<DataGridPageType | undefined>(undefined);

interface DataGridPageProviderProps {
  children: React.ReactNode;
  useQueryList: () => UseQueryResult<Records>;
  useMutationBulkDelete?: () => UseMutationResult;
  pageTitle?: string;
  addButtonNavigateTo?: string;
  initialState?: DataGridPageType["state"];
}

export type Records<T = object> = T[];

function DataGridPageProvider({
  children,
  useQueryList,
  pageTitle,
  addButtonNavigateTo,
  useMutationBulkDelete: _useMutationBulkDelete,
  initialState
}: DataGridPageProviderProps) {
  const [state, setState] = useQueryParams(
    {
      selected: withDefault(SelectionModelParam, initialState?.selected ?? []),
      pageSize: withDefault(NumberParam, initialState?.pageSize ?? 50),
      page: withDefault(NumberParam, initialState?.page ?? 0),
      sorting: withDefault(SortModelParam, initialState?.sorting ?? []),
      filter: withDefault(FilterModelParam, initialState?.filter),
      columnVisibility: withDefault(ColumnVisibilityModelParam, initialState?.columnVisibility)
    },
    { removeDefaultsFromUrl: true }
  );

  const query = useQueryList();
  const mutationBulkDelete = _useMutationBulkDelete && _useMutationBulkDelete();

  return (
    <DataGridPageContext.Provider
      value={{
        state,
        setState,
        query,
        mutationBulkDelete,
        pageTitle,
        addButtonNavigateTo
      }}
    >
      {children}
    </DataGridPageContext.Provider>
  );
}

function withDataGridPageProvider<P>(
  WrappedComponent: React.ComponentType<P>,
  {
    useQueryList,
    useMutationBulkDelete,
    pageTitle,
    addButtonNavigateTo,
    initialState
  }: {
    useQueryList: () => UseQueryResult<Records>;
    useMutationBulkDelete?: () => UseMutationResult<any, any, any>;
    pageTitle?: string;
    addButtonNavigateTo?: string;
    initialState?: DataGridPageType["state"];
  }
) {
  return (props: any) => {
    return (
      <DataGridPageProvider
        useQueryList={useQueryList}
        useMutationBulkDelete={useMutationBulkDelete}
        pageTitle={pageTitle}
        addButtonNavigateTo={addButtonNavigateTo}
        initialState={initialState}
      >
        <WrappedComponent {...props} />
      </DataGridPageProvider>
    );
  };
}

export { DataGridPageProvider, DataGridPageContext, withDataGridPageProvider };
