import React, { useMemo, useRef, useState } from "react";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider as MuiDivider,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Stack,
  Typography
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import styled from "@emotion/styled";
import { AssertionError } from "node:assert";
import { ValidationError } from "yup";
import { compact, isEmpty } from "lodash";
import Loader from "../../components/Loader";
import { spacing } from "@mui/system";
import { useParams } from "react-router-dom";
import { v4 } from "uuid";
import { useMutationAddFile, useMutationUploader } from "../../api/File";
import { formatISO } from "date-fns";
import confirm from "../../components/Confirm";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { filesize } from "filesize";
import { TableStyled } from "../../components/TableStyled";
import { useQueryOneInventoryItem } from "../../api/InventoryItem";
import { usePriceGridProcessor } from "../../hooks/usePriceGridProcessor";
import { useMutationAddPriceGrid } from "../../api/PriceGrid";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1
});

const PriceGridUploaderCard: React.VFC<{ inventory_item_uuid?: string }> = ({
  inventory_item_uuid
}) => {
  const { uuid } = useParams();
  const { data: inventoryItem } = useQueryOneInventoryItem(inventory_item_uuid ?? uuid);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const {
    data: priceGridConfig,
    fetchStatus: fetchStatusPriceGridProcessor,
    error: errorPriceGridProcessor
  } = usePriceGridProcessor(selectedFile, inventoryItem);
  const isPriceGridProcessorLoading = fetchStatusPriceGridProcessor === "fetching";

  const { mutateAsync: addFile, isLoading: isLoadingAddFile } = useMutationAddFile(v4());
  const { mutateAsync: addPriceGrid, isLoading: isLoadingAddPriceGrid } = useMutationAddPriceGrid(
    v4()
  );

  const {
    mutateAsync: uploadFile,
    error: errorUploadFile,
    isLoading: isLoadingUploadFile,
    reset: resetMutationUploader
  } = useMutationUploader();

  const isMutating = isLoadingAddFile || isLoadingAddPriceGrid || isLoadingUploadFile;

  const errorsMessages = useMemo(() => {
    return compact([errorPriceGridProcessor, errorUploadFile]).reduce((__errors: string[], err) => {
      switch (true) {
        case err instanceof ValidationError:
          return [...__errors, ...err.errors];
        case err instanceof AssertionError:
          return [...__errors, err.message];
        default:
          return [...__errors, (err as Error).message];
      }
    }, []);
  }, [errorPriceGridProcessor, errorUploadFile]);

  const reset = () => {
    if (fileInputRef.current) fileInputRef.current.value = "";
    setSelectedFile(null);
    resetMutationUploader();
  };

  const handleClose = async () => {
    if (
      isEmpty(errorsMessages) &&
      !(await confirm({
        confirmation: "You are about to cancel. Are you sure?",
        options: {
          title: "Confirm"
        }
      }))
    ) {
      return;
    }

    reset();
  };

  const handleUploadSelectedFile = async () => {
    if (!selectedFile || !priceGridConfig) {
      return;
    }

    const uploadedFile = await uploadFile({
      file: selectedFile,
      meta: {
        uuid: v4()
      }
    });

    const fileResponse = await addFile({
      name: uploadedFile.name,
      size_in_bytes: uploadedFile.data.size,
      file_type: uploadedFile.type,
      location_url: uploadedFile.uploadURL
    });

    await addPriceGrid({
      width: priceGridConfig.config.width,
      height_depth: priceGridConfig.config.height_depth,
      matrix: priceGridConfig.config.matrix,
      is_active: priceGridConfig.config.active,
      start_date: formatISO(new Date()),
      inventory_item_uuid: inventoryItem.uuid,
      file_uuid: fileResponse.uuid
    });

    reset();
  };

  return (
    <>
      <Card>
        <CardContent>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h5">Import Price Grid</Typography>
          </Stack>

          <Divider mb={5} mt={2} />

          <Button component="label" variant="contained" startIcon={<CloudUploadIcon />}>
            Upload file
            <VisuallyHiddenInput
              type="file"
              ref={fileInputRef}
              onChange={e => setSelectedFile(e.target.files?.[0] ?? null)}
            />
          </Button>
        </CardContent>
      </Card>

      {!!selectedFile && (
        <Dialog open={!!selectedFile} onClose={handleClose} fullWidth maxWidth="lg">
          <DialogTitle>Import Price Grid</DialogTitle>
          <DialogContent dividers>
            {!!selectedFile && (
              <Paper variant="outlined" sx={{ mb: 2 }}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      {isMutating ? <CircularProgress color="secondary" /> : <AttachFileIcon />}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={selectedFile.name}
                    secondary={`${filesize(selectedFile.size, {
                      standard: "jedec"
                    })} - Last modified: ${formatISO(selectedFile.lastModified)}`}
                  />
                </ListItem>
              </Paper>
            )}
            {isEmpty(errorsMessages) && (
              <>
                <Alert severity="success" sx={{ mb: 2 }}>
                  No errors found, please click the Upload button
                </Alert>
                <Alert severity="info" sx={{ mb: 2 }}>
                  Only the first grid will be processed
                </Alert>
              </>
            )}
            {errorsMessages?.map(msg => (
              <Alert key={msg} severity="error" sx={{ mb: 2 }}>
                {msg}
              </Alert>
            ))}
            <Box sx={{ overflowY: "auto", width: "100%" }}>
              {isPriceGridProcessorLoading && <Loader />}

              {priceGridConfig?.html && (
                <TableStyled dangerouslySetInnerHTML={{ __html: priceGridConfig?.html }} />
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button disabled={isMutating} onClick={handleClose}>
              Cancel
            </Button>
            <Button
              disabled={isMutating || !isEmpty(errorsMessages)}
              onClick={handleUploadSelectedFile}
            >
              Upload
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default PriceGridUploaderCard;
