import useDataGridPage from "../../hooks/useDataGridPage";
import { Box, Button, Stack, Typography } from "@mui/material";
import {
  gridRowSelectionStateSelector,
  GridSlotProps,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  useGridApiContext,
  useGridSelector
} from "@mui/x-data-grid-pro";
import { Link, useLocation } from "react-router-dom";
import { Add as AddIcon, Delete as DeleteIcon } from "@mui/icons-material";
import React from "react";
import confirm from "../Confirm";
import qs from "qs";
import useHealthChecker from "../../hooks/useHealthChecker";
import { Helmet } from "react-helmet-async";

declare module "@mui/x-data-grid-pro" {
  interface ToolbarPropsOverrides {
    setFilterButtonEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
  }
}

const DataGridPageToolbar = ({ setFilterButtonEl }: GridSlotProps["toolbar"]) => {
  const { pageTitle, addButtonNavigateTo, mutationBulkDelete } = useDataGridPage();
  const bulkDelete = mutationBulkDelete?.mutateAsync;
  const apiRef = useGridApiContext();
  const selection = useGridSelector(apiRef, gridRowSelectionStateSelector);
  const location = useLocation();
  const { isSuspendMutations } = useHealthChecker();

  const handleBulkDelete = async () => {
    if (
      bulkDelete &&
      (await confirm({
        confirmation: `You are about to delete ${selection.length} ${
          selection.length > 1 ? "items" : "item"
        }. Are you sure?`,
        options: {
          title: "Confirm"
        }
      }))
    ) {
      await bulkDelete(selection);
      apiRef.current.setRowSelectionModel([]);
    }
  };

  return (
    <Stack
      spacing={4}
      direction="row"
      margin={4}
      alignItems="baseline"
      justifyContent="space-between"
    >
      {pageTitle && (
        <>
          <Helmet title={pageTitle} />
          <Typography variant="h3">{pageTitle}</Typography>
        </>
      )}

      <Box sx={{ flexGrow: 1 }} />

      <GridToolbarContainer>
        <GridToolbarQuickFilter />
        <GridToolbarFilterButton ref={setFilterButtonEl} />
        {/*<GridToolbarColumnsButton />*/}

        {!!addButtonNavigateTo && (
          <Button
            component={Link}
            to={
              addButtonNavigateTo +
              qs.stringify(
                {
                  navigate_to: location.pathname + location.search
                },
                { addQueryPrefix: true }
              )
            }
            variant="text"
            color="secondary"
            size="small"
          >
            <AddIcon />
            Add
          </Button>
        )}

        {selection.length > 0 && !!bulkDelete && (
          <Button
            onClick={handleBulkDelete}
            variant="text"
            color="warning"
            size="small"
            disabled={isSuspendMutations}
          >
            <DeleteIcon />
            Delete Selected
          </Button>
        )}
      </GridToolbarContainer>
    </Stack>
  );
};

export default DataGridPageToolbar;
