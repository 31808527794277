import React, { useMemo, useState } from "react";
import { chain, get, isNil } from "lodash";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Chip,
  IconButton,
  Stack,
  Typography
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { PricingBreakdownTable } from "./PricingBreakdownTable";
import { useOrderLineItemConfValues } from "./useOrderLineItemConfValues";
import { useOrderLineItemProductDesc } from "./useOrderLineItemProductDesc";
import { useQueryOrderLineItemsDerivedInfo } from "../../../api/OrderLineItem";
import { Feature, useFeature } from "use-feature";
import ErrorFallbackMessage from "../../../components/ErrorFallbackMessage";
import { ErrorBoundary } from "react-error-boundary";
import { toDecimalFromFloat } from "aldrin-erp-main-shared-functions";

export const AccordionOrderLineItem: React.VFC<{
  order_line_item: any;
  public_view?: boolean;
  multi_select?: boolean;
  list_item_actions?: React.ReactNode;
  checked?: React.ComponentPropsWithoutRef<typeof Checkbox>["checked"];
  onChange?: React.ComponentPropsWithoutRef<typeof Checkbox>["onChange"];
}> = ({ order_line_item, onChange, checked, list_item_actions, public_view, multi_select }) => {
  const { product, markup_percentage } = order_line_item;

  const configValues = useOrderLineItemConfValues(order_line_item);
  const productDescription = useOrderLineItemProductDesc(order_line_item);
  const { data: derivedInfoRows } = useQueryOrderLineItemsDerivedInfo(order_line_item?.order_id, {
    enabled: useFeature("FEATURE_DEBUG_ORDER")
  });
  const derivedInfo = derivedInfoRows?.find(
    (info: any) => info.order_line_item_id === order_line_item.id
  );

  const _configValues = useMemo(() => {
    const mappedValues = chain(configValues)
      .thru(confs => {
        let headerName: string | null = null;
        return confs
          ?.map(conf => {
            if (conf?.isHeader) {
              headerName = conf.heading;
              return null;
            }

            return {
              ...conf,
              headerName
            };
          })
          ?.filter(conf => !isNil(conf?.value) && conf);
      })
      .reduce((map, conf) => {
        const _confs = map.get(conf?.headerName) ?? [];

        return map.set(conf?.headerName, [..._confs, conf]);
      }, new Map<string | null | undefined, any>())
      .value();

    return mappedValues;
  }, [configValues]);

  const [expanded, setExpanded] = useState(false);

  return (
    <Accordion disableGutters expanded={expanded}>
      <Stack direction="row" spacing={3} alignItems="center">
        {!public_view && !!multi_select && (
          <Checkbox disableRipple checked={checked} onChange={onChange} />
        )}
        <AccordionSummary
          onClick={() => setExpanded(!expanded)}
          sx={{ width: "100%" }}
          expandIcon={
            <IconButton>
              <ExpandMoreIcon />
            </IconButton>
          }
        >
          <Stack direction="row" spacing={3} alignItems="baseline">
            <Typography sx={{ flexShrink: 0 }}>{product?.code}</Typography>
            <Typography sx={{ color: "text.secondary" }}>{productDescription}</Typography>
            <Feature name="FEATURE_DEBUG_ORDER">
              {<Chip label={`id=${order_line_item.id}`} size="small" />}
              {!!derivedInfo?.is_qbcc && <Chip label="QBCC" size="small" />}
              {!!derivedInfo?.is_check_measure && <Chip label="Check Measure" size="small" />}
              <Chip label={`m=${order_line_item.markup_percentage || 0}%`} size="small" />
              <Chip
                label={`ex_gst_disc=${toDecimalFromFloat(order_line_item?.excluding_gst || 0)}`}
                size="small"
              />
              <Chip
                label={`total=${toDecimalFromFloat(order_line_item?.price || 0)}`}
                size="small"
              />
            </Feature>
          </Stack>
        </AccordionSummary>

        {list_item_actions}
      </Stack>
      <AccordionDetails>
        <Stack direction="row" spacing={6} flexWrap="wrap" useFlexGap>
          {Array.from(_configValues).map(([headerName, confs]) => (
            <Stack
              key={headerName}
              direction="column"
              spacing={3}
              flexWrap="wrap"
              useFlexGap
              sx={{
                borderRadius: 1,
                borderColor: "#f3f6fa",
                border: "1px solid",
                padding: 3
              }}
            >
              <Typography variant="h6">{headerName}</Typography>

              {confs.map((conf: any) => {
                return (
                  <Stack
                    key={conf.optionName}
                    direction="row"
                    spacing={3}
                    flexWrap="wrap"
                    useFlexGap
                    justifyContent="space-between"
                  >
                    <Typography sx={{ color: "text.secondary" }}>{conf.heading}</Typography>
                    <Typography>{get(conf, "value.label", conf.value)}</Typography>
                  </Stack>
                );
              })}
            </Stack>
          ))}
        </Stack>
        {/*<List dense>*/}
        {/*  {configValues?.map(conf => (*/}
        {/*    <ListItem*/}
        {/*      key={conf.optionName}*/}
        {/*      secondaryAction={get(conf, "value.label", conf.value)}*/}
        {/*      divider*/}
        {/*    >*/}
        {/*      {conf.isHeader ? (*/}
        {/*        <ListItemText primary={<Typography variant="h6">{conf.heading}</Typography>} />*/}
        {/*      ) : (*/}
        {/*        <ListItemText inset primary={conf.heading} />*/}
        {/*      )}*/}
        {/*    </ListItem>*/}
        {/*  ))}*/}
        {/*</List>*/}

        {!public_view && (
          <Box sx={{ overflow: "auto", width: "100%", my: 4 }}>
            <ErrorBoundary FallbackComponent={ErrorFallbackMessage}>
              <PricingBreakdownTable
                product_id={product?.id}
                markup_percentage={markup_percentage}
                config_values={order_line_item?.config_values}
                config_options={order_line_item?.product_config?.config}
              />
            </ErrorBoundary>
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
