import React from "react";
import styled from "@emotion/styled";
import { Divider as MuiDivider, Paper as MuiPaper } from "@mui/material";
import { spacing } from "@mui/system";
import InventoryItemForm from "./InventoryItemForm";
import { useMutationEditInventoryItem } from "../../api/InventoryItem";
import PageTitleWithLoading from "../../components/PageTitleWithLoading";
import { useParams } from "react-router-dom";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const InventoryItemEdit = () => {
  const { uuid } = useParams<string>();
  const mutation = useMutationEditInventoryItem(uuid ?? "");

  return (
    <Paper p={4}>
      <PageTitleWithLoading title="Edit Inventory Item" />

      <Divider my={6} />

      <InventoryItemForm mutation={mutation} uuid={uuid} isEdit />
    </Paper>
  );
};

export default InventoryItemEdit;
