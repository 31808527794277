import {
  GridFilterInputValueProps,
  GridFilterItem,
  GridFilterOperator
} from "@mui/x-data-grid-pro";
import { compareAsc, compareDesc, endOfDay, isValid, parseISO } from "date-fns";
import { DateRangePicker, DateRangePickerProps } from "@mui/x-date-pickers-pro/DateRangePicker";
import { Box } from "@mui/material";
import React, { useRef } from "react";

export const DateRangeOperator: GridFilterOperator = {
  label: "between",
  value: "date_between",
  // TODO: Need testing
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    if (!filterItem.field || !filterItem.value || !filterItem.operator) {
      return null;
    }

    const [startDate, endDate] = filterItem.value;

    return (_, row): boolean => {
      const value = row[filterItem.field];

      if (!value) {
        return false;
      }

      return (
        compareAsc(parseISO(value), parseISO(startDate)) >= 0 &&
        compareDesc(parseISO(value), parseISO(endDate)) >= 0
      );
    };
  },
  InputComponent: DateRangePickerInputValue
};

function DateRangePickerInputValue(props: GridFilterInputValueProps) {
  const { item, applyValue } = props;

  const inputRef: React.Ref<any> = useRef(null);

  const handleFilterChange: DateRangePickerProps<any>["onChange"] = value => {
    if (value.every(val => val !== null)) {
      const [D1, D2] = value as [Date, Date];

      applyValue({ ...item, value: [D1, endOfDay(D2)] });
    }
  };

  return (
    <Box
      sx={{
        display: "inline-flex",
        flexDirection: "row",
        alignItems: "center",
        pl: "10px"
      }}
    >
      <DateRangePicker
        ref={inputRef}
        onChange={handleFilterChange}
        value={item?.value?.map((val: string) => (isValid(val) ? val : parseISO(val))) ?? null}
        slotProps={{
          textField: {
            variant: "standard"
          }
        }}
      />
    </Box>
  );
}
