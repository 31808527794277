import { dineroAUDFromFloat, toDecimalFromFloat } from "aldrin-erp-main-shared-functions";
import { subtract, toDecimal } from "dinero.js";
import { useMemo } from "react";

export const useOrderMonetaryValues = (order?: any) =>
  useMemo(
    () => ({
      soldPrice: toDecimalFromFloat(order?.sold_price ?? 0),
      retailPrice: toDecimalFromFloat(order?.retail_price ?? 0),
      subtotal: toDecimalFromFloat(order?.subtotal ?? 0),
      excludingGST: toDecimalFromFloat(order?.excluding_gst ?? 0),
      gstAmount: toDecimalFromFloat(order?.gst_amount ?? 0),
      saving: toDecimalFromFloat(order?.saving ?? 0),
      deposit: toDecimalFromFloat(order?.deposit_amount ?? 0),
      deposit_paid: toDecimalFromFloat(order?.deposit_amount_paid ?? 0),
      pre_delivery_amount: toDecimalFromFloat(order?.pre_delivery_amount ?? 0),
      initial_deposit_amount: toDecimal(
        subtract(
          dineroAUDFromFloat(order?.deposit_amount ?? 0),
          dineroAUDFromFloat(order?.deposit_amount_paid ?? 0)
        )
      ),
      initial_pre_delivery_amount: toDecimal(
        subtract(
          dineroAUDFromFloat(order?.pre_delivery_amount ?? 0),
          dineroAUDFromFloat(order?.pre_delivery_amount_paid ?? 0)
        )
      ),
      pre_delivery_amount_paid: toDecimalFromFloat(order?.pre_delivery_amount_paid ?? 0),
      final_balance_amount: toDecimalFromFloat(order?.final_balance_amount ?? 0),
      initial_final_balance_amount: toDecimal(
        subtract(
          dineroAUDFromFloat(order?.final_balance_amount ?? 0),
          dineroAUDFromFloat(order?.final_balance_amount_paid ?? 0)
        )
      ),
      final_balance_amount_paid: toDecimalFromFloat(order?.final_balance_amount_paid ?? 0),
      outstanding_amount: toDecimalFromFloat(order?.outstanding_amount ?? 0)
    }),
    [order]
  );
