import KeyValueList from "./KeyValueList";
import KeyValueAdd from "./KeyValueAdd";
import KeyValueEdit from "./KeyValueEdit";

const routes = [
  {
    path: "/key-value",
    element: <KeyValueList />
  },
  {
    path: "/key-value/add",
    element: <KeyValueAdd />
  },
  {
    path: "/key-value/:uuid/edit",
    element: <KeyValueEdit />
  }
];

export default routes;
