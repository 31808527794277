import React, { Suspense } from "react";
import { Card, Stack } from "@mui/material";
import SalesRepCard from "./SalesRepCard.tsx";
import Loader from "../../../components/Loader.tsx";
import SalesRepStatisticsCard from "./SalesRepStatisticsCard.tsx";
import { SalesRepDashboardTables } from "./SalesRepDashboardTables.tsx";

export const SalesRepDashboardPartial = ({ uuid }: { uuid: string }) => {
  return (
    <>
      <Stack
        direction="row"
        spacing={6}
        justifyContent="flex-start"
        alignItems="stretch"
        useFlexGap
        flexWrap="wrap"
      >
        <Card>
          <Suspense fallback={<Loader />}>
            <SalesRepCard sales_rep_user_uuid={uuid} />
          </Suspense>
        </Card>
        <Card>
          <Suspense fallback={<Loader />}>
            <SalesRepStatisticsCard sales_rep_user_uuids={[uuid]} />
          </Suspense>
        </Card>
      </Stack>

      <Suspense fallback={<Loader />}>
        <SalesRepDashboardTables sales_rep_user_uuids={[uuid]} />
      </Suspense>
    </>
  );
};
