import AccountGroupList from "./AccountGroupList";
import AccountGroupAdd from "./AccountGroupAdd";
import AccountGroupEdit from "./AccountGroupEdit";

const routes = [
  {
    path: "/account-group",
    element: <AccountGroupList />
  },
  {
    path: "/account-group/add",
    element: <AccountGroupAdd />
  },
  {
    path: "/account-group/:uuid/edit",
    element: <AccountGroupEdit />
  }
];

export default routes;
