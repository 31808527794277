import {
  useMutationAddModel,
  useMutationBulkDeleteModelV2,
  useMutationDeleteModel,
  useMutationEditModel,
  useQueryModels,
  useQueryOneModel
} from "../hooks/useMutationFormAbstract";

export const useQueryLeadSources = () => useQueryModels("lead-source");

export const useQueryOneLeadSource = (uuid?: string) => useQueryOneModel<any>("lead-source", uuid);

export const useMutationAddLeadSource = (uuid: string) => useMutationAddModel("lead-source", uuid);

export const useMutationEditLeadSource = (uuid: string) =>
  useMutationEditModel("lead-source", uuid);

export const useMutationDeleteLeadSource = () => useMutationDeleteModel("lead-source");

export const useMutationBulkDeleteLeadSource = () => useMutationBulkDeleteModelV2("lead-source");
