import { useQuery } from "@tanstack/react-query";
import { geocode, OutputFormat, RequestType } from "react-geocode";
import { first } from "lodash";
import { GoogleMapsAPIKey } from "../config";

export const useQueryGoogleMapByAddress = (address?: string) => {
  return useQuery<{
    lat: number;
    lng: number;
  }>({
    enabled: !!address,
    suspense: false,
    useErrorBoundary: false,
    queryKey: ["google-map", { address }],
    queryFn: async () => {
      const addressResponse = await geocode(RequestType.ADDRESS, address ?? "", {
        key: GoogleMapsAPIKey,
        language: "en",
        outputFormat: OutputFormat.JSON
      });

      const result: any = first(addressResponse?.results);

      return result.geometry?.location;
    }
  });
};
