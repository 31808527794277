// src/features/user/user.types.ts
var USER_ROLES = /* @__PURE__ */ ((USER_ROLES2) => {
  USER_ROLES2["SUPER_ADMIN"] = "SUPER_ADMIN";
  USER_ROLES2["ADMIN"] = "ADMIN";
  USER_ROLES2["SALES_REP"] = "SALES_REP";
  USER_ROLES2["OFFICE"] = "OFFICE";
  USER_ROLES2["PUBLIC"] = "PUBLIC";
  USER_ROLES2["FITTER"] = "FITTER";
  USER_ROLES2["FACTORY"] = "FACTORY";
  return USER_ROLES2;
})(USER_ROLES || {});

// src/features/order/order.types.ts
var OrderStatusBit = /* @__PURE__ */ ((OrderStatusBit2) => {
  OrderStatusBit2[OrderStatusBit2["EMPTY"] = 0] = "EMPTY";
  OrderStatusBit2[OrderStatusBit2["CHECKOUT"] = 1] = "CHECKOUT";
  OrderStatusBit2[OrderStatusBit2["OPEN"] = 2] = "OPEN";
  OrderStatusBit2[OrderStatusBit2["PROCESSING"] = 4] = "PROCESSING";
  OrderStatusBit2[OrderStatusBit2["INVOICED"] = 8] = "INVOICED";
  OrderStatusBit2[OrderStatusBit2["COMPLETE"] = 16] = "COMPLETE";
  return OrderStatusBit2;
})(OrderStatusBit || {});
var OrderStatusV2 = /* @__PURE__ */ ((OrderStatusV22) => {
  OrderStatusV22["EMPTY"] = "empty";
  OrderStatusV22["CHECKOUT"] = "checkout";
  OrderStatusV22["OPEN"] = "open";
  OrderStatusV22["PROCESSING"] = "processing";
  OrderStatusV22["INVOICED"] = "invoiced";
  OrderStatusV22["COMPLETE"] = "complete";
  return OrderStatusV22;
})(OrderStatusV2 || {});
export {
  OrderStatusBit,
  OrderStatusV2,
  USER_ROLES
};
