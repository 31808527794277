import {
  useMutationAddModel,
  useMutationBulkDeleteModelV2,
  useMutationDeleteModel,
  useMutationEditModel,
  useQueryModels,
  useQueryModelsV2,
  useQueryOneModel
} from "../hooks/useMutationFormAbstract";

export const useQueryCustomerGroups = () => useQueryModels("customer-groups");
export const useQueryCustomerGroupsV2 = () => useQueryModelsV2("customer-groups");

export const useQueryOneCustomerGroup = (uuid?: string) =>
  useQueryOneModel<any>("customer-groups", uuid);

export const useMutationAddCustomerGroup = (uuid: string) =>
  useMutationAddModel("customer-groups", uuid);

export const useMutationEditCustomerGroup = (uuid: string) =>
  useMutationEditModel("customer-groups", uuid);

export const useMutationDeleteCustomerGroup = () => useMutationDeleteModel("customer-groups");

export const useMutationBulkDeleteCustomerGroup = () =>
  useMutationBulkDeleteModelV2("customer-groups");
