import React from "react";
import styled from "@emotion/styled";
import {
  Alert as MuiAlert,
  AlertTitle,
  Box,
  Button,
  Card as MuiCard,
  CardContent,
  CircularProgress as MuiCircularProgress,
  Grid,
  Link as MuiLink,
  List,
  Stack,
  Typography
} from "@mui/material";
import { spacing } from "@mui/system";
import { useQueryOrderLineItemsByOrderId } from "../../../api/OrderLineItem";
import { Link, useParams } from "react-router-dom";
import { useQueryOneOrderPublic } from "../../../api/Order";
import CartSummaryTable from "../form/CartSummaryTable";
import { Helmet } from "react-helmet-async";
import useIsLoading from "../../../hooks/useIsLoading";
import { format } from "date-fns";
import qs from "qs";
import { pportalOrdersBaseURL } from "../../../config";
import { AccordionOrderLineItem } from "../components/AccordionOrderLineItem";
import PaymentTermsTable from "../form/PaymentTermsTable";
import CustomerDetailsCardV2 from "../../customer/CustomerDetailsCardV2";
import { useQueryOneCompanyByCustomerGroupId } from "../../../api/Company";
import TransactionsTable from "../form/TransactionsTable";
import { OptionsButton } from "./OptionsButton";
import { chain } from "lodash";
import { OrderProgress } from "../components/OrderProgress.tsx";
import { useOpenObserveRecordSessionReplay } from "../../../hooks/useOpenObserveRecordSessionReplay.ts";

const CircularProgress = styled(MuiCircularProgress)(spacing);
const Card = styled(MuiCard)(spacing);
const Image = styled.img(spacing);
const Alert = styled(MuiAlert)(spacing);

const OrderQuoteFinalized = () => {
  const { uuid } = useParams<string>();
  const { data: order } = useQueryOneOrderPublic(uuid);
  const { customer_group_id } = order?.customer ?? {};
  const { data: company } = useQueryOneCompanyByCustomerGroupId(customer_group_id);
  const { data: orderLineItems } = useQueryOrderLineItemsByOrderId(order?.id);
  const isLoading = useIsLoading();
  useOpenObserveRecordSessionReplay();

  if (!order?.is_quote_finalized) {
    return (
      <>
        <Helmet title={`Order ${order?.reference_number}`} />

        <Card mb={3}>
          <CardContent>Not yet finalized</CardContent>
        </Card>
      </>
    );
  }

  const depositPendingEft =
    !!order?.quote_deposit_payment_initiated_at &&
    order?.quote_deposit_payment_initiated_using === "eft" &&
    !order?.deposit_amount_paid_at;

  const initiatedDeposit = chain(order?.transactions)
    .filter(tran => tran.transaction_for === "deposit")
    .filter(tran => tran.status === "initiated")
    .orderBy("id", "desc")
    .first()
    .value();

  const initiatedPreDelivery = chain(order?.transactions)
    .filter(tran => tran.transaction_for === "pre-delivery")
    .filter(tran => tran.status === "initiated")
    .orderBy("id", "desc")
    .first()
    .value();

  const initiatedFinalBalance = chain(order?.transactions)
    .filter(tran => tran.transaction_for === "final")
    .filter(tran => tran.status === "initiated")
    .orderBy("id", "desc")
    .first()
    .value();

  const paymentButtons = [
    !!order?.deposit_amount && !order?.deposit_amount_paid_at && (
      <Button
        variant="contained"
        color="primary"
        component="a"
        href={
          initiatedDeposit?.metadata
            ? `${pportalOrdersBaseURL}/payment${qs.stringify(
                {
                  customerInfo: {
                    firstName: initiatedDeposit?.metadata?.firstName,
                    lastName: initiatedDeposit?.metadata?.firstName,
                    email: initiatedDeposit?.metadata?.email,
                    merchantReference: initiatedDeposit?.metadata?.merchantReference,
                    authKey: initiatedDeposit?.metadata?.authkey,
                    totalQuoteAmount: initiatedDeposit?.metadata?.totalQuoteAmount,
                    testMode: initiatedDeposit?.metadata?.TestMode,
                    testModeReason: initiatedDeposit?.metadata?.TestModeReason,
                    order_uuid: order?.uuid,
                    amount: order?.deposit_amount ?? 0,
                    transaction_for: "deposit",
                    company_image: company?.image
                  }
                },
                { addQueryPrefix: true }
              )}`
            : `${pportalOrdersBaseURL}${qs.stringify(
                {
                  initialValues: {
                    firstName: order?.customer?.first_name,
                    lastName: order?.customer?.last_name,
                    email: order?.customer?.email,
                    merchantReference: order?.reference_number,
                    order_uuid: order?.uuid,
                    is_test_mode: order?.is_test_mode,
                    totalQuoteAmount: order?.sold_price,
                    amount: order?.deposit_amount ?? 0,
                    transaction_for: "deposit",
                    company_image: company?.image
                  }
                },
                { addQueryPrefix: true }
              )}`
        }
        target="_blank"
      >
        Accept Order
      </Button>
    ),
    !!order?.pre_delivery_amount && !order?.pre_delivery_amount_paid_at && (
      <Button
        variant="contained"
        color="primary"
        component="a"
        href={
          initiatedPreDelivery?.metadata
            ? `${pportalOrdersBaseURL}/payment${qs.stringify(
                {
                  customerInfo: {
                    firstName: initiatedPreDelivery?.metadata?.firstName,
                    lastName: initiatedPreDelivery?.metadata?.firstName,
                    email: initiatedPreDelivery?.metadata?.email,
                    merchantReference: initiatedPreDelivery?.metadata?.merchantReference,
                    authKey: initiatedPreDelivery?.metadata?.authkey,
                    totalQuoteAmount: initiatedPreDelivery?.metadata?.totalQuoteAmount,
                    testMode: initiatedPreDelivery?.metadata?.TestMode,
                    testModeReason: initiatedPreDelivery?.metadata?.TestModeReason,
                    order_uuid: order?.uuid,
                    amount: order?.pre_delivery_amount ?? 0,
                    transaction_for: "pre-delivery",
                    company_image: company?.image
                  }
                },
                { addQueryPrefix: true }
              )}`
            : `${pportalOrdersBaseURL}${qs.stringify(
                {
                  initialValues: {
                    firstName: order?.customer?.first_name,
                    lastName: order?.customer?.last_name,
                    email: order?.customer?.email,
                    merchantReference: order?.reference_number,
                    order_uuid: order?.uuid,
                    is_test_mode: order?.is_test_mode,
                    totalQuoteAmount: order?.sold_price,
                    amount: order?.pre_delivery_amount ?? 0,
                    transaction_for: "pre-delivery",
                    company_image: company?.image
                  }
                },
                { addQueryPrefix: true }
              )}`
        }
        target="_blank"
      >
        Pay Pre-Delivery
      </Button>
    ),
    !!order?.final_balance_amount && !order?.final_balance_amount_paid_at && (
      <Button
        variant="contained"
        color="primary"
        component="a"
        href={
          initiatedFinalBalance?.metadata
            ? `${pportalOrdersBaseURL}/payment${qs.stringify(
                {
                  customerInfo: {
                    firstName: initiatedFinalBalance?.metadata?.firstName,
                    lastName: initiatedFinalBalance?.metadata?.firstName,
                    email: initiatedFinalBalance?.metadata?.email,
                    merchantReference: initiatedFinalBalance?.metadata?.merchantReference,
                    authKey: initiatedFinalBalance?.metadata?.authkey,
                    totalQuoteAmount: initiatedFinalBalance?.metadata?.totalQuoteAmount,
                    testMode: initiatedFinalBalance?.metadata?.TestMode,
                    testModeReason: initiatedFinalBalance?.metadata?.TestModeReason,
                    order_uuid: order?.uuid,
                    amount: order?.final_balance_amount ?? 0,
                    transaction_for: "final",
                    company_image: company?.image
                  }
                },
                { addQueryPrefix: true }
              )}`
            : `${pportalOrdersBaseURL}${qs.stringify(
                {
                  initialValues: {
                    firstName: order?.customer?.first_name,
                    lastName: order?.customer?.last_name,
                    email: order?.customer?.email,
                    merchantReference: order?.reference_number,
                    order_uuid: order?.uuid,
                    is_test_mode: order?.is_test_mode,
                    totalQuoteAmount: order?.sold_price,
                    amount: order?.final_balance_amount ?? 0,
                    transaction_for: "final",
                    company_image: company?.image
                  }
                },
                { addQueryPrefix: true }
              )}`
        }
        target="_blank"
      >
        Pay Final Balance
      </Button>
    )
  ];

  return (
    <Stack direction="column" spacing={3} useFlexGap>
      <Helmet title={`Order ${order?.reference_number}`} />

      {Boolean(order?.is_test_mode) && (
        <Card>
          <Alert severity="warning">
            <AlertTitle>TEST ORDER</AlertTitle>
            Payments will be marked as TEST mode.
          </Alert>
        </Card>
      )}

      <Card>
        <CardContent>
          <Stack
            direction="row"
            spacing={6}
            justifyContent="space-between"
            alignItems="center"
            useFlexGap
            flexWrap="wrap"
          >
            <Box
              sx={{ display: "flex", flexDirection: "row", alignItems: "center", flexWrap: "wrap" }}
            >
              {company?.image && (
                <Image
                  style={{
                    maxWidth: 300,
                    height: "auto"
                  }}
                  mr={6}
                  src={company?.image}
                  alt={company?.name}
                />
              )}

              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  <Typography variant="h3" textTransform="capitalize" gutterBottom>{`${
                    order?.order_type
                  } ${order?.reference_number}  ${
                    order.quote_accepted_at && !order?.is_invoiced
                      ? "Pro Forma Invoice"
                      : order?.quote_accepted_at && order?.is_invoiced
                      ? "Tax Invoice"
                      : ""
                  }`}</Typography>

                  {isLoading && <CircularProgress mx={2} size={25} />}
                </Box>

                {!!order?.notes_reference && (
                  <Typography variant="h6" gutterBottom>
                    {order?.notes_reference}
                  </Typography>
                )}

                {order?.sales_rep && (
                  <Stack
                    direction="row"
                    spacing={2}
                    divider={<span>|</span>}
                    alignItems="center"
                    useFlexGap
                    flexWrap="wrap"
                  >
                    <Typography variant="caption">
                      {`Prepared by ${order?.sales_rep?.full_name}`}
                    </Typography>
                    <Typography variant="caption">
                      {format(new Date(order?.createdAt), "dd/MM/yyyy p")}
                    </Typography>
                    {order?.sales_rep?.email && (
                      <MuiLink href={`mailto:${order?.sales_rep?.email}`} variant="caption">
                        {order?.sales_rep?.email}
                      </MuiLink>
                    )}
                    {order?.sales_rep?.phone_number && (
                      <MuiLink href={`tel:${order?.sales_rep?.phone_number}`} variant="caption">
                        {order?.sales_rep?.phone_number}
                      </MuiLink>
                    )}
                  </Stack>
                )}

                {order?.last_updated_by_user && (
                  <Stack
                    direction="row"
                    spacing={2}
                    divider={<span>|</span>}
                    alignItems="center"
                    useFlexGap
                    flexWrap="wrap"
                  >
                    <Typography variant="caption">
                      {`Last edited by ${order?.last_updated_by_user?.full_name}`}
                    </Typography>

                    <Typography variant="caption">
                      {format(new Date(order?.updatedAt), "dd/MM/yyyy p")}
                    </Typography>
                  </Stack>
                )}
              </Box>
            </Box>

            {!!order?.quote_accepted_at && (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="h4">Order Accepted</Typography>
                <Typography variant="caption">
                  {format(new Date(order?.quote_accepted_at), "dd/MM/yyyy p")}
                </Typography>

                <Typography variant="caption">
                  {[
                    order?.deposit_amount_paid_at
                      ? "Deposit Processed"
                      : order?.quote_deposit_payment_initiated_at
                      ? "Deposit Pending"
                      : "",
                    !!order?.quote_deposit_payment_initiated_using &&
                      ` (${order?.quote_deposit_payment_initiated_using})`
                  ]
                    .filter(v => v)
                    .join(" ")
                    .trim()}
                </Typography>
              </Box>
            )}

            {!depositPendingEft && paymentButtons.find(Boolean)}

            <OptionsButton />
          </Stack>
        </CardContent>
      </Card>

      <Grid container columnSpacing={3}>
        <Grid item xs={12} lg={8}>
          <Card>
            <CardContent>
              <List>
                {orderLineItems?.map((order_line_item: any) => {
                  return (
                    <AccordionOrderLineItem
                      key={order_line_item.uuid}
                      order_line_item={order_line_item}
                      public_view
                    />
                  );
                })}
              </List>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Stack direction="column" spacing={3} useFlexGap>
            <Card>
              <CustomerDetailsCardV2
                site_address={order?.site_address}
                customer_uuid={order?.customer?.uuid}
                hideDetailsButton
                hideCreateOrderFromButton
                hideMoreOptionsButton
              />
            </Card>

            <Card>
              <CardContent>
                <Typography gutterBottom variant="h6">
                  Summary
                </Typography>

                <CartSummaryTable />

                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <Button
                    sx={{ alignSelf: "center" }}
                    href="/pages/terms--conditions"
                    target="_blank"
                  >
                    Terms & Conditions
                  </Button>
                  <Button sx={{ alignSelf: "center" }} href="/pages/warranty" target="_blank">
                    Warranty
                  </Button>
                  {order?.qbcc_contract_pdf_file && (
                    <Button
                      sx={{ alignSelf: "center" }}
                      component={Link}
                      to={order?.qbcc_contract_pdf_file?.location_url}
                      target="_blank"
                    >
                      Download QBCC contract
                    </Button>
                  )}
                </Box>
              </CardContent>
            </Card>

            <Card>
              <CardContent>
                <Typography gutterBottom variant="h6">
                  Payment Terms
                </Typography>

                <PaymentTermsTable />
              </CardContent>
            </Card>

            <Card>
              <CardContent>
                <TransactionsTable disablePaymentButton />
              </CardContent>
            </Card>
          </Stack>
        </Grid>
      </Grid>

      <Card>
        <CardContent
          sx={{
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Typography>Order Progress:</Typography>

          <OrderProgress status={order?.status_v2} />
        </CardContent>
      </Card>

      {company && (
        <Card>
          <CardContent>
            <Stack
              direction="row"
              spacing={3}
              divider={<span>|</span>}
              justifyContent="center"
              alignItems="center"
              useFlexGap
              flexWrap="wrap"
            >
              {company?.name && <Typography variant="h5">{company?.name}</Typography>}
              {company?.address && <Typography variant="caption">{company?.address}</Typography>}
              {company?.email && (
                <MuiLink href={`mailto:${company?.email}`} variant="caption">
                  {company?.email}
                </MuiLink>
              )}
              {company?.abn && <Typography variant="caption">ABN: {company?.abn}</Typography>}
              {company?.bsb && <Typography variant="caption">BSB: {company?.bsb}</Typography>}
              {company?.acc && <Typography variant="caption">ACC: {company?.acc}</Typography>}
            </Stack>
          </CardContent>
        </Card>
      )}
    </Stack>
  );
};

export default OrderQuoteFinalized;
