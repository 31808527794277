import { useQuery } from "@tanstack/react-query";
import type { WorkSheet } from "xlsx";

export const useXlsxSheetToHTML = (sheetName?: string, worksheet?: WorkSheet) => {
  return useQuery({
    enabled: !!worksheet && !!sheetName,
    queryKey: ["xlsx-sheet-to-html", sheetName],
    queryFn: async () => {
      if (!worksheet) {
        return null;
      }

      const { utils } = await import("xlsx");

      return utils.sheet_to_html(worksheet, {
        header: "" // Intentionally left blank
      });
    }
  });
};
