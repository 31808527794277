import React from "react";
import { Add as AddIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { generatePath, Link as RouterLink } from "react-router-dom";
import { Button, FormControl, FormGroup } from "@mui/material";
import {
  gridRowSelectionStateSelector,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  ToolbarPropsOverrides,
  useGridApiContext,
  useGridSelector
} from "@mui/x-data-grid-pro";
import { Stack } from "@mui/system";
import qs from "qs";
import confirm from "../../components/Confirm.tsx";
import useHealthChecker from "../../hooks/useHealthChecker.ts";

export const DataGridToolbar = ({ bulkDelete }: ToolbarPropsOverrides) => {
  const { isSuspendMutations } = useHealthChecker();
  const apiRef = useGridApiContext();
  const selection = useGridSelector(apiRef, gridRowSelectionStateSelector);

  const handleBulkDelete = async () => {
    if (
      bulkDelete &&
      (await confirm({
        confirmation: `You are about to delete ${selection.length} ${
          selection.length > 1 ? "items" : "item"
        }. Are you sure?`,
        options: {
          title: "Confirm"
        }
      }))
    ) {
      await bulkDelete(selection);
      apiRef.current.setRowSelectionModel([]);
    }
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="flex-end"
      flexWrap="wrap"
      gap={3}
      m={3}
      my={4}
    >
      <FormGroup row>
        <FormControl sx={{ m: 1, minWidth: 100 }}>
          <GridToolbarContainer>
            <GridToolbarQuickFilter />
            <GridToolbarFilterButton />

            <Button
              component={RouterLink}
              to={generatePath(
                "/customers/add" +
                  qs.stringify(
                    {
                      navigate_to: location.pathname + location.search
                    },
                    { addQueryPrefix: true }
                  )
              )}
              variant="text"
              color="secondary"
              size="small"
            >
              <AddIcon />
              Add
            </Button>

            {selection.length > 0 && !!bulkDelete && (
              <Button
                onClick={handleBulkDelete}
                variant="text"
                color="warning"
                size="small"
                disabled={isSuspendMutations}
              >
                <DeleteIcon />
                Delete Selected
              </Button>
            )}
          </GridToolbarContainer>
        </FormControl>
      </FormGroup>
    </Stack>
  );
};
