import React from "react";
import styled from "@emotion/styled";
import { v4 } from "uuid";
import { Divider as MuiDivider, Paper as MuiPaper } from "@mui/material";
import { spacing } from "@mui/system";
import { useMutationAddCustomer } from "../../api/Customer";
import CustomerForm from "./CustomerForm";
import PageTitleWithLoading from "../../components/PageTitleWithLoading";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const CustomerAdd = () => {
  const uuid = v4();
  const mutation = useMutationAddCustomer(uuid);

  return (
    <Paper p={4}>
      <PageTitleWithLoading title="Add Customer" />

      <Divider my={6} />

      <CustomerForm mutation={mutation} uuid={uuid} isEdit={false} />
    </Paper>
  );
};

export default CustomerAdd;
