import { Button, CardContent } from "@mui/material";
import confirm from "../../components/Confirm";
import { exportedDebtorStatementDialog } from "./ExportedDebtorStatementDialog";
import { v4 } from "uuid";
import { useDebtorStatementExport } from "../../api/DebtorStatementExport";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import { endOfToday } from "date-fns";

export const PrintDebtorStatement = ({ customer_uuid }: { customer_uuid: string }) => {
  const [asOfDate] = useQueryParam(
    "asOfDate",
    withDefault(StringParam, endOfToday().toISOString())
  );

  const { mutateAsync: exportDebtorStatement, isLoading: isLoadingExport } =
    useDebtorStatementExport();

  return (
    <CardContent style={{ padding: 8 }}>
      <Button
        variant="outlined"
        size="small"
        color="primary"
        fullWidth
        onClick={async () => {
          if (
            await confirm({
              confirmation: `You are about to export. Are you sure?`,
              options: {
                title: "Confirm"
              }
            })
          ) {
            const uuid = v4();

            await exportDebtorStatement({
              uuid,
              customer_uuid,
              date_as_at: asOfDate
            });

            await exportedDebtorStatementDialog({
              title: `Exported Debtor Statement`,
              export_uuid: uuid
            });
          }
        }}
        disabled={isLoadingExport}
        startIcon={<FileDownloadIcon />}
      >
        Print statement
      </Button>
    </CardContent>
  );
};
