import BankReconciliationPaymentsList from "./BankReconciliationPaymentsList";

const routes = [
  {
    path: "/bank-reconciliation",
    element: <BankReconciliationPaymentsList />
  }
];

export default routes;
