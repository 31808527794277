import { useField } from "formik";
import styled from "@emotion/styled";
import { Autocomplete, Box, Grid, TextField as MuiTextField, Typography } from "@mui/material";
import { spacing } from "@mui/system";
import usePlacesAutocomplete from "use-places-autocomplete";
import * as React from "react";
import { useEffect } from "react";
import parse from "autosuggest-highlight/parse";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { get } from "lodash";
import { useDebounceCallback } from "usehooks-ts";
import { useGlobalGoogleMapJsApiLoader } from "../hooks/useGlobalGoogleMapJsApiLoader";

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

export default function GooglePlacesInput({
  label,
  name,
  helpText,
  disabled
}: {
  label: string;
  name: string;
  helpText?: false | string;
  disabled?: boolean;
}) {
  const [, meta, helpers] = useField(name);
  const { value: fieldValue } = meta;
  const setFieldValue = useDebounceCallback(helpers.setValue, 500);

  const {
    value,
    suggestions: { loading, data },
    setValue,
    init
  } = usePlacesAutocomplete({
    defaultValue: fieldValue,
    cache: false,
    debounce: 400,
    initOnMount: false
  });

  const { isLoaded: isGoogleMapJSAPILoaded, loadError: loadErrorGoogleJSApi } =
    useGlobalGoogleMapJsApiLoader();

  useEffect(() => {
    if (isGoogleMapJSAPILoaded && !loadErrorGoogleJSApi) {
      init();
    }
  }, [isGoogleMapJSAPILoaded, loadErrorGoogleJSApi]);

  useEffect(() => {
    setValue(fieldValue);
  }, [fieldValue]);

  useEffect(() => {
    setFieldValue(value);
  }, [value]);

  return (
    <Autocomplete
      fullWidth
      getOptionLabel={option => get(option, "description", String(option))}
      filterOptions={x => x}
      options={data}
      autoComplete
      includeInputInList
      filterSelectedOptions
      disabled={disabled}
      loading={loading}
      noOptionsText={"No data found"}
      freeSolo
      onChange={(_, newValue) => {
        newValue && setFieldValue(get(newValue, "description", String(newValue)));
      }}
      onInputChange={(event, newInputValue) => {
        setValue(newInputValue);
      }}
      renderInput={params => {
        return (
          <TextField
            {...params}
            inputProps={{ ...params.inputProps, value: value ?? "" }}
            helperText={(meta.touched && meta.error) || helpText}
            error={Boolean(meta.touched && meta.error)}
            label={label}
            margin="normal"
          />
        );
      }}
      renderOption={({ key, ...props }, option) => {
        const matches = option.structured_formatting.main_text_matched_substrings || [];

        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [match.offset, match.offset + match.length])
        );

        return (
          <li key={key} {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: "flex", width: 44 }}>
                <LocationOnIcon sx={{ color: "text.secondary" }} />
              </Grid>
              <Grid item sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}>
                {parts.map((part: any, index: number) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? "bold" : "regular" }}
                  >
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
