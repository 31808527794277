import CustomerGroupsList from "./CustomerGroupList";
import CustomerGroupAdd from "./CustomerGroupAdd";
import CustomerGroupEdit from "./CustomerGroupEdit";

const routes = [
  {
    path: "/customer-groups",
    element: <CustomerGroupsList />
  },
  {
    path: "/customer-groups/add",
    element: <CustomerGroupAdd />
  },
  {
    path: "/customer-groups/:uuid/edit",
    element: <CustomerGroupEdit />
  }
];

export default routes;
