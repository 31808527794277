import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import {
  useMutationAddModel,
  useMutationBulkDeleteModelV2,
  useMutationDeleteModel,
  useMutationEditModel,
  useQueryFormOptionsModel,
  useQueryModels,
  useQueryOneModel
} from "../hooks/useMutationFormAbstract";
import axios from "../utils/axios.ts";
import { AxiosRequestConfig } from "axios";

export const useQueryCustomers = () => useQueryModels("customers");

export const useQueryOneCustomer = (uuid?: string, options?: UseQueryOptions) =>
  useQueryOneModel<any>("customers", uuid, options);

export const useQueryCustomerFormOptions = () =>
  useQueryFormOptionsModel<{
    existing_customer_emails: any;
    customer_groups: any;
  }>("customers");

export const useMutationAddCustomer = (uuid: string) => useMutationAddModel("customers", uuid);

export const useMutationEditCustomer = (uuid: string) => useMutationEditModel("customers", uuid);

export const useMutationDeleteCustomer = () => useMutationDeleteModel("customers");

export const useMutationBulkDeleteCustomer = () => useMutationBulkDeleteModelV2("customers");

export interface outstanding_order {
  customer_uuid: string;
  is_invoiced_at: string;
  order_id: number;
  order_uuid: string;
  outstanding: number;
  reference_number: string;
  sold: number;
}

export function useQueryCustomerStatistics<
  T = {
    excluding_gst: number;
    gst: number;
    balance: {
      sum: number;
      outstanding_orders: outstanding_order[];
    };
    invoiced_current: {
      sum: number;
      outstanding_orders: outstanding_order[];
    };
    invoiced_30_days: {
      sum: number;
      outstanding_orders: outstanding_order[];
    };
    invoiced_60_days: {
      sum: number;
      outstanding_orders: outstanding_order[];
    };
    invoiced_90_days: {
      sum: number;
      outstanding_orders: outstanding_order[];
    };
    invoiced_90_plus_days: {
      sum: number;
      outstanding_orders: outstanding_order[];
    };
  },
  TError = unknown
>(
  customer_uuid?: string,
  transactions_as_of_date?: Date | null,
  options?: UseQueryOptions<T, TError>,
  requestConfig?: AxiosRequestConfig
) {
  return useQuery<T, TError>({
    enabled: !!customer_uuid && !!transactions_as_of_date,
    queryKey: [
      "customers",
      "statistics",
      { customer_uuid, transactions_as_of_date: transactions_as_of_date }
    ],
    queryFn: ({ signal }) =>
      axios
        .get(`/api/customers/${customer_uuid}/statistics`, {
          signal,
          params: { transactions_as_of_date },
          ...requestConfig
        })
        .then(({ data }) => data),
    ...options
  });
}
