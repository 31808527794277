import type { ParsingOptions, WorkBook } from "xlsx";
import { sha256 } from "crypto-hash";

export const parseWorkbook = (
  blob: Blob,
  opts?: ParsingOptions
): Promise<{ workbook: WorkBook; hash: string }> => {
  return new Promise(resolve =>
    blob.arrayBuffer().then(arrayBuff => {
      import("xlsx").then(XLSX => {
        const workbook = XLSX.read(arrayBuff, opts);

        sha256(arrayBuff).then(hash => resolve({ workbook, hash }));
      });
    })
  );
};
