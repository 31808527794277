import React from "react";
import { generatePath, Link as RouterLink } from "react-router-dom";
import { Card, Chip, Link, Stack } from "@mui/material";
import { useMutationBulkDeleteCustomer, useQueryCustomerFormOptions } from "../../api/Customer";

import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import useSetPageTitle from "../../hooks/useSetPageTitle.ts";
import { DataGridServerIndexBasedPaginated } from "../../components/data-grid-v2/DataGridServerIndexBasedPaginated.tsx";
import { DataGridToolbar } from "./DataGridToolbar.tsx";
import { getGridSingleSelectMoreOperators } from "../../components/data-grid-v2/getGridSingleSelectMoreOperators.ts";

const CustomersList = () => {
  useSetPageTitle("Customers");
  const { mutateAsync: bulkDelete } = useMutationBulkDeleteCustomer();
  const { data: formOptions } = useQueryCustomerFormOptions();

  const columns: GridColDef[] = [
    {
      field: "email",
      headerName: "Email",
      resizable: true,
      width: 200,
      renderCell: (params: GridRenderCellParams) => {
        const { value, row } = params;
        const { uuid } = row;

        return (
          <>
            <Link
              component={RouterLink}
              underline="none"
              to={generatePath("/customers/:uuid/details", { uuid })}
            >
              {value}
            </Link>

            {!!row?.is_tester && (
              <Chip label="Tester" size="small" color="warning" sx={{ mt: 1 }} />
            )}
          </>
        );
      }
    },

    {
      field: "company_name",
      headerName: "Name",
      resizable: true,
      width: 300,
      renderCell: (params: GridRenderCellParams) => {
        const { row } = params;
        const { uuid } = row;
        const userInfo = row.company_name
          ? `${row.company_name}, ${row.first_name} ${row.last_name}`
          : `${row.first_name} ${row.last_name}`;
        return (
          <Stack alignItems={"flex-start"}>
            <Link
              component={RouterLink}
              underline="none"
              to={generatePath("/customers/:uuid/details", { uuid })}
            >
              {userInfo}
            </Link>
          </Stack>
        );
      }
    },
    { field: "phone_number", headerName: "Phone #" },
    { field: "mobile_number", headerName: "Mobile #" },
    { field: "billing_address", headerName: "Billing Address", width: 500 },
    {
      field: "customer_group_id",
      headerName: "Customer Group",
      resizable: true,
      renderCell: params => {
        const {
          value: customerGroupName,
          row: { customer_group }
        } = params;

        if (customer_group) {
          return (
            <Link
              component={RouterLink}
              underline="none"
              to={generatePath("/customer-groups/:uuid/edit", {
                uuid: customer_group?.uuid
              })}
            >
              {customerGroupName}
            </Link>
          );
        }
      },
      valueGetter: (value, row) => row?.customer_group?.name,
      type: "singleSelect",
      valueOptions: formOptions?.customer_groups?.map((customerGroup: any) => {
        return {
          value: customerGroup.id,
          label: customerGroup.name
        };
      }),
      filterOperators: getGridSingleSelectMoreOperators()
    },

    {
      field: "customer_type",
      headerName: "Account Type",
      type: "singleSelect",
      valueOptions: ["cod", "account"],
      filterOperators: getGridSingleSelectMoreOperators()
    }
  ];

  return (
    <Card sx={{ height: "87vh" }}>
      <DataGridServerIndexBasedPaginated
        apiEndpoint="customers"
        autosizeOnMount={true}
        columns={columns}
        saveAndRestoreState
        slots={{
          toolbar: DataGridToolbar
        }}
        slotProps={{
          toolbar: {
            bulkDelete
          }
        }}
      />
    </Card>
  );
};

export default CustomersList;
