import React, { CSSProperties } from "react";
import { Grid, ListItem, Skeleton } from "@mui/material";

export const LoadableListItem = ({
  label,
  value,
  isLoading,
  selected,
  divider,
  labelStyle
}: {
  label: React.ReactNode;
  value?: React.ReactNode;
  isLoading?: boolean;
  selected?: boolean;
  divider?: boolean;
  labelStyle?: CSSProperties;
}) => {
  return (
    <ListItem divider={divider} selected={selected} disableGutters>
      <Grid
        container
        columns={2}
        spacing={2}
        justifyContent="space-between"
        direction="row"
        alignItems="center"
      >
        <Grid item xs style={labelStyle}>
          {label}
        </Grid>

        <Grid item xs="auto" style={labelStyle}>
          {isLoading ? <Skeleton variant="text" animation="wave" width={50} /> : value}
        </Grid>
      </Grid>
    </ListItem>
  );
};
