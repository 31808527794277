import {
  useMutationAddModel,
  useMutationBulkDeleteModelV2,
  useMutationDeleteModel,
  useMutationEditModel,
  useQueryModels,
  useQueryOneModel
} from "../hooks/useMutationFormAbstract";

export const QUERY_KEY = "account-group";

export const useQueryAccountGroups = () => useQueryModels(QUERY_KEY);

export const useQueryOneAccountGroup = (uuid?: string) => useQueryOneModel<any>(QUERY_KEY, uuid);

export const useMutationAddAccountGroup = (uuid: string) => useMutationAddModel(QUERY_KEY, uuid);

export const useMutationEditAccountGroup = (uuid: string) => useMutationEditModel(QUERY_KEY, uuid);

export const useMutationDeleteAccountGroup = () => useMutationDeleteModel(QUERY_KEY);

export const useMutationBulkDeleteAccountGroup = () => useMutationBulkDeleteModelV2(QUERY_KEY);
