import { useQueryFormOptionsModel } from "../hooks/useMutationFormAbstract";
import axios from "../utils/axios";
import { useQuery } from "@tanstack/react-query";

export const useQueryOneEvent = (position?: number) => {
  return useQuery({
    enabled: !!position,
    suspense: false,
    queryKey: ["evtstore", "one", position],
    queryFn: ({ signal }) =>
      axios
        .get(`/api/evtstore/${position}`, {
          signal
        })
        .then(({ data }) => data)
  });
};

export const useQueryEvtStoreAggregate = (stream?: string, uuid?: string, version?: number) => {
  return useQuery({
    enabled: !!stream && !!uuid,
    suspense: false,
    queryKey: ["evtstore/aggregate", { stream, uuid, version }],
    queryFn: ({ signal }) =>
      axios
        .get(`/api/evtstore/aggregate`, {
          signal,
          params: {
            stream,
            uuid,
            version
          }
        })
        .then(({ data }) => data)
  });
};

export const useQueryEvtStoreFormOptions = () =>
  useQueryFormOptionsModel<{
    streams: string[];
    users: any[];
  }>("evtstore");
