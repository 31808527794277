import React from "react";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Grid } from "@mui/material";
import styled from "@emotion/styled";
import { drawerWidth } from "../../layouts/DashboardV2.tsx";
import useGetPageTitle from "../../hooks/useGetPageTitle.ts";
import NavbarUserDropdown from "./NavbarUserDropdown.tsx";
import { DrawerHeader } from "../sidebar/DrawerHeader.tsx";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== "open"
})<AppBarProps>(({ theme, open }) => ({
  background: theme.header.background,
  color: theme.header.color,
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

export const NavbarV2 = ({
  openSidebar,
  handleSidebarToggle
}: {
  openSidebar: boolean;
  handleSidebarToggle: () => void;
}) => {
  const pageTitle = useGetPageTitle();

  return (
    <>
      <AppBar position="fixed" open={openSidebar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleSidebarToggle}
            edge="start"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>

          <Grid container alignItems="center">
            {pageTitle && (
              <Grid item>
                <Typography variant="h3" noWrap component="div">
                  {pageTitle}
                </Typography>
              </Grid>
            )}
            <Grid item xs />
            <Grid item>
              <NavbarUserDropdown />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      {/* as spacer only */}
      <DrawerHeader />
    </>
  );
};
