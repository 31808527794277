import {
  useMutationAbstract,
  useMutationAddModel,
  useMutationBulkDeleteModelV2,
  useMutationDeleteModel,
  useMutationEditModel,
  useQueryFormOptionsModel,
  useQueryModels,
  useQueryOneModel
} from "../hooks/useMutationFormAbstract";
import axios from "../utils/axios";
import { useQuery } from "@tanstack/react-query";

export const QUERY_KEY = "inventory-item";

export const useQueryInventoryItems = () => useQueryModels(QUERY_KEY);

export const useQueryInventoryItemsByProductID = (product_id?: number) =>
  useQuery({
    enabled: !!product_id,
    queryKey: [QUERY_KEY, "list-by-product-id", { product_id }],
    queryFn: ({ signal }) =>
      axios
        .get(`/api/${QUERY_KEY}/by-product-id/${product_id}`, {
          signal
        })
        .then(({ data }) => data)
  });

export const useQueryInventoryItemFormOptions = () =>
  useQueryFormOptionsModel<{ product_sold_by: any; product_lines: any }>(QUERY_KEY);

export const useQueryOneInventoryItem = (uuid?: string) => useQueryOneModel<any>(QUERY_KEY, uuid);

export const useMutationAddInventoryItem = (uuid: string) => useMutationAddModel(QUERY_KEY, uuid);

export const useMutationEditInventoryItem = (uuid: string) => useMutationEditModel(QUERY_KEY, uuid);

export const useMutationDeleteInventoryItem = () => useMutationDeleteModel(QUERY_KEY);

export const useMutationBulkDeleteInventoryItem = () => useMutationBulkDeleteModelV2(QUERY_KEY);

export const useMutationImportXlsxInventoryItemPriceGrid = (uuid: string) => {
  return useMutationAbstract({
    mutationKey: [QUERY_KEY],
    mutationFn: data =>
      axios.post(`/api/${QUERY_KEY}/${uuid}/import-price-grid`, data).then(({ data }) => data)
  });
};
