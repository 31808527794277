import LeadList from "./LeadList";
import LeadAdd from "./LeadAdd";
import LeadEdit from "./LeadEdit";
import LeadDetails from "./LeadDetails";
import CustomerEdit from "../customer/CustomerEdit";
import CustomerDetails from "../customer/CustomerDetails";
import async from "../../components/Async";

const LeadCalendar = async(() => import("./calendar/LeadCalendar"));

const routes = [
  {
    path: "/lead",
    element: <LeadList />
  },
  {
    path: "/lead/add",
    element: <LeadAdd />
  },
  {
    path: "/lead/:uuid/edit",
    element: <LeadEdit />
  },
  {
    path: "/lead/:uuid/details",
    element: <LeadDetails />
  },
  {
    path: "/lead-calendar",
    element: <LeadCalendar />,
    children: [
      {
        path: "event/:uuid/details",
        element: <LeadDetails />
      },
      {
        path: "event/:uuid/edit",
        element: <LeadEdit />
      },
      {
        path: "event/customers/:uuid/edit",
        element: <CustomerEdit />
      },
      {
        path: "event/customers/:uuid/details",
        element: <CustomerDetails />
      }
    ]
  }
];

export default routes;
