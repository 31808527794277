import React, { useMemo } from "react";
import { Button, Link, List, Menu, MenuItem, Stack, Typography } from "@mui/material";
import { QUERY_KEY, useQueryOneOrder } from "../../../api/Order";
import { generatePath, useParams } from "react-router-dom";
import useIsLoading from "../../../hooks/useIsLoading";
import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";
import { IconText } from "../../../components/IconText";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { useOrderMonetaryValues } from "../components/useOrderMonetaryValues";
import { LoadableListItem } from "../components/LoadableListItem";
import { toDecimalFromFloat } from "aldrin-erp-main-shared-functions";
import { bindMenu, bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AttachMoneySharpIcon from "@mui/icons-material/AttachMoneySharp";
import { v4 } from "uuid";
import {
  useMutationAddOrderTransactionCredit,
  useMutationAddOrderTransactionDebit
} from "../../../api/OrderTransaction";
import { useAuthAbility } from "../../../hooks/useAuthAbility";
import { TransactionActionsMenu } from "../components/TransactionActionsMenu";
import {
  saveTransactionFormDialog,
  TransactionFormDialogProps
} from "../components/SaveTransactionFormDialog";
import { formatISO } from "date-fns";

interface PaymentHistoryTableProps {
  disablePaymentButton?: boolean;
}

const TransactionsTable = ({ disablePaymentButton }: PaymentHistoryTableProps) => {
  const { uuid } = useParams<string>();
  const { data: order } = useQueryOneOrder(uuid);
  const isLoading = useIsLoading([QUERY_KEY]);

  const monetaryValues = useOrderMonetaryValues(order);
  const ability = useAuthAbility();

  const { mutateAsync: addTransactionDebit } = useMutationAddOrderTransactionDebit();
  const { mutateAsync: addTransactionCredit } = useMutationAddOrderTransactionCredit();

  // const quoteDepositPaymentInitiatedUsingCreditCard =
  //   order?.quote_deposit_payment_initiated_using === "credit-card" &&
  //   !!order?.quote_deposit_payment_initiated_at;

  const popupState = usePopupState({
    variant: "popover",
    popupId: `line-item-${uuid}`
  });

  const reversedTransactionsIds = useMemo(() => {
    return order?.transactions
      ?.filter(({ transaction_for }: any) => transaction_for === "reversal")
      ?.map(({ reference_transaction_id }: any) => reference_transaction_id, [])
      ?.filter(Boolean);
  }, [order?.transactions]);

  const handleClickAddTransaction = (props: TransactionFormDialogProps) => async () => {
    popupState.close();
    const values = await saveTransactionFormDialog(props);
    if (values === false) {
      return;
    }

    if (values.transaction_type === "debit") {
      await addTransactionDebit({
        ...values,
        order_uuid: uuid
      });
    } else if (values.transaction_type === "credit") {
      await addTransactionCredit({
        ...values,
        order_uuid: uuid
      });
    }
  };

  return (
    <>
      <Stack direction="row" flexWrap="wrap" justifyContent="space-between" alignItems="baseline">
        <Typography gutterBottom variant="h6">
          Transactions
        </Typography>

        {!disablePaymentButton && (
          <Button
            size="small"
            variant="outlined"
            startIcon={<AttachMoneySharpIcon />}
            endIcon={<MoreVertIcon />}
            {...bindTrigger(popupState)}
          >
            Add
          </Button>
        )}

        <Menu {...bindMenu(popupState)}>
          {!!order?.deposit_amount && (
            <MenuItem
              onClick={handleClickAddTransaction({
                title: "Deposit Payment",
                initial_values: {
                  uuid: v4(),
                  date: formatISO(new Date()),
                  transaction_type: "debit",
                  transaction_for: "deposit",
                  is_test_mode: Boolean(order?.is_test_mode),
                  amount: Number(monetaryValues.initial_deposit_amount)
                }
              })}
              disabled={
                ability.cannot("AddTransaction", "order") || !!order?.deposit_amount_paid_at
              }
            >
              Deposit Payment
            </MenuItem>
          )}

          {!!order?.pre_delivery_amount && (
            <MenuItem
              onClick={handleClickAddTransaction({
                title: "Pre-Delivery Payment",
                initial_values: {
                  uuid: v4(),
                  date: formatISO(new Date()),
                  transaction_type: "debit",
                  transaction_for: "pre-delivery",
                  is_test_mode: Boolean(order?.is_test_mode),
                  amount: Number(monetaryValues.initial_pre_delivery_amount)
                }
              })}
              divider={!order?.final_balance_amount}
              disabled={
                ability.cannot("AddTransaction", "order") || !!order?.pre_delivery_amount_paid_at
              }
            >
              Pre-Delivery Payment
            </MenuItem>
          )}

          {!!order?.final_balance_amount && (
            <MenuItem
              onClick={handleClickAddTransaction({
                title: "Final Payment",
                initial_values: {
                  uuid: v4(),
                  date: formatISO(new Date()),
                  transaction_type: "debit",
                  transaction_for: "final",
                  is_test_mode: Boolean(order?.is_test_mode),
                  amount: Number(monetaryValues.initial_final_balance_amount)
                }
              })}
              disabled={
                ability.cannot("AddTransaction", "order") || !!order?.final_balance_amount_paid_at
              }
            >
              Final Payment
            </MenuItem>
          )}

          {/*<MenuItem*/}
          {/*  onClick={handleClickAddTransaction({*/}
          {/*    uuid: v4(),*/}
          {/*    title: "Reversal",*/}
          {/*    transaction_type: "credit",*/}
          {/*    transaction_for: "reversal",*/}
          {/*    is_test_mode: Boolean(order?.is_test_mode)*/}
          {/*  })}*/}
          {/*  disabled={ability.cannot("AddTransactionCredit", "order")}*/}
          {/*>*/}
          {/*  Reversal*/}
          {/*</MenuItem>*/}
        </Menu>
      </Stack>

      <List dense>
        {!order?.transactions?.length && <span>No transactions</span>}

        {order?.transactions?.map((transaction: any) => {
          return (
            <LoadableListItem
              key={transaction.uuid}
              label={
                <Stack direction="row" gap={2}>
                  {transaction.status === "success" ? (
                    <Link
                      key={transaction.uuid}
                      underline="hover"
                      href={generatePath("/guest/order-transaction/:uuid/print-receipt", {
                        uuid: transaction.uuid
                      })}
                      target="_blank"
                    >
                      <IconText startIcon={<ReceiptLongIcon />}>
                        {transaction.transaction_for}: {transaction.reference_number}
                      </IconText>
                    </Link>
                  ) : transaction.status === "initiated" ? (
                    <span>
                      <IconText startIcon={<ReceiptLongIcon />}>
                        {transaction.transaction_for}: ({transaction.status})
                      </IconText>
                    </span>
                  ) : (
                    <span>
                      <IconText startIcon={<ReceiptLongIcon />}>
                        {transaction.transaction_for}: {transaction.reference_number} (
                        {transaction.status})
                      </IconText>
                    </span>
                  )}
                </Stack>
              }
              value={
                <Stack direction="row" gap={2} alignItems="center">
                  <Typography>{toDecimalFromFloat(transaction.amount)}</Typography>
                  {!disablePaymentButton && (
                    <TransactionActionsMenu
                      transaction={transaction}
                      order_uuid={order?.uuid}
                      is_reversed={reversedTransactionsIds.includes(transaction.id)}
                    />
                  )}
                </Stack>
              }
              divider
              isLoading={isLoading}
            />
          );
        })}
      </List>
    </>
  );
};

export default TransactionsTable;
