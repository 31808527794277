import { Dinero } from "dinero.js";
import { AUD } from "@dinero.js/currencies";
import { dineroAUD } from "./dineroAUD";

export const dineroAUDFromFloat = (float: number): Dinero<number> => {
  const currency = AUD;
  const scale = float.toString().split(".")[1]?.length ?? currency.exponent;

  const factor = (currency.base as number) ** scale;
  const amount = Math.round(float * factor);

  return dineroAUD(amount, scale);
};
