import {
  useMutationAddModel,
  useMutationBulkDeleteModelV2,
  useMutationDeleteModel,
  useMutationEditModel,
  useQueryModels,
  useQueryOneModel
} from "../hooks/useMutationFormAbstract";

export const QUERY_KEY = "order-status";

export const useQueryOrderStatuses = () => useQueryModels(QUERY_KEY);

export const useQueryOneOrderStatus = (uuid?: string) => useQueryOneModel<any>(QUERY_KEY, uuid);

export const useMutationAddOrderStatus = (uuid: string) => useMutationAddModel(QUERY_KEY, uuid);

export const useMutationEditOrderStatus = (uuid: string) => useMutationEditModel(QUERY_KEY, uuid);

export const useMutationDeleteOrderStatus = () => useMutationDeleteModel(QUERY_KEY);

export const useMutationBulkDeleteOrderStatus = () => useMutationBulkDeleteModelV2(QUERY_KEY);
