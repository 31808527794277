import React from "react";
import styled from "@emotion/styled";
import { v4 } from "uuid";
import { Divider as MuiDivider, Paper as MuiPaper } from "@mui/material";
import { spacing } from "@mui/system";
import { useMutationInviteUser } from "../../api/User";
import UserInviteForm from "./UserInviteForm";
import PageTitleWithLoading from "../../components/PageTitleWithLoading";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const UserInvite = () => {
  const mutation = useMutationInviteUser(v4());

  return (
    <Paper p={4}>
      <PageTitleWithLoading title="Invite User" />

      <Divider my={6} />

      <UserInviteForm mutation={mutation} />
    </Paper>
  );
};

export default UserInvite;
