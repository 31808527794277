import { useQuery } from "@tanstack/react-query";
import {
  useMutationAddModel,
  useMutationBulkDeleteModelV2,
  useMutationDeleteModel,
  useMutationEditModel,
  useQueryFormOptionsModel,
  useQueryModels,
  useQueryOneModel
} from "../hooks/useMutationFormAbstract";
import axios from "../utils/axios";

export const QUERY_KEY = "company";

export const useQueryCompanies = () => useQueryModels(QUERY_KEY);

export const useQueryOneCompany = (uuid?: string) => useQueryOneModel<any>(QUERY_KEY, uuid);

export const useQueryOneCompanyByCustomerGroupId = (customer_group_id?: number) => {
  return useQuery({
    enabled: !!customer_group_id,
    queryKey: [QUERY_KEY, "one-by-customer_group_id", customer_group_id],
    queryFn: ({ signal }) =>
      axios
        .get(`/api/${QUERY_KEY}/by-customer-group-id/${customer_group_id}`, {
          signal
        })
        .then(({ data }) => data),
    retry: 2
  });
};

export const useMutationAddCompany = (uuid: string) => useMutationAddModel(QUERY_KEY, uuid);

export const useMutationEditCompany = (uuid: string) => useMutationEditModel(QUERY_KEY, uuid);

export const useMutationDeleteCompany = () => useMutationDeleteModel(QUERY_KEY);

export const useMutationBulkDeleteCompany = () => useMutationBulkDeleteModelV2(QUERY_KEY);

export const useQueryCompanyFormOptions = () =>
  useQueryFormOptionsModel<{
    customer_groups: any;
  }>(QUERY_KEY);
