import ProductList from "./ProductList";
import ProductAdd from "./ProductAdd";
import ProductEdit from "./ProductEdit";
import ProductDetails from "./ProductDetails";

const routes = [
  {
    path: "/product",
    element: <ProductList />
  },
  {
    path: "/product/add",
    element: <ProductAdd />
  },
  {
    path: "/product/:uuid/edit",
    element: <ProductEdit />
  },
  {
    path: "/product/:uuid/details",
    element: <ProductDetails />
  }
];

export default routes;
