import CompanyList from "./CompanyList";
import CompanyAdd from "./CompanyAdd";
import CompanyEdit from "./CompanyEdit";

const routes = [
  {
    path: "/company",
    element: <CompanyList />
  },
  {
    path: "/company/add",
    element: <CompanyAdd />
  },
  {
    path: "/company/:uuid/edit",
    element: <CompanyEdit />
  }
];

export default routes;
