import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import * as Yup from "yup";
import { Formik } from "formik";

import { Alert as MuiAlert, Button, TextField as MuiTextField } from "@mui/material";
import { spacing } from "@mui/system";
import { useMutationUserSetPassword } from "../../api/User";
import { omit } from "lodash";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

function SetPassword() {
  const navigate = useNavigate();
  const { mutateAsync } = useMutationUserSetPassword();

  return (
    <Formik
      initialValues={{
        password: "",
        confirm_password: "",
        submit: false
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string()
          .required("Password is required")
          .min(6, "Your password is too short."),
        confirm_password: Yup.string()
          .required("Please retype your password.")
          .oneOf([Yup.ref("password")], "Passwords must match")
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await mutateAsync(omit(values, "submit"));
          navigate("/auth/sign-in");
        } catch (error: any) {
          const message = error.message || "Something went wrong";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={3} severity="warning">
              {errors.submit}
            </Alert>
          )}

          <TextField
            type="password"
            name="password"
            label="Password"
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />

          <TextField
            type="password"
            name="confirm_password"
            label="Confirm Password"
            value={values.confirm_password}
            error={Boolean(touched.confirm_password && errors.confirm_password)}
            fullWidth
            helperText={touched.confirm_password && errors.confirm_password}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Set Password
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default SetPassword;
