import { bindMenu, bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem
} from "@mui/material";
import { MoreVertical } from "react-feather";
import HistoryIcon from "@mui/icons-material/History";
import { saveTransactionFormDialog } from "./SaveTransactionFormDialog";
import { formatISO } from "date-fns";
import {
  useMutationAddOrderTransactionCredit,
  useQueryOrderTransactionFormOptions
} from "../../../api/OrderTransaction";
import InfoIcon from "@mui/icons-material/Info";
import { useState } from "react";
import async from "../../../components/Async";
import { Feature } from "use-feature";

const Code = async(() => import("../../../components/Code"));

export const TransactionActionsMenu: React.VFC<{
  transaction: any;
  order_uuid: string;
  is_reversed?: boolean;
}> = ({ transaction, order_uuid, is_reversed }) => {
  const { uuid } = transaction;
  const popupState = usePopupState({
    variant: "popover",
    popupId: `transaction-${uuid}`
  });

  const { mutateAsync: addTransactionCredit } = useMutationAddOrderTransactionCredit();
  const { data: formOptions } = useQueryOrderTransactionFormOptions();

  const [openInfoModal, setOpenInfoModal] = useState(false);

  const handleClickReverseTransaction = async () => {
    popupState.close();

    const {
      reference_number,
      is_test_mode,
      amount,
      payment_method_raw,
      payment_method_id,
      provider
    } = transaction;

    const values = await saveTransactionFormDialog({
      title: `Reversed #${reference_number}`,
      initial_values: {
        date: formatISO(new Date()),
        transaction_type: "credit",
        transaction_for: "reversal",
        is_test_mode: Boolean(is_test_mode),
        amount: -amount,
        reference_number: `Reversed #${reference_number}`,
        payment_method_raw,
        payment_method_uuid: formOptions?.payment_methods?.find(
          (payment: any) => payment.id === payment_method_id
        )?.uuid,
        reference_transaction_uuid: transaction?.uuid
      }
    });

    if (values === false) {
      return;
    }

    await addTransactionCredit({
      ...values,
      order_uuid,
      provider,
      payment_method_raw
    });
  };

  return (
    <>
      <IconButton size="small" disableFocusRipple edge="end" {...bindTrigger(popupState)}>
        <MoreVertical />
      </IconButton>
      <Menu {...bindMenu(popupState)}>
        <MenuItem
          onClick={handleClickReverseTransaction}
          disabled={is_reversed || transaction?.transaction_type !== "debit"}
        >
          <ListItemIcon>
            <HistoryIcon />
          </ListItemIcon>
          {is_reversed ? "Already Reversed" : "Reverse"}
        </MenuItem>

        <Feature name="FEATURE_DEBUG_ORDER">
          <MenuItem onClick={() => setOpenInfoModal(true)}>
            <ListItemIcon>
              <InfoIcon />
            </ListItemIcon>
            Info
          </MenuItem>
        </Feature>
      </Menu>

      <Dialog open={openInfoModal} onClose={() => setOpenInfoModal(false)} fullWidth maxWidth="lg">
        <DialogTitle>Transaction</DialogTitle>
        <DialogContent dividers>
          <Code>{JSON.stringify(transaction, null, "\t")}</Code>
        </DialogContent>
      </Dialog>
    </>
  );
};
