import { useContext } from "react";
import { LoadingBackdropContext } from "../contexts/LoadingBackdropContext.tsx";

const useLoadingBackdrop = () => {
  const context = useContext(LoadingBackdropContext);

  if (!context)
    throw new Error("LoadingBackdropContext must be placed within LoadingBackdropProvider");

  return context;
};

export default useLoadingBackdrop;
