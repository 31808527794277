import React from "react";
import styled from "@emotion/styled";
import { useQueryOnePagePublished } from "../../api/Page";
import { useParams } from "react-router-dom";
import { Card, CardContent, Divider as MuiDivider } from "@mui/material";
import { spacing } from "@mui/system";
import { Helmet } from "react-helmet-async";

const Divider = styled(MuiDivider)(spacing);

const PublishedPage = () => {
  const { slug } = useParams();
  const { data: pageData, isLoading } = useQueryOnePagePublished(slug);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const { title, content } = pageData;

  return (
    <Card>
      <Helmet title={title} />

      <CardContent>
        <h1>{title}</h1>
        <Divider my={2} />
        <p dangerouslySetInnerHTML={{ __html: content }} />
      </CardContent>
    </Card>
  );
};

export default PublishedPage;
