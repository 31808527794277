import React, { Suspense, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List
} from "@mui/material";
import { confirmable, ConfirmDialogProps } from "react-confirm";
import { useQueryOrderLineItemsByOrderId } from "../../api/OrderLineItem";
import { createConfirmation } from "../../components/ReactConfirmMountPoint";
import { uniq } from "lodash";
import Loader from "../../components/Loader";
import { AccordionOrderLineItem } from "./components/AccordionOrderLineItem";

export const OrderCopyDialog = confirmable(
  ({ show, proceed, order_id }: ConfirmDialogProps<{ order_id: number }, false | string[]>) => {
    const { data: orderLineItems, isLoading } = useQueryOrderLineItemsByOrderId(order_id, {
      suspense: false
    });

    const [selectLineItems, setSelectLineItems] = useState<string[]>([]);

    useEffect(() => {
      setSelectLineItems(orderLineItems?.map((item: any) => item.uuid) ?? []);
    }, [orderLineItems, setSelectLineItems]);

    const handleClose = () => proceed(false);

    return (
      <Dialog
        open={show}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Select line items</DialogTitle>
        <DialogContent>
          <Card>
            <CardContent>
              {isLoading && <Loader />}
              <Suspense fallback={<Loader />}>
                <List>
                  {orderLineItems?.map((order_line_item: any) => {
                    return (
                      <AccordionOrderLineItem
                        key={order_line_item.uuid}
                        order_line_item={order_line_item}
                        multi_select
                        checked={selectLineItems.includes(order_line_item.uuid)}
                        onChange={e => {
                          if (e.target.checked) {
                            setSelectLineItems(current =>
                              uniq([...current, order_line_item?.uuid])
                            );
                          } else {
                            setSelectLineItems(current =>
                              current.filter(uuid => uuid != order_line_item?.uuid)
                            );
                          }
                        }}
                      />
                    );
                  })}
                </List>
              </Suspense>
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={() => proceed(selectLineItems)} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

export const orderCopyConfirmationDialog = createConfirmation(OrderCopyDialog);
