import React from "react";
import { Formik } from "formik";
import {
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Grid,
  TextField as MuiTextField
} from "@mui/material";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { spacing, SpacingProps } from "@mui/system";
import { MutationResultType } from "../../hooks/useMutationFormAbstract";
import { QUERY_KEY, useQueryOneAccountGroup } from "../../api/AccountGroup";
import { Refresh as RefreshIcon } from "@mui/icons-material";
import useIsLoading from "../../hooks/useIsLoading";
import useHealthChecker from "../../hooks/useHealthChecker";
import useFormikSubmitHandler from "../../hooks/useFormikSubmitHandler";
import { Feature } from "use-feature";

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

const validationSchema = Yup.object().shape({
  name: Yup.string().required().max(255)
});

interface Values {
  name?: string;
}

const AccountGroupForm: React.VFC<{
  mutation: MutationResultType;
  uuid?: string;
}> = ({ mutation, uuid }) => {
  const { data } = useQueryOneAccountGroup(uuid);
  const isLoading = useIsLoading([QUERY_KEY]);
  const { isSuspendMutations } = useHealthChecker();

  const handleSubmit = useFormikSubmitHandler<Values>({
    mutation,
    navigate_to: "/account-group",
    validationSchema
  });

  return (
    <Formik
      initialValues={{
        name: "",
        ...data
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, touched, values, dirty, setValues }) => (
        <Card mb={6}>
          <CardContent>
            <form onSubmit={handleSubmit}>
              <TextField
                name="name"
                label="Name"
                value={values.name ?? ""}
                error={Boolean(touched.name && errors.name)}
                fullWidth
                helperText={touched.name && errors.name}
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                variant="outlined"
                my={2}
                disabled={isLoading}
              />

              <Grid container justifyContent="space-between">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  mt={3}
                  disabled={!dirty || isLoading || isSuspendMutations}
                >
                  Submit
                </Button>

                <Feature name="FEATURE_SEED_DATA">
                  <Button
                    variant="contained"
                    color="primary"
                    mt={3}
                    onClick={async () => {
                      const { faker } = await import("@faker-js/faker");

                      setValues(prevValues => ({
                        ...prevValues,
                        name: faker.random.words(2)
                      }));
                    }}
                  >
                    <RefreshIcon /> Seed Data
                  </Button>
                </Feature>
              </Grid>
            </form>
          </CardContent>
        </Card>
      )}
    </Formik>
  );
};

export default AccountGroupForm;
