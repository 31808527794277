import {
  useMutationAbstract,
  useMutationBulkDeleteModelV2,
  useMutationDeleteModel,
  useMutationEditModel,
  useQueryModels,
  useQueryOneModel
} from "../hooks/useMutationFormAbstract";
import axios from "../utils/axios";
import sleep from "../utils/sleep";
import * as Product from "../api/Product";

export const QUERY_KEY = "product-config";

export const useQueryProductConfigs = () => useQueryModels(QUERY_KEY);

export const useQueryOneProductConfig = (uuid?: string) => useQueryOneModel<any>(QUERY_KEY, uuid);

export const useMutationAddProductConfig = (uuid: string) => {
  return useMutationAbstract({
    invalidateQueryKey: [Product.QUERY_KEY],
    mutationKey: [QUERY_KEY],
    mutationFn: data =>
      axios
        .post(`/api/${QUERY_KEY}`, {
          ...data,
          uuid
        })
        .then(({ data }) => data)
        .then(async response => {
          // we have to do it this way to wait for event handlers to process changes
          await sleep(1500);

          return response;
        })
  });
};

export const useMutationEditProductConfig = (uuid: string) => useMutationEditModel(QUERY_KEY, uuid);

export const useMutationDeleteProductConfig = () => useMutationDeleteModel(QUERY_KEY);

export const useMutationBulkDeleteProductConfig = () => useMutationBulkDeleteModelV2(QUERY_KEY);
