import React from "react";
import { CircularProgress as MuiCircularProgress, Typography } from "@mui/material";
import { useIsFetching, useIsMutating } from "@tanstack/react-query";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { Helmet } from "react-helmet-async";

const CircularProgress = styled(MuiCircularProgress)(spacing);
const TitleContainer = styled.div(spacing);

const PageTitleWithLoading: React.VFC<{ title: string }> = ({ title }) => {
  const isFetching = useIsFetching();
  const isMutating = useIsMutating();
  const isLoading = isFetching || isMutating;

  return (
    <>
      <Helmet title={title} />
      <TitleContainer
        mx={2}
        pt={3}
        style={{
          display: "flex",
          flexDirection: "row"
        }}
      >
        <Typography variant="h3">{title}</Typography>
        {!!isLoading && <CircularProgress mx={2} size={25} />}
      </TitleContainer>
    </>
  );
};

export default PageTitleWithLoading;
