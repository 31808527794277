import React from "react";
import styled from "@emotion/styled";
import { Divider as MuiDivider, Paper as MuiPaper } from "@mui/material";
import { spacing } from "@mui/system";
import PageForm from "./PageForm";
import { useMutationEditPage } from "../../api/Page";
import PageTitleWithLoading from "../../components/PageTitleWithLoading";
import { useParams } from "react-router-dom";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const PageEdit = () => {
  const { uuid } = useParams<string>();
  const mutation = useMutationEditPage(uuid ?? "");

  return (
    <Paper p={4}>
      <PageTitleWithLoading title="Edit Page" /> {/* Updated title to match the form purpose */}
      <Divider my={6} />
      <PageForm mutation={mutation} uuid={uuid} />
    </Paper>
  );
};

export default PageEdit;
