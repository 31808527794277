import { useCallback } from "react";
import { Channel, PresenceChannel } from "pusher-js";

export function useEventHandledPromise<T>(channel?: Channel & PresenceChannel) {
  return useCallback(
    (uuid: string, data: any) =>
      new Promise<T>(resolve => {
        channel?.bind("handled", (meta: any) => {
          if (meta?.aggregate_id === uuid) {
            channel?.unbind("handled");
            resolve(data);
          }
        });
      }),
    [channel]
  );
}
