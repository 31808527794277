import React from "react";
import { Formik } from "formik";
import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Chip,
  FormControl as MuiFormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField as MuiTextField
} from "@mui/material";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { spacing, SpacingProps } from "@mui/system";
import { MutationResultType } from "../../hooks/useMutationFormAbstract";
import { useQueryOneUser, useQueryUserFormOptions } from "../../api/User";
import useIsLoading from "../../hooks/useIsLoading";
import useHealthChecker from "../../hooks/useHealthChecker";
import useFormikSubmitHandler from "../../hooks/useFormikSubmitHandler";

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const FormControl = styled(MuiFormControl)(spacing);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

interface ButtonProps extends SpacingProps {
  component?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

type Values = {
  roles?: string[];
  first_name?: string;
  last_name?: string;
  phone_number?: string;
};

const UserForm: React.VFC<{
  mutation: MutationResultType;
  uuid?: string;
}> = ({ mutation, uuid }) => {
  const { data: formOptionsData } = useQueryUserFormOptions();
  const { data } = useQueryOneUser(uuid);
  const isLoading = useIsLoading(["users"]);
  const { isSuspendMutations } = useHealthChecker();
  // const { checkRolesAccess, user } = useAuth();

  const validationSchema = Yup.object().shape({
    roles: Yup.array(Yup.string()).min(1).required(),
    first_name: Yup.string().trim().min(2).max(255).optional().nullable(),
    last_name: Yup.string().trim().min(2).max(255).optional().nullable(),
    phone_number: Yup.string().trim().min(2).max(255).optional().nullable()
  });

  const handleSubmit = useFormikSubmitHandler<Values>({
    mutation,
    navigate_to: "/users",
    validationSchema
  });

  return (
    <Formik initialValues={data} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ errors, handleBlur, handleChange, handleSubmit, touched, values, dirty }) => (
        <Card mb={6}>
          <CardContent>
            <form onSubmit={handleSubmit}>
              <FormControl fullWidth error={Boolean(touched.roles && errors.roles)} my={2}>
                <InputLabel id="demo-multiple-chip-label">Roles</InputLabel>
                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  name="roles"
                  value={values.roles ?? []}
                  onChange={handleChange}
                  error={Boolean(touched.roles && errors.roles)}
                  input={<OutlinedInput id="select-multiple-chip" label="Roles" />}
                  renderValue={selected => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map(value => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                  disabled={isLoading}
                >
                  {formOptionsData?.roles.map((name: string) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                name="first_name"
                label="First Name"
                value={values.first_name ?? ""}
                error={Boolean(touched.first_name && errors.first_name)}
                fullWidth
                helperText={touched.first_name && errors.first_name}
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                variant="outlined"
                my={2}
                disabled={isLoading}
              />

              <TextField
                name="last_name"
                label="Last Name"
                value={values.last_name ?? ""}
                error={Boolean(touched.last_name && errors.last_name)}
                fullWidth
                helperText={touched.last_name && errors.last_name}
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                variant="outlined"
                my={2}
                disabled={isLoading}
              />

              <TextField
                name="phone_number"
                label="Phone Number"
                value={values.phone_number ?? ""}
                error={Boolean(touched.phone_number && errors.phone_number)}
                fullWidth
                helperText={touched.phone_number && errors.phone_number}
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                variant="outlined"
                my={2}
                disabled={isLoading}
              />

              <Button
                type="submit"
                variant="contained"
                color="primary"
                mt={3}
                disabled={!dirty || isLoading || isSuspendMutations}
              >
                Submit
              </Button>
            </form>
          </CardContent>
        </Card>
      )}
    </Formik>
  );
};

export default UserForm;
