import React from "react";
import styled from "@emotion/styled";
import { v4 } from "uuid";
import { Divider as MuiDivider, Paper as MuiPaper } from "@mui/material";
import { spacing } from "@mui/system";
import CustomerGroupForm from "./CustomerGroupForm";
import { useMutationAddCustomerGroup } from "../../api/CustomerGroup";
import PageTitleWithLoading from "../../components/PageTitleWithLoading";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const CustomerGroupAdd = () => {
  const mutation = useMutationAddCustomerGroup(v4());

  return (
    <Paper p={4}>
      <PageTitleWithLoading title="Add Customer Group" />

      <Divider my={6} />

      <CustomerGroupForm mutation={mutation} />
    </Paper>
  );
};

export default CustomerGroupAdd;
