import React from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Chip,
  FormHelperText,
  Stack,
  Typography
} from "@mui/material";
import { SimpleTreeView, TreeItem } from "@mui/x-tree-view";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { chain } from "lodash";
import { useField } from "formik";

interface RenderTree {
  id: string;
  uuid?: string;
  description: string;
  code?: string;
  is_qbcc?: boolean;
  is_check_measure?: boolean;
  children?: readonly RenderTree[];
}

const SelectInventoryItemsTreeView: React.FunctionComponent<{
  inventory_items?: any;
  name: string;
}> = ({ inventory_items, name }) => {
  const [field, meta, helpers] = useField(name);
  const selected: string[] = field.value;
  const [expanded, setExpanded] = React.useState<string[]>([]);
  const [groupBy, setGroupBy] = React.useState<string>("product_line.name");

  const handleToggle = (event: React.SyntheticEvent, itemIds: string[]) => {
    setExpanded(itemIds);
  };

  const handleSelectGroupByProductLine = () => setGroupBy("product_line.name");
  const handleSelectGroupByProductSoldBy = () => setGroupBy("product_sold_by.name");
  const handleSelectGroupBySelected = () => setGroupBy("selected");

  const _treeNodes = chain(inventory_items ?? [])
    .map(item => ({
      ...item,
      selected: selected?.includes(item.uuid) ? "Selected" : "Not Selected"
    }))
    .groupBy(groupBy)
    .mapValues((inventoryItems, groupByName) => {
      return {
        id: groupByName,
        description: groupByName,
        children: inventoryItems
      };
    });

  const rootIds = _treeNodes.keys().value();

  const handleSelect = (event: React.SyntheticEvent, itemIds: string[]) => {
    if (!itemIds.filter(itemId => !rootIds.includes(itemId)).length) {
      return;
    }

    helpers.setValue(itemIds);
  };

  const treeNodes = _treeNodes.values().value();

  const selectedNonRoot = selected.filter(val => !rootIds.includes(val));

  const handleExpandClick = () => {
    setExpanded(oldExpanded => (oldExpanded.length === 0 ? rootIds : []));
  };

  const handleUnselectAll = () => {
    helpers.setValue([]);
  };

  const renderTree = (nodes: RenderTree[]) => {
    return nodes?.map(node => {
      const label = node.code ? `[${node.code}] - ${node.description}` : node.description;
      return (
        <TreeItem
          key={node.id}
          itemId={node.uuid || node.id}
          label={
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                py: 2,
                pr: 0
              }}
            >
              {!!node.uuid && <Checkbox checked={selected.includes(node.uuid)} size="small" />}
              <Typography variant="body2" sx={{ fontWeight: "inherit", flexGrow: 1 }}>
                {label}
              </Typography>

              <Stack direction="row" spacing={2}>
                {node?.is_qbcc && <Chip label="QBCC" size="small" />}
                {node?.is_check_measure && <Chip label="Check Measure" size="small" />}
              </Stack>
            </Box>
          }
        >
          {Array.isArray(node.children) ? renderTree(node.children) : null}
        </TreeItem>
      );
    });
  };

  return (
    <Box>
      <ButtonGroup
        variant="outlined"
        aria-label="outlined primary button group"
        sx={{ mb: 3, flexGrow: 1, justifyContent: "center" }}
        size="small"
      >
        <Button onClick={handleExpandClick}>
          {expanded.length === 0 ? "Expand all" : "Collapse all"}
        </Button>
        <Button onClick={handleSelectGroupByProductLine}>Group by Product Line</Button>
        <Button onClick={handleSelectGroupByProductSoldBy}>Group by Product Sold by</Button>
        <Button onClick={handleSelectGroupBySelected}>Group by Selected</Button>
        {selectedNonRoot.length > 0 && (
          <Button onClick={handleUnselectAll}>Unselect all({selectedNonRoot.length})</Button>
        )}
      </ButtonGroup>

      <Box sx={{ minHeight: 270, flexGrow: 1 }}>
        <SimpleTreeView
          aria-label="controlled"
          slots={{ expandIcon: ChevronRightIcon, collapseIcon: ExpandMoreIcon }}
          expandedItems={expanded}
          selectedItems={selected}
          onExpandedItemsChange={handleToggle}
          onSelectedItemsChange={handleSelect}
          multiSelect
        >
          {renderTree(treeNodes)}
        </SimpleTreeView>

        <FormHelperText error>{meta.error}</FormHelperText>
      </Box>
    </Box>
  );
};

export default SelectInventoryItemsTreeView;
