import {
  gridFilterModelSelector,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  useGridApiContext,
  useGridSelector
} from "@mui/x-data-grid-pro";
import { Suspense, useDeferredValue } from "react";
import { FormControl, InputLabel, Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useQueryOrderReceivableFormOptions } from "../../api/OrderReceivable";
import { ToolbarStatistics } from "./ToolbarStatistics";
import Loader from "../../components/Loader";

export const DataGridToolbar = () => {
  const apiRef = useGridApiContext();

  const filterModel = useGridSelector(apiRef, gridFilterModelSelector);

  const filterItemCustomerGroup = filterModel?.items?.find(
    item => item.field === "customer.customer_group.id" && item.operator === "is"
  );
  const deferredFilterCustomerGroup = useDeferredValue(filterItemCustomerGroup?.value ?? null);

  const { data: formOptions } = useQueryOrderReceivableFormOptions();

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="flex-end"
      flexWrap="wrap"
      gap={3}
      m={3}
      my={4}
    >
      <Stack gap={3} direction="row" flexWrap="wrap" flexGrow={1}>
        <Suspense fallback={<Loader />}>
          <ToolbarStatistics />
        </Suspense>
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        gap={3}
      >
        <FormControl size="small">
          <InputLabel
            id="filter-customer-group-label"
            shrink
            margin="dense"
            sx={{ backgroundColor: "white", padding: 0.5 }}
          >
            Customer Group
          </InputLabel>
          <ToggleButtonGroup
            value={deferredFilterCustomerGroup}
            size="small"
            exclusive
            onChange={(e, value) => {
              if (value === null && filterItemCustomerGroup) {
                apiRef.current.deleteFilterItem(filterItemCustomerGroup);
              } else {
                apiRef.current.upsertFilterItem({
                  field: "customer.customer_group.id",
                  value,
                  id: "customer_group",
                  operator: "is"
                });
              }
            }}
          >
            {formOptions?.customer_groups?.map((customerGroup: any) => {
              return (
                <ToggleButton key={customerGroup.id} value={customerGroup.id}>
                  {customerGroup.name}
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        </FormControl>

        <GridToolbarContainer>
          <GridToolbarQuickFilter placeholder="Order #" />
          <GridToolbarFilterButton />
        </GridToolbarContainer>
      </Stack>
    </Stack>
  );
};
