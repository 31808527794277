import React, { useEffect, useMemo, useState } from "react";
import useIsLoading from "../../../hooks/useIsLoading";
import useHealthChecker from "../../../hooks/useHealthChecker";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  FormHelperText,
  Stack,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography
} from "@mui/material";
import { generatePath, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { Save as SaveIcon } from "@mui/icons-material";
import confirm from "../../../components/Confirm";
import {
  QUERY_KEY,
  useMutationCopyOrderLineItem,
  useQueryOneOrderLineItem,
  useQueryOrderLineItemFormOptions
} from "../../../api/OrderLineItem";
import { QUERY_KEY as ORDER_QUERY_KEY, useQueryOneOrder } from "../../../api/Order";
import * as Yup from "yup";
import useFormikSubmitHandler from "../../../hooks/useFormikSubmitHandler";
import { ErrorMessage, FieldArray, FormikProvider, useFormik } from "formik";
import { FormStep2OutletContext } from "./FormStep2";
import { useQueryParam } from "use-query-params";
import { useQueryClient } from "@tanstack/react-query";
import Heading from "./OrderLineItemOptionTypes/Heading";
import DropdownList from "./OrderLineItemOptionTypes/DropdownList";
import OptionTypeTextField from "./OrderLineItemOptionTypes/TextField";
import IntegerInput from "./OrderLineItemOptionTypes/IntegerInput";
import DecimalInput from "./OrderLineItemOptionTypes/DecimalInput";
import DiscountInput from "./OrderLineItemOptionTypes/DiscountInput";
import NotesInput from "./OrderLineItemOptionTypes/NotesInput";
import { isEmpty, keyBy, mapValues, pickBy } from "lodash";
import IntegerRangeInput from "./OrderLineItemOptionTypes/IntegerRangeInput";
import { PricingBreakdownTable } from "../components/PricingBreakdownTable";
import { useQueryOneCustomer } from "../../../api/Customer";
import { Feature } from "use-feature";
import async from "../../../components/Async";
import styled from "@emotion/styled";
import { useOrderLinePriceConfig } from "../components/useOrderLinePriceConfig.ts";

export interface RenderConfigProps {
  conf: any;
  index: number;
  configuration_options: object[];
}

interface Values {
  order_uuid?: string;
  product_uuid?: string;
  config_values?: any;
  product_config_uuid?: any;
}

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 1200
  }
});

const Code = async(() => import("../../../components/Code"));

const OrderLineItemForm = () => {
  const { order_uuid, navigateToStep, product_uuid, mutationOrderLineItem, product } =
    useOutletContext<FormStep2OutletContext>();

  const { data: order } = useQueryOneOrder(order_uuid);
  const { data: customer } = useQueryOneCustomer(order?.customer_uuid);

  const { order_line_item_uuid } = useParams();
  const isEditing = !!order_line_item_uuid;
  const { data: orderLineItem } = useQueryOneOrderLineItem(order_line_item_uuid);
  const selectedProduct = orderLineItem?.product ?? product;
  const { data: formOptions } = useQueryOrderLineItemFormOptions();
  const isLoading = useIsLoading([QUERY_KEY]);
  const { isSuspendMutations } = useHealthChecker();
  const [, setProductUUID] = useQueryParam("product_uuid");
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [copyAfterSubmit, setCopyAfterSubmit] = useState(false);
  const { mutateAsync: copyOrderLineItem } = useMutationCopyOrderLineItem();

  const configuration_options = useMemo(
    () => orderLineItem?.product_config?.config ?? selectedProduct?.product_config_active?.config,
    [orderLineItem, selectedProduct]
  );

  const markupPercentageFromCustomerGroup = useMemo(() => {
    if (orderLineItem) {
      return orderLineItem.markup_percentage;
    }

    const markup_percentage_mapping = keyBy(
      selectedProduct?.customer_groups_markup_percentage,
      "customer_group_uuid"
    );

    return markup_percentage_mapping?.[customer?.customer_group_uuid]?.markup_percentage;
  }, [selectedProduct, customer, orderLineItem]);

  const optionTypesValidationSchema = (conf: any) => {
    // return Yup.string().label(conf["Question Heading"]).required();
    // https://buzsoftware.atlassian.net/wiki/spaces/HELP/pages/679182427/Group+Options+-+Question+type#Setup
    // const assertExpectedOptionTypes = ['L', '$', 'R', '&', '#', 'D', 'N', 'H', 'F'];
    // console.log(arrayHelpers.form.values);
    const getBaseSchema = (conf: any) => {
      switch (conf["Option Type"]) {
        case "L":
          return Yup.mixed(); // TODO: Change to proper Yup.object().shape()...
        case "$":
        case "N":
          return Yup.string();
        case "&":
        case "D":
          return Yup.number()
            .integer()
            .transform(value => (isNaN(value) ? undefined : value));
        case "R": {
          const { error } = conf["Answer Options"];
          if (!error) {
            return Yup.number().integer();
          }

          return Yup.number().integer().min(error.min, error.msg).max(error.max, error.msg);
        }
        case "#":
          return Yup.number();
        // case "F":
        //   return <FileUpload {...props} />;
        default:
          return Yup.mixed();
      }
    };

    const schema = getBaseSchema(conf).label(conf["Question Heading"]);

    // if (conf["Is Required"]) {
    //   schema = schema.required();
    // }

    return schema;
  };

  const validationSchema = Yup.object().shape({
    order_uuid: Yup.string().uuid().required().label("Order"),
    product_uuid: Yup.string()
      .uuid()
      .required("Please select one Product")
      .label("Product")
      .nullable()
      .oneOf(formOptions?.products?.map(({ uuid }: any) => uuid) ?? []),
    config_values: Yup.object().shape(
      mapValues(
        keyBy(
          orderLineItem?.product_config?.config ?? selectedProduct?.product_config_active?.config,
          "Unique Option Name"
        ),
        conf => optionTypesValidationSchema(conf)
      )
    ),
    product_config_uuid: Yup.string().uuid().required()
  });

  const handleSubmit = useFormikSubmitHandler<Values>({
    mutation: mutationOrderLineItem,
    validationSchema,
    mutateOptions: {
      onSuccess: async response => {
        if (copyAfterSubmit) {
          const copyResponse = await copyOrderLineItem(response.uuid);

          navigate(
            generatePath("/order/:order_uuid/edit/step-3/line-item/:uuid", {
              order_uuid,
              uuid: copyResponse.copied_uuid
            })
          );
        }
      }
    }
  });

  useEffect(() => {
    window.scrollTo({
      top: 50,
      behavior: "smooth"
    });
  }, [selectedProduct, order_line_item_uuid]);

  const formik = useFormik<Values>({
    initialValues: {
      order_uuid,
      product_uuid: orderLineItem?.product?.uuid ?? product_uuid,
      ...orderLineItem,
      product_config_uuid:
        orderLineItem?.product_config?.uuid ?? selectedProduct?.product_config_active?.uuid
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, formikHelpers) => {
      await handleSubmit(values, formikHelpers);
      formikHelpers.resetForm();
      setProductUUID(undefined);
      window.scrollTo({
        top: 50,
        behavior: "smooth"
      });
      await queryClient.invalidateQueries([ORDER_QUERY_KEY, "one"]);
    }
  });

  const { values, dirty } = formik;

  const renderConfiguration = (props: RenderConfigProps) => {
    // eslint-disable-next-line react/prop-types
    const { conf } = props;

    // https://buzsoftware.atlassian.net/wiki/spaces/HELP/pages/679182427/Group+Options+-+Question+type#Setup
    // const assertExpectedOptionTypes = ['L', '$', 'R', '&', '#', 'D', 'N', 'H', 'F'];
    // console.log(arrayHelpers.form.values);
    // eslint-disable-next-line react/prop-types
    switch (conf["Option Type"]) {
      case "H":
        return <Heading {...props} />;
      case "L":
        return <DropdownList {...props} />;
      case "$":
        return <OptionTypeTextField {...props} />;
      case "&":
        return <IntegerInput {...props} />;
      case "R":
        return <IntegerRangeInput {...props} />;
      case "#":
        return <DecimalInput {...props} />;
      case "D":
        return <DiscountInput {...props} />;
      case "N":
        return <NotesInput {...props} />;
      // case "F": // TODO: File Upload input
      //   return <FileUpload {...props} />;
    }
  };

  const optionTypesInputs = useMemo(() => {
    return configuration_options?.map((conf: any, index: number) => (
      <Stack
        key={conf["Unique Option Name"]}
        direction="row"
        spacing={2}
        mb={6}
        justifyContent="space-between"
      >
        {renderConfiguration({
          conf,
          index,
          configuration_options
        })}

        <Feature name="FEATURE_DEBUG_ORDER">
          <Stack direction="row" spacing={2}>
            <Chip label={conf["Unique Option Name"]} size="small" />
            <CustomWidthTooltip title={<Code>{JSON.stringify(conf, null, "\t")}</Code>}>
              <Chip label={"info"} size="small" />
            </CustomWidthTooltip>
          </Stack>
        </Feature>
      </Stack>
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configuration_options]);

  const priceConfig = useOrderLinePriceConfig({
    product_id: selectedProduct?.id,
    markup_percentage: markupPercentageFromCustomerGroup,
    config_values: values?.config_values,
    config_options: configuration_options
  });

  const notAvailableItems = useMemo(
    () => pickBy(priceConfig?.config, conf => !conf.isAvailable),
    [priceConfig]
  );

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <ErrorMessage name="product_uuid">
          {msg => (
            <Typography variant="caption" color="error">
              {msg}
            </Typography>
          )}
        </ErrorMessage>

        <FieldArray name="config_values" render={() => optionTypesInputs} />

        {selectedProduct ? (
          <Box sx={{ overflow: "auto", width: "100%" }}>
            <PricingBreakdownTable
              product_id={selectedProduct?.id}
              markup_percentage={markupPercentageFromCustomerGroup}
              config_values={values?.config_values}
              config_options={configuration_options}
            />
          </Box>
        ) : (
          <Card variant="outlined">
            <CardContent>
              <Typography sx={{ fontSize: 14 }} color="text.secondary">
                Select a product to configure
              </Typography>
            </CardContent>
          </Card>
        )}

        {Object.values(notAvailableItems)?.map(itemConf => {
          return (
            <FormHelperText error key={itemConf.code}>
              {itemConf.label}: {itemConf.code} is not available
            </FormHelperText>
          );
        })}

        <Stack direction="row" justifyContent="space-between" spacing={3} mt={3}>
          <div>
            <Button
              variant="outlined"
              onClick={async () => {
                const proceed =
                  !dirty ||
                  (await confirm({
                    confirmation: "You are about to discard the changes. Are you sure?",
                    options: {
                      title: "Confirm"
                    }
                  }));

                if (proceed) {
                  if (order?.order_line_items?.length > 0) {
                    navigateToStep("step-3", order_uuid);
                  } else {
                    navigate("/order/add/step-1");
                  }
                }
              }}
            >
              Cancel
            </Button>
          </div>

          <Stack direction="row" justifyContent="space-between" spacing={3} mt={3}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!isEmpty(notAvailableItems) || !dirty || isLoading || isSuspendMutations}
              startIcon={<SaveIcon />}
              onClick={() => {
                setCopyAfterSubmit(false);
              }}
            >
              {isEditing ? "Save" : "Add to Cart"}
            </Button>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!isEmpty(notAvailableItems) || !dirty || isLoading || isSuspendMutations}
              startIcon={<SaveIcon />}
              onClick={() => {
                setCopyAfterSubmit(true);
              }}
            >
              {isEditing ? "Save & Copy" : "Add & Copy"}
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={async () => {
                const proceed =
                  !dirty ||
                  (await confirm({
                    confirmation: "There are unsaved changes. Are you sure?",
                    options: {
                      title: "Confirm"
                    }
                  }));

                if (proceed) {
                  navigate(
                    generatePath("/order/:order_uuid/edit/step-3", {
                      order_uuid
                    })
                  );
                }
              }}
            >
              View Cart
            </Button>

            {/*{import.meta.env.DEV && (*/}
            {/*  <Button*/}
            {/*    variant="contained"*/}
            {/*    color="primary"*/}
            {/*    onClick={async () => {*/}
            {/*      const { faker } = await import("@faker-js/faker");*/}

            {/*      setValues((prevValues: any) => ({*/}
            {/*        ...prevValues*/}
            {/*      }));*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    <RefreshIcon /> Seed Data*/}
            {/*  </Button>*/}
            {/*)}*/}
          </Stack>
        </Stack>
      </form>
    </FormikProvider>
  );
};

export default OrderLineItemForm;
