import React, { useState } from "react";
import { Formik } from "formik";
import {
  Alert,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Checkbox,
  createFilterOptions,
  Dialog,
  FormControlLabel,
  FormHelperText,
  Grid,
  TextField as MuiTextField
} from "@mui/material";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { spacing, SpacingProps } from "@mui/system";
import { MutationResultType } from "../../hooks/useMutationFormAbstract";
import { Refresh as RefreshIcon } from "@mui/icons-material";
import { useQueryCustomerFormOptions, useQueryOneCustomer } from "../../api/Customer";
import GooglePlacesInput from "../../components/GooglePlacesInput";
import AutocompleteField from "../../components/AutocompleteField";
import qs from "qs";
import useIsLoading from "../../hooks/useIsLoading";
import useHealthChecker from "../../hooks/useHealthChecker";
import useFormikSubmitHandler from "../../hooks/useFormikSubmitHandler";
import { useNavigate } from "react-router-dom";
import { useQueryParam, withDefault } from "use-query-params";
import { StringParam } from "serialize-query-params";
import useAuth from "../../hooks/useAuth";
import { USER_ROLES } from "../../constants";
import { isEmailValid } from "@sideway/address";
import { Feature } from "use-feature";

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

interface Values {
  email?: string;
  first_name?: string;
  last_name?: string;
  phone_number?: string;
  mobile_number?: string;
  billing_address?: string;
  company_name?: string;
  warning_text?: string;
  customer_group_uuid?: string;
  is_tester?: boolean;
  customer_type?: string;
  account_limit?: number;
}

const CustomerForm: React.VFC<{
  mutation: MutationResultType;
  uuid?: string;
  isEdit: boolean;
}> = ({ mutation, uuid, isEdit }) => {
  const { data: formOptions } = useQueryCustomerFormOptions();
  const [openFormDialog, setOpenFormDialog] = useState<JSX.Element>();
  const { data } = useQueryOneCustomer(uuid, {
    enabled: !!uuid && isEdit
  });
  const isLoading = useIsLoading(["customers"]);
  const { isSuspendMutations } = useHealthChecker();
  const [createLeadAfterSubmit, setCreateLeadAfterSubmit] = useState<boolean>(false);
  const navigate = useNavigate();
  const { checkRolesAccess } = useAuth();

  const existingCustomerEmails = (formOptions?.existing_customer_emails ?? []).filter(
    (email: string) => email !== data?.email
  );

  const customerGroupOptions =
    formOptions?.customer_groups?.map((customerGroup: any) => {
      return {
        label: customerGroup.name,
        id: customerGroup.uuid
      };
    }) ?? [];

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email()
      .required("Required")
      .max(255)
      .test("is-valid-email", "${path} is not a valid email", value => isEmailValid(value))
      .notOneOf(existingCustomerEmails, "Email already used"),
    first_name: Yup.string()
      .matches(/^[a-zA-Z]+$/, "It should be letters only without spaces")
      .max(255)
      .required(),
    last_name: Yup.string()
      .matches(/^[a-zA-Z]+$/, "It should be letters only without spaces")
      .max(255)
      .required(),
    phone_number: Yup.string().matches(/^\d+$/, "It should be numbers only").max(255).required(),
    mobile_number: Yup.string()
      .optional()
      .matches(/^\d{10}$/, "It should be exactly 10 numbers only"), // regex: means only numbers without whitespaces
    billing_address: Yup.string().max(255).optional(),
    company_name: Yup.string().max(255).optional(),
    customer_group_uuid: Yup.string()
      .uuid()
      .required()
      .label("Customer Group")
      .nullable()
      .oneOf(customerGroupOptions.map(({ id }: any) => id)),
    warning_text: Yup.string().optional().nullable(),
    is_tester: Yup.boolean().optional().nullable(),
    customer_type: Yup.string()
      .max(255)
      .required()
      .label("Customer Type")
      .oneOf(["cod", "account"]),
    account_limit: Yup.number()
      .optional()
      .nullable()
      .transform(v => (isNaN(v) ? null : v))
  });

  const [navigateToUrl] = useQueryParam("navigate_to", withDefault(StringParam, "/customers"));

  const handleSubmit = useFormikSubmitHandler<Values>({
    mutation,
    validationSchema,
    mutateOptions: {
      onSuccess: () => {
        if (createLeadAfterSubmit) {
          navigate(
            `/lead/add?${qs.stringify({
              initialValues: {
                customer_uuid: uuid
              },
              navigate_to: `/customers/${uuid}/details`
            })}`
          );
        } else {
          navigate(navigateToUrl);
        }
      }
    }
  });

  return (
    <Formik
      initialValues={{
        email: "",
        first_name: "",
        last_name: "",
        phone_number: "",
        mobile_number: "",
        billing_address: "",
        company_name: "",
        customer_group_uuid: "",
        warning_text: "",
        is_tester: false,
        customer_type: "cod",
        account_limit: "",
        ...data
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        dirty,
        setValues,
        submitForm
      }) => (
        <Card mb={6}>
          <CardContent>
            <form onSubmit={handleSubmit}>
              {Boolean(values.is_tester) && (
                <Alert severity="warning">
                  Payments related to this customer will be marked as TEST mode
                </Alert>
              )}

              <AutocompleteField
                label="Email"
                name="email"
                filterOptions={createFilterOptions({
                  matchFrom: "start"
                })}
                options={existingCustomerEmails}
                fullWidth
                freeSolo
                disabled={isLoading}
              />

              <TextField
                name="first_name"
                label="First Name"
                value={values.first_name ?? ""}
                error={Boolean(touched.first_name && errors.first_name)}
                fullWidth
                helperText={touched.first_name && errors.first_name}
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                variant="outlined"
                my={2}
                disabled={isLoading}
              />

              <TextField
                name="last_name"
                label="Last Name"
                value={values.last_name ?? ""}
                error={Boolean(touched.last_name && errors.last_name)}
                fullWidth
                helperText={touched.last_name && errors.last_name}
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                variant="outlined"
                my={2}
                disabled={isLoading}
              />

              <AutocompleteField
                label="Customer Group"
                name="customer_group_uuid"
                options={customerGroupOptions}
                fullWidth
                openOnFocus
                disabled={isLoading}
              />

              <TextField
                name="phone_number"
                label="Phone Number"
                value={values.phone_number ?? ""}
                error={Boolean(touched.phone_number && errors.phone_number)}
                fullWidth
                helperText={touched.phone_number && errors.phone_number}
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                variant="outlined"
                my={2}
                disabled={isLoading}
              />

              <TextField
                name="mobile_number"
                label="Mobile Number"
                value={values.mobile_number ?? ""}
                error={Boolean(touched.mobile_number && errors.mobile_number)}
                fullWidth
                helperText={touched.mobile_number && errors.mobile_number}
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                variant="outlined"
                my={2}
                disabled={isLoading}
              />

              <GooglePlacesInput
                label="Billing Address"
                name="billing_address"
                disabled={isLoading}
              />

              <TextField
                name="company_name"
                label="Company Name"
                value={values.company_name ?? ""}
                error={Boolean(touched.company_name && errors.company_name)}
                fullWidth
                helperText={touched.company_name && errors.company_name}
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                variant="outlined"
                my={2}
                disabled={isLoading}
              />

              <TextField
                name="warning_text"
                label="Warning Text"
                value={values.warning_text ?? ""}
                error={Boolean(touched.warning_text && errors.warning_text)}
                fullWidth
                helperText={touched.warning_text && errors.warning_text}
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                variant="outlined"
                my={2}
                disabled={isLoading}
              />

              <AutocompleteField
                label="Customer Type"
                name="customer_type"
                filterOptions={createFilterOptions({
                  matchFrom: "start"
                })}
                options={[
                  { label: "Cash-on-delivery", id: "cod" },
                  { label: "Account", id: "account" }
                ]}
                fullWidth
                disabled={isLoading}
              />

              <TextField
                name="account_limit"
                label="Account Limit"
                value={values.account_limit ?? ""}
                error={Boolean(touched.account_limit && errors.account_limit)}
                fullWidth
                helperText={touched.account_limit && errors.account_limit}
                onBlur={handleBlur}
                onChange={handleChange}
                type="number"
                variant="outlined"
                my={2}
                disabled={isLoading}
              />

              {checkRolesAccess([USER_ROLES.SUPER_ADMIN]) && (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Boolean(values.is_tester)}
                        name="is_tester"
                        value={true}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    }
                    label="Is Tester?"
                  />
                  <FormHelperText margin="dense">
                    Payments related to this customer will be marked as TEST mode
                  </FormHelperText>
                </>
              )}

              <Grid container justifyContent="space-between">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  mt={3}
                  disabled={!dirty || isLoading || isSuspendMutations}
                >
                  Submit
                </Button>

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  mt={3}
                  disabled={!dirty || isLoading || isSuspendMutations}
                  onClick={async e => {
                    e.preventDefault();
                    setCreateLeadAfterSubmit(true);

                    await submitForm();
                  }}
                >
                  Save & create Lead
                </Button>

                <Feature name="FEATURE_SEED_DATA">
                  <Button
                    variant="contained"
                    color="primary"
                    mt={3}
                    onClick={async () => {
                      const { faker } = await import("@faker-js/faker");

                      setValues(prevValues => {
                        const fname = faker.name.firstName();
                        const lname = faker.name.lastName();

                        return {
                          ...prevValues,
                          email: faker.internet.email(fname, lname),
                          first_name: fname,
                          last_name: lname,
                          phone_number: faker.phone.number(),
                          mobile_number: faker.phone.number(),
                          billing_address: faker.address.streetAddress(true),
                          company_name: faker.random.words(2),
                          warning_text: faker.random.words(10),
                          customer_group_uuid: faker.helpers.arrayElement(
                            formOptions?.customer_groups?.map(
                              (customerGroup: any) => customerGroup.uuid
                            )
                          )
                        };
                      });
                    }}
                  >
                    <RefreshIcon /> Seed Data
                  </Button>
                </Feature>
              </Grid>

              <Dialog
                fullWidth
                open={!!openFormDialog}
                onClose={() => setOpenFormDialog(undefined)}
              >
                {openFormDialog}
              </Dialog>
            </form>
          </CardContent>
        </Card>
      )}
    </Formik>
  );
};

export default CustomerForm;
