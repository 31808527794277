import ProductLineList from "./ProductLineList";
import ProductLineAdd from "./ProductLineAdd";
import ProductLineEdit from "./ProductLineEdit";

const routes = [
  {
    path: "/product-line",
    element: <ProductLineList />
  },
  {
    path: "/product-line/add",
    element: <ProductLineAdd />
  },
  {
    path: "/product-line/:uuid/edit",
    element: <ProductLineEdit />
  }
];

export default routes;
