import { TextField as BaseTextField } from "@mui/material";
import { Field, FieldProps, useFormikContext } from "formik";
import { isEmpty } from "lodash";
import React from "react";
import { RenderConfigProps } from "../OrderLineItemForm";
import { useHandleLookbackFieldValueChanged } from "../../../../hooks/useHandleLookbackFieldValueChanged";

// TODO: Validate integer only input
const IntegerInput: React.VFC<RenderConfigProps> = ({ conf }) => {
  const { lookback, lookbackValueLabel } = useHandleLookbackFieldValueChanged({ conf });
  const { isSubmitting } = useFormikContext();
  const fieldName = `config_values[${conf["Unique Option Name"]}]`;

  const isInputDisabled =
    !isEmpty(lookback) &&
    !conf["Answer Options"]?.["enable_input_if_lookback_value_includes"]?.includes(
      lookbackValueLabel
    );
  const required = !isInputDisabled && conf["Is Required"] === "Y";

  return (
    <Field name={fieldName}>
      {({ field, meta: { error } }: FieldProps) => {
        return (
          <BaseTextField
            label={conf["Question Heading"]}
            {...field}
            value={field.value ?? ""}
            fullWidth
            type="number"
            variant="outlined"
            disabled={isInputDisabled || isSubmitting}
            margin="normal"
            size="small"
            error={!!error}
            InputLabelProps={{
              required
            }}
            required={required}
            helperText={error ?? (!isEmpty(conf["Help Message"]) && conf["Help Message"])}
          />
        );
      }}
    </Field>
  );
};

export default IntegerInput;
