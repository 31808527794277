import { useContext, useEffect } from "react";
import { PageTitleContext } from "../contexts/PageTitleContext";

const useSetPageTitle = (pageTitle?: string) => {
  const context = useContext(PageTitleContext);

  useEffect(() => {
    context?.setPageTitle(pageTitle);
  }, [context, pageTitle]);
};

export default useSetPageTitle;
