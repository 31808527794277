import ProductSoldByList from "./ProductSoldByList";
import ProductSoldByAdd from "./ProductSoldByAdd";
import ProductSoldByEdit from "./ProductSoldByEdit";

const routes = [
  {
    path: "/product-sold-by",
    element: <ProductSoldByList />
  },
  {
    path: "/product-sold-by/add",
    element: <ProductSoldByAdd />
  },
  {
    path: "/product-sold-by/:uuid/edit",
    element: <ProductSoldByEdit />
  }
];

export default routes;
