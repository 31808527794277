import { TextField as BaseTextField } from "@mui/material";
import { Field, FieldProps, useFormikContext } from "formik";
import { isEmpty } from "lodash";
import React from "react";
import { RenderConfigProps } from "../OrderLineItemForm";
import { useHandleLookbackFieldValueChanged } from "../../../../hooks/useHandleLookbackFieldValueChanged";

const NotesInput: React.VFC<RenderConfigProps> = ({ conf }) => {
  useHandleLookbackFieldValueChanged({ conf });
  const { isSubmitting } = useFormikContext();
  const fieldName = `config_values[${conf["Unique Option Name"]}]`;

  return (
    <Field name={fieldName}>
      {({ field, meta: { error } }: FieldProps) => {
        return (
          <BaseTextField
            label={conf["Question Heading"]}
            {...field}
            value={field.value ?? ""}
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            disabled={isSubmitting}
            margin="normal"
            size="small"
            error={!!error}
            InputLabelProps={{
              required: conf["Is Required"] === "Y"
            }}
            required={conf["Is Required"] === "Y"}
            helperText={error ?? (!isEmpty(conf["Help Message"]) && conf["Help Message"])}
          />
        );
      }}
    </Field>
  );
};

export default NotesInput;
