import PaymentMethodList from "./PaymentMethodList";
import PaymentMethodAdd from "./PaymentMethodAdd";
import PaymentMethodEdit from "./PaymentMethodEdit";

const routes = [
  {
    path: "/payment-method",
    element: <PaymentMethodList />
  },
  {
    path: "/payment-method/add",
    element: <PaymentMethodAdd />
  },
  {
    path: "/payment-method/:uuid/edit",
    element: <PaymentMethodEdit />
  }
];

export default routes;
