import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo
} from "react";
import {
  DataGridPro,
  DataGridProProps,
  GridInitialState,
  GridProSlotsComponent
} from "@mui/x-data-grid-pro";
import { DataGridPageToolbarV2 } from "./DataGridPageToolbarV2.tsx";
import { useLocalStorage } from "usehooks-ts";
import { has, set } from "lodash";

export interface DataGridStatefulProps extends DataGridProProps {
  id?: string;
  hideIsMutatingLoading?: boolean;
  setEnableQuery?: Dispatch<SetStateAction<boolean>>;
  saveAndRestoreState?: boolean;
}

const defaultPageSizeOptions = [10, 25, 50, 100, 500];

export const DataGridStateful = ({
  id,
  rows,
  setEnableQuery,
  saveAndRestoreState,
  initialState: initialStateFromParent,
  apiRef,
  ...dataGridProps
}: DataGridStatefulProps) => {
  const [initialState, setInitialState] = useLocalStorage<GridInitialState | undefined>(
    `dg-${[location.pathname, id].filter(Boolean).join("-")}`,
    initialStateFromParent,
    {
      initializeWithValue: true
    }
  );

  useEffect(() => {
    if (apiRef?.current?.restoreState) {
      if (initialState) {
        if (has(initialState, "pagination.paginationModel.page")) {
          apiRef.current.restoreState(set(initialState, "pagination.paginationModel.page", 0));
        } else {
          apiRef.current.restoreState(initialState);
        }
      }

      setEnableQuery && setEnableQuery(true);
    }
  }, [apiRef, initialState, setEnableQuery]);

  const saveSnapshot = useCallback(() => {
    if (apiRef?.current?.exportState && saveAndRestoreState) {
      setInitialState(apiRef.current.exportState());
    }
  }, [apiRef, setInitialState, saveAndRestoreState]);

  useLayoutEffect(() => {
    // handle refresh and navigating away/refreshing
    window.addEventListener("beforeunload", saveSnapshot);

    return () => {
      // in case of an SPA remove the event-listener
      window.removeEventListener("beforeunload", saveSnapshot);
      saveSnapshot();
    };
  }, [saveSnapshot]);

  const getRowId = useCallback(({ uuid }: any): string => uuid, []);

  const slots = useMemo<GridProSlotsComponent>(() => {
    return {
      toolbar: DataGridPageToolbarV2,
      ...dataGridProps?.slots
    } as GridProSlotsComponent;
  }, [dataGridProps]);

  return (
    <DataGridPro
      apiRef={apiRef}
      autoPageSize
      autosizeOnMount
      checkboxSelection
      disableRowSelectionOnClick
      pagination
      getRowId={getRowId}
      rows={rows}
      keepNonExistentRowsSelected
      pageSizeOptions={defaultPageSizeOptions}
      slots={slots}
      {...dataGridProps}
    />
  );
};
