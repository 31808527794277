import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import useAuth from "../../hooks/useAuth";
import PageLoading from "../PageLoading";

interface AuthGuardType {
  children: React.ReactNode;
}

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }: AuthGuardType) {
  const { isAuthenticated, isInitialized } = useAuth();
  const needSignIn = isInitialized && !isAuthenticated;
  const navigate = useNavigate();

  useEffect(() => {
    if (!needSignIn) {
      return;
    }

    navigate(
      `/auth/sign-in?redirect=${encodeURIComponent(
        window.location.href.replace(window.location.origin, "")
      )}`,
      { replace: true }
    );
  }, [needSignIn, navigate]);

  if (!isInitialized) {
    return <PageLoading />;
  }

  return children;
}

export default AuthGuard;
