import {
  useMutationAbstract,
  useQueryFormOptionsModel,
  useQueryOneModel
} from "../hooks/useMutationFormAbstract.ts";
import axios from "../utils/axios.ts";

export const QUERY_KEY = "data-export";

export const useQueryOneDataExport = (uuid?: string) =>
  useQueryOneModel<any>(QUERY_KEY, uuid, {
    retry: 30,
    retryDelay: 1000
  });

export const useQueryDataExportFormOptions = () =>
  useQueryFormOptionsModel<{
    customer_groups: any;
  }>(QUERY_KEY);

export const useDataExport = (export_type: string) => {
  return useMutationAbstract({
    mutationKey: [QUERY_KEY, export_type],
    mutationFn: input =>
      axios.post(`/api/${QUERY_KEY}/${export_type}`, input).then(({ data }) => data)
  });
};
