import { GridFilterItem } from "@mui/x-data-grid";

// https://www.npmjs.com/package/@bekzod/sequelizeqp
export const encodeToSequelizeQP = (item: GridFilterItem) => {
  // TODO: Convert date to AU timezone
  if (item.value === undefined) {
    if (item.operator === "isEmpty") {
      return null;
    }

    if (item.operator === "isNotEmpty") {
      return "!";
    }

    return null;
  }

  if (["equals", "=", "is"].includes(item.operator)) {
    return item.value;
  }

  if (["!=", "not"].includes(item.operator)) {
    return `!${item.value}`;
  }

  if ([">", ">=", "<", "<="].includes(item.operator)) {
    return `${item.operator}${item.value}`;
  }

  if (item.operator === "after") {
    return `>${item.value}`;
  }

  if (item.operator === "onOrAfter") {
    return `>=${item.value}`;
  }

  if (item.operator === "before") {
    return `<${item.value}`;
  }

  if (item.operator === "onOrBefore") {
    return `<=${item.value}`;
  }

  if (item.operator === "startsWith") {
    return `^${item.value}`;
  }

  if (item.operator === "endsWith") {
    return `$${item.value}`;
  }

  if (item.operator === "contains") {
    return `~${item.value}`;
  }

  if (item.operator === "isAnyOf") {
    return `$in${item.value?.join("|")}`;
  }

  if (item.operator === "date_between") {
    return item.value?.map((val: string) => `|${val}`)?.join("");
  }

  return item.value;
};
