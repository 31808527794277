import React from "react";
import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid-pro";
import AutocompleteField from "../../../components/AutocompleteField";
import { useMutationPatchLead, useQueryLeadFormOptions } from "../../../api/Lead";
import { Formik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import axios from "axios";

interface Values {
  lead_status_uuid?: string;
}

const LeadStatusGridAutocompleteField = (props: GridRenderEditCellParams) => {
  const { id, field, row } = props;
  const { data: formOptionsData, isLoading } = useQueryLeadFormOptions();
  const { mutateAsync: patchLead } = useMutationPatchLead(row?.uuid);
  const apiRef = useGridApiContext();
  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = Yup.object().shape({
    lead_status_uuid: Yup.string()
      .uuid()
      .required()
      .label("Lead Status")
      .oneOf(formOptionsData?.lead_status?.map(({ uuid }: any) => uuid) ?? [])
  });

  const handleSubmit = async (values: Values) => {
    try {
      await patchLead(
        validationSchema.cast(values, {
          stripUnknown: true
        })
      );
    } catch (error: any) {
      if (axios.isAxiosError(error) && error?.response) {
        console.error(error);
        enqueueSnackbar(
          // @ts-expect-error quick
          Object.values(error?.response?.data?.violations).join(", "),
          {
            variant: "error",
            preventDuplicate: true
          }
        );
      } else {
        // TODO: Rollbar error
        console.error(error);
        enqueueSnackbar("Something went wrong", {
          variant: "error",
          preventDuplicate: true
        });
      }
    }
  };

  return (
    <Formik
      initialValues={{
        lead_status_uuid: row?.lead_status?.uuid
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue, submitForm }) => {
        return (
          <AutocompleteField
            label="Lead Status"
            name="lead_status_uuid"
            options={formOptionsData?.lead_status?.map(({ uuid, name }: any) => ({
              id: uuid,
              label: name
            }))}
            fullWidth
            openOnFocus
            disabled={isLoading}
            onChange={async (event, value: any) => {
              const isValid = await apiRef.current.setEditCellValue({
                id,
                field,
                value
              });

              if (isValid) {
                setFieldValue("lead_status_uuid", value.id);
                apiRef.current.stopCellEditMode({ id, field });
                submitForm();
              }
            }}
          />
        );
      }}
    </Formik>
  );
};

export default LeadStatusGridAutocompleteField;
