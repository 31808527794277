import React, { useEffect, useState } from "react";
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  FileCopy as FileCopyIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon
} from "@mui/icons-material";
import confirm from "../../components/Confirm";
import { generatePath, Link as RouterLink } from "react-router-dom";
import { IconButton, Tooltip } from "@mui/material";
import {
  useMutationBulkDeletePage,
  useMutationDeletePage,
  useMutationPageSetPublishedAt,
  useQueryPages
} from "../../api/Page";
import useAuth from "../../hooks/useAuth";
import { USER_ROLES } from "../../constants";
import { withDataGridPageProvider } from "../../contexts/DataGridPageContext";
import { GridColDef } from "@mui/x-data-grid-pro";
import DataGridPage from "../../components/data-grid/DataGridPage";
import useHealthChecker from "../../hooks/useHealthChecker";
import useSetPageTitle from "../../hooks/useSetPageTitle.ts";

const PageList = () => {
  useSetPageTitle("Pages");
  const { mutateAsync: deleteItem } = useMutationDeletePage();
  const { checkRolesAccess } = useAuth();
  const { isSuspendMutations } = useHealthChecker();
  // const pagesQuery = useQueryPages();
  const [copiedSlug, setCopiedSlug] = useState<string | null>(null);

  const { mutateAsync: setPublishedAt } = useMutationPageSetPublishedAt();

  // Initialize the pageStates state from localStorage or an empty object
  const [pageStates, setPageStates] = useState<{ [key: string]: boolean }>(() => {
    const storedPageStates = localStorage.getItem("pageStates");
    return storedPageStates ? JSON.parse(storedPageStates) : {};
  });

  useEffect(() => {
    // Save the pageStates to localStorage whenever it changes
    localStorage.setItem("pageStates", JSON.stringify(pageStates));
  }, [pageStates]);

  const handlePublish = async (
    uuid: string,
    isPublished: boolean,
    title: string | null,
    content: string | null
  ) => {
    const newPublishedState = !isPublished;
    const actionVerb = newPublishedState ? "publish" : "unpublish";

    if (
      await confirm({
        confirmation: `You are about to ${actionVerb}. Are you sure?`,
        options: {
          title: "Confirm"
        }
      })
    ) {
      try {
        // Update the state for the current page
        setPageStates(prevPageStates => ({
          ...prevPageStates,
          [uuid]: newPublishedState
        }));

        // Log the payload before sending
        const payload = {
          uuid,
          published_at: newPublishedState ? new Date().toISOString() : null,
          title: title || "",
          content
        };

        await setPublishedAt({
          ...payload,
          published_at: newPublishedState ? new Date(payload.published_at as string) : null
        });
      } catch (error) {
        console.error(`Error ${actionVerb}ing page ${uuid}:`, error);
        // Log the entire error object to inspect its structure
        console.error("Full error object:", error);
        // Handle specific details based on the error object's structure
      }
    }
  };

  const handleCopySlug = (uuid: string, slug: string) => {
    const fullUrl = `${window.location.origin}/pages/${slug}`;
    navigator.clipboard.writeText(fullUrl);
    setCopiedSlug(slug);
    setTimeout(() => {
      setCopiedSlug(null);
    }, 3000);
  };

  const handleDelete = async (uuid: string) => {
    if (
      await confirm({
        confirmation: "You are about to delete this page. Are you sure?",
        options: {
          title: "Confirm"
        }
      })
    ) {
      await deleteItem(uuid);
    }
  };

  const columns: GridColDef[] = [
    { field: "title", headerName: "Title", flex: 1 },
    { field: "slug", headerName: "Slug", flex: 1, sortable: false },
    {
      field: "actions",
      headerName: "Actions",
      filterable: false,
      sortable: false,
      headerAlign: "right",
      align: "right",
      renderCell: ({ row }) => {
        const { uuid } = row;

        // Ensure that pageStates is correctly updated
        const pageState = pageStates[uuid] || false;

        return [
          <Tooltip title={pageState ? "Unpublish" : "Publish"} key={`toggle-publish-${uuid}`}>
            <IconButton
              size="large"
              onClick={() => handlePublish(row.uuid, pageState, row.title, row.content)}
            >
              {pageState ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          </Tooltip>,
          <Tooltip title="Edit" key={`edit-${uuid}`}>
            <IconButton
              size="large"
              component={RouterLink}
              to={generatePath("/pages/:uuid/edit", { uuid })}
              disabled={!checkRolesAccess([USER_ROLES.ADMIN])}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>,
          <Tooltip title="Delete" key={`delete-${uuid}`}>
            <IconButton
              size="large"
              onClick={() => handleDelete(uuid)}
              disabled={!checkRolesAccess([USER_ROLES.ADMIN]) || isSuspendMutations}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>,
          <Tooltip title="Copy Permalink" key={`copy-permalink-${uuid}`}>
            <IconButton
              id={`copy-button-${uuid}`}
              size="large"
              onClick={() => handleCopySlug(uuid, row.slug)}
              style={{
                marginLeft: "8px",
                color: copiedSlug === row.slug ? "green" : "inherit"
              }}
            >
              <FileCopyIcon />
            </IconButton>
          </Tooltip>
        ];
      },
      flex: 1
    }
  ];

  return (
    <div>
      <DataGridPage
        dataGridProps={{
          columns
        }}
      />
    </div>
  );
};

export default withDataGridPageProvider(PageList, {
  useQueryList: useQueryPages,
  useMutationBulkDelete: useMutationBulkDeletePage,
  addButtonNavigateTo: "/pages/add"
});
