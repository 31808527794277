import {
  useMutationCopyOrderLineItem,
  useMutationDeleteOrderLineItem
} from "../../../api/OrderLineItem";
import confirm from "../../../components/Confirm";
import { QUERY_KEY } from "../../../api/Order";
import { generatePath, useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { bindMenu, bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import { IconButton, ListItemIcon, Menu, MenuItem } from "@mui/material";
import { MoreVertical } from "react-feather";
import {
  ContentCopy as ContentCopyIcon,
  Delete as DeleteIcon,
  Edit as EditIcon
} from "@mui/icons-material";

export const OrderLineItemActionsMenu: React.VFC<{
  order_line_item: any;
  order_uuid: string;
}> = ({ order_line_item, order_uuid }) => {
  const { uuid } = order_line_item;
  const { mutateAsync: deleteOrderLineItem } = useMutationDeleteOrderLineItem();
  const { mutateAsync: copyOrderLineItem } = useMutationCopyOrderLineItem();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const popupState = usePopupState({
    variant: "popover",
    popupId: `line-item-${uuid}`
  });

  return (
    <>
      <IconButton size="small" disableFocusRipple edge="end" {...bindTrigger(popupState)}>
        <MoreVertical />
      </IconButton>
      <Menu {...bindMenu(popupState)}>
        <MenuItem
          onClick={() => {
            popupState.close();
            navigate(
              generatePath("/order/:order_uuid/edit/step-3/line-item/:uuid", {
                order_uuid,
                uuid
              })
            );
          }}
        >
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          Edit
        </MenuItem>
        <MenuItem
          onClick={async () => {
            popupState.close();
            if (
              await confirm({
                confirmation: "You are about to copy. Are you sure?",
                options: {
                  title: "Confirm"
                }
              })
            ) {
              const response = await copyOrderLineItem(uuid);

              navigate(
                generatePath("/order/:order_uuid/edit/step-3/line-item/:uuid", {
                  order_uuid,
                  uuid: response.copied_uuid
                })
              );
            }
          }}
        >
          <ListItemIcon>
            <ContentCopyIcon />
          </ListItemIcon>
          Copy
        </MenuItem>
        <MenuItem
          onClick={async () => {
            popupState.close();
            if (
              await confirm({
                confirmation: "You are about to delete. Are you sure?",
                options: {
                  title: "Confirm"
                }
              })
            ) {
              await deleteOrderLineItem(uuid);
              await queryClient.invalidateQueries([QUERY_KEY]);

              navigate(
                generatePath("/order/:order_uuid/edit/step-3", {
                  order_uuid
                })
              );
            }
          }}
        >
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          Delete
        </MenuItem>
      </Menu>
    </>
  );
};
