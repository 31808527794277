import React, { useState } from "react";
import { Card } from "@mui/material";
import { DataGridPro, DataGridProProps, GridPaginationModel } from "@mui/x-data-grid-pro";
import useDataGridPage from "../../hooks/useDataGridPage";
import DataGridPageToolbar from "./DataGridPageToolbar";
import { useIsMutating } from "@tanstack/react-query";
import type { SetOptional } from "type-fest";

interface DataGridPageProps {
  dataGridProps: SetOptional<DataGridProProps, "rows">;
  hideIsMutatingLoading?: boolean;
}

const DataGridPage = ({
  dataGridProps: _dataGridProps,
  hideIsMutatingLoading
}: DataGridPageProps) => {
  const {
    state,
    setState,
    query: { data, isLoading }
  } = useDataGridPage();
  const isMutating = useIsMutating() > 0 && !hideIsMutatingLoading;

  const [filterButtonEl, setFilterButtonEl] = useState<HTMLButtonElement | null>(null);

  const { rows, slotProps, ...dataGridProps } = _dataGridProps;

  return (
    <Card sx={{ height: "84vh", maxWidth: { xs: "100%", lg: "81vw" } }}>
      <DataGridPro
        {...{
          sx: { border: 0 },
          checkboxSelection: true,
          disableRowSelectionOnClick: true,
          rows: rows ?? data ?? [],
          loading: isLoading || isMutating,
          slots: {
            toolbar: DataGridPageToolbar
          },
          getRowId: ({ uuid }) => uuid,
          pagination: true,
          pageSizeOptions: [10, 25, 50, 100, 500],
          paginationModel: {
            page: state.page ?? 0,
            pageSize: state.pageSize ?? 50
          },
          onPaginationModelChange: (model: GridPaginationModel) => {
            setState(prev => ({
              ...prev,
              ...model
            }));
          },
          rowSelectionModel: state.selected,
          onRowSelectionModelChange: selected =>
            setState({
              selected
            }),
          sortModel: state.sorting,
          onSortModelChange: sorting =>
            setState({
              sorting
            }),
          filterModel: state.filter,
          onFilterModelChange: filter =>
            setState({
              filter
            }),
          columnVisibilityModel: state.columnVisibility,
          onColumnVisibilityModelChange: columnVisibility =>
            setState({
              columnVisibility
            }),
          slotProps: {
            panel: {
              anchorEl: filterButtonEl,
              placement: "bottom-end"
            },
            toolbar: {
              setFilterButtonEl,
              ...slotProps?.toolbar
            },
            ...slotProps
          },
          ...dataGridProps
        }}
      />
    </Card>
  );
};

export default DataGridPage;
