import React from "react";
import styled from "@emotion/styled";
import { v4 } from "uuid";
import { Divider as MuiDivider, Paper as MuiPaper } from "@mui/material";
import { spacing } from "@mui/system";
import { useMutationAddInventoryItem } from "../../api/InventoryItem";
import PageTitleWithLoading from "../../components/PageTitleWithLoading";
import InventoryItemForm from "./InventoryItemForm";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const InventoryItemAdd = () => {
  const mutation = useMutationAddInventoryItem(v4());

  return (
    <Paper p={4}>
      <PageTitleWithLoading title="Add Inventory Item" />

      <Divider my={6} />

      <InventoryItemForm mutation={mutation} />
    </Paper>
  );
};

export default InventoryItemAdd;
