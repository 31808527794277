import React, { Suspense, useMemo } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from "@mui/material";
import { confirmable, ConfirmDialogProps } from "react-confirm";
import { createConfirmation } from "../../../components/ReactConfirmMountPoint";
import { Field, FieldProps, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import useHealthChecker from "../../../hooks/useHealthChecker";
import { addDays, formatISO, parseISO } from "date-fns";
import { DatePicker } from "@mui/x-date-pickers";
import Loader from "../../../components/Loader";

interface Values {
  uuid: string;
  is_invoiced_at: string;
}

interface Props {
  uuid: string;
}

export const InvoicedDateDialog = ({
  show,
  proceed,
  uuid
}: ConfirmDialogProps<Props, false | Values>) => {
  const { isSuspendMutations } = useHealthChecker();

  const handleClose = () => proceed(false);

  const validationSchema = Yup.object<Values>().shape({
    uuid: Yup.string().uuid().required(),
    is_invoiced_at: Yup.string().datetime({ allowOffset: true }).required().label("Invoiced At")
  });

  const formik = useFormik<Values>({
    initialValues: useMemo(
      () => ({
        uuid,
        is_invoiced_at: formatISO(addDays(new Date(), 1))
      }),
      [uuid]
    ),
    enableReinitialize: true,
    validationSchema,
    onSubmit: values => {
      const validated = validationSchema.cast(values, {
        stripUnknown: true
      });
      proceed(validated);
    }
  });

  const { handleSubmit, values } = formik;

  return (
    <Dialog open={show} onClose={handleClose} fullWidth maxWidth="sm">
      <FormikProvider value={formik}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Set Invoiced Date</DialogTitle>
          <DialogContent>
            <Field name="is_invoiced_at" value={values.is_invoiced_at}>
              {({ field, form, meta }: FieldProps) => {
                return (
                  <DatePicker
                    label="Invoiced Date"
                    value={parseISO(meta.value)}
                    onChange={(newValue: any) => {
                      if (newValue) {
                        form.setFieldValue(field.name, formatISO(newValue));
                      }
                    }}
                    slots={{
                      textField: (params: any) => (
                        <TextField
                          {...params}
                          helperText={meta.touched && meta.error}
                          error={Boolean(meta.touched && meta.error)}
                          fullWidth
                          margin="normal"
                        />
                      )
                    }}
                  />
                );
              }}
            </Field>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit" color="primary" disabled={isSuspendMutations}>
              Submit
            </Button>
          </DialogActions>
        </form>
      </FormikProvider>
    </Dialog>
  );
};

export const setInvoicedDateDialog = createConfirmation(
  confirmable((props: ConfirmDialogProps<Props, false | Values>) => (
    <Suspense fallback={<Loader />}>
      <InvoicedDateDialog {...props} />
    </Suspense>
  ))
);
