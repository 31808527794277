import { Card, Link } from "@mui/material";
import { darken, lighten, styled, Theme } from "@mui/material/styles";

import { DataGridServerIndexBasedPaginated as BaseDataGridServerIndexBasedPaginated } from "../../../components/data-grid-v2/DataGridServerIndexBasedPaginated.tsx";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { compareAsc, format, isPast, isValid, parseISO, subDays } from "date-fns";
import {
  GridActionsCellItem,
  GridColDef,
  GridFilterItem,
  GridRenderEditCellParams,
  GridRowModel,
  GridRowParams
} from "@mui/x-data-grid";
import { generatePath, Link as RouterLink } from "react-router-dom";
import { toDecimalFromFloat } from "aldrin-erp-main-shared-functions";
import { cloneDeep, has, isEmpty, set } from "lodash";
import {
  useMutationSetCallBackAtAndNotesLead,
  useMutationSetStatusLostLead
} from "../../../api/Lead.ts";
import { useQueryClient } from "@tanstack/react-query";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import confirm from "../../../components/Confirm.tsx";
import { DataGridDatePicker } from "../../../components/data-grid-v2/DataGridDatePicker.tsx";
import objectDelta from "../../../utils/objectDelta.ts";
import { setCallBackDateDialog } from "../../lead/CallBackDateDialog.tsx";
import { GridApiPro } from "@mui/x-data-grid-pro";

const getBackgroundColor = (color: string, theme: Theme, coefficient: number) => ({
  backgroundColor: darken(color, coefficient),
  ...theme.applyStyles("light", {
    backgroundColor: lighten(color, coefficient)
  })
});

const DataGridServerIndexBasedPaginated = styled(BaseDataGridServerIndexBasedPaginated)(
  ({ theme }) => ({
    "& .old": {
      ...getBackgroundColor(theme.palette.error.main, theme, 0.8),
      "&:hover": {
        ...getBackgroundColor(theme.palette.error.main, theme, 0.6)
      },
      "&.Mui-selected": {
        ...getBackgroundColor(theme.palette.info.main, theme, 0.5),
        "&:hover": {
          ...getBackgroundColor(theme.palette.info.main, theme, 0.4)
        }
      }
    }
  })
);

export const LeadCallBackList = ({
  upsertFilterItems
}: {
  upsertFilterItems?: GridFilterItem[];
}) => {
  const { mutateAsync: setStatusLostLead } = useMutationSetStatusLostLead();
  const { mutateAsync: setCallBackAtAndNotes } = useMutationSetCallBackAtAndNotesLead();
  const queryClient = useQueryClient();

  const dataGridRef = useRef<Pick<GridApiPro, "upsertFilterItems" | "deleteFilterItem">>();

  useEffect(() => {
    if (dataGridRef.current && upsertFilterItems) {
      dataGridRef.current.upsertFilterItems(upsertFilterItems);
    }
  }, [upsertFilterItems]);

  const handleButtonLost = useCallback(
    (row: any) => async () => {
      if (
        await confirm({
          confirmation: "You are about to mark the lead status as Lost. Are you sure?",
          options: {
            title: "Confirm"
          }
        })
      ) {
        setStatusLostLead({
          uuid: row.lead.uuid
        }).then(() => {
          queryClient.invalidateQueries(["lead/call-back"]);
        });
      }
    },
    [queryClient, setStatusLostLead]
  );

  const columns = useMemo<GridColDef[]>(() => {
    return [
      {
        field: "reference_number",
        headerName: "Order #",
        width: 100,
        filterable: false,
        sortable: false,
        renderCell: params => {
          const { value, row } = params;
          return (
            <Link
              component={RouterLink}
              underline="hover"
              to={generatePath(
                row?.is_quote_finalized ? "/order/:uuid/finalized-quote" : "/order/:uuid/edit",
                {
                  uuid: row.uuid
                }
              )}
            >
              {value}
            </Link>
          );
        }
      },
      {
        field: "customer_id",
        headerName: "Customer",
        width: 180,
        filterable: false,
        sortable: false,
        renderCell: params => {
          const {
            row: { customer }
          } = params;
          if (customer) {
            return (
              <Link
                component={RouterLink}
                underline="none"
                to={generatePath("/customers/:uuid/details", {
                  uuid: customer?.uuid
                })}
              >
                {customer.full_name_with_company}
              </Link>
            );
          }
        }
      },
      {
        field: "created_at",
        headerName: "Quoted Date",
        width: 180,
        filterable: false,
        sortable: false,
        valueFormatter: value => (isValid(value) ? format(value, "dd/MM/yyyy pp") : ""),
        valueGetter: (_, row) => parseISO(row.created_at)
      },
      {
        field: "site_address",
        headerName: "Site Address",
        width: 180,
        filterable: false,
        sortable: false,
        valueGetter: (_, row) => row?.site_address || row.customer?.billing_address
      },
      {
        field: "customer.phone_number",
        headerName: "Phone #",
        width: 180,
        filterable: false,
        sortable: false,
        valueGetter: (_, row) => row.customer?.phone_number
      },
      {
        field: "lead.call_back_at",
        headerName: "Call Date",
        width: 180,
        filterable: false,
        valueFormatter: value => (isValid(value) ? format(value, "dd/MM/yyyy") : ""),
        valueGetter: (_, row) => parseISO(row.lead.call_back_at),
        valueSetter: (value, row, column) => {
          if (!isValid(value)) {
            return null;
          }

          return set(cloneDeep(row), column.field, value.toISOString());
        },
        editable: true,
        renderEditCell: (params: GridRenderEditCellParams) => <DataGridDatePicker {...params} />
      },
      {
        field: "lead.sales_rep_user.uuid",
        headerName: "Sales Rep UUID",
        filterable: false,
        valueGetter: (value, row) => row.lead.sales_rep_user?.full_name
        // type: "singleSelect",
        // valueOptions: formOptions?.sales_rep_user?.map((user: any) => ({
        //   value: user.uuid,
        //   label: user.full_name
        // })),
        // filterOperators: getGridSingleSelectMoreOperators()
      },
      {
        field: "sold_price",
        headerName: "Amount",
        filterable: false,
        sortable: false,
        valueFormatter: value => toDecimalFromFloat(value ?? 0),
        width: 120
      },
      {
        field: "discount",
        headerName: "Discount",
        filterable: false,
        sortable: false,
        valueGetter: (value, row) => {
          const { discount } = row;
          return `${discount ?? 0}%`;
        },
        width: 120
      },
      {
        field: "lead.call_back_notes",
        headerName: "Call Notes",
        width: 180,
        filterable: false,
        sortable: false,
        editable: true,
        valueSetter: (value, row, column) => set(cloneDeep(row), column.field, value),
        valueGetter: (_, row) => row.lead.call_back_notes
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        getActions: (params: GridRowParams) => [
          <GridActionsCellItem
            key="lost"
            icon={<RemoveCircleOutlineIcon />}
            onClick={handleButtonLost(params.row)}
            label="Lost"
          />
        ]
      }
    ];
  }, [handleButtonLost]);

  const processRowUpdate = useCallback(
    async (newRow: GridRowModel, oldRow: GridRowModel) => {
      const delta = objectDelta(newRow.lead, oldRow.lead);
      if (isEmpty(delta)) {
        return oldRow;
      }

      let data: any = delta;

      if (has(delta, "call_back_at")) {
        const values = await setCallBackDateDialog({
          uuid: newRow?.lead?.uuid,
          call_back_at: newRow?.lead?.call_back_at,
          call_back_notes: newRow?.lead?.call_back_notes
        });

        if (values === false) {
          return oldRow;
        }

        data = values;
      }

      const { lead } = newRow;

      await setCallBackAtAndNotes({
        uuid: lead.uuid,
        ...data
      }).then(() => queryClient.invalidateQueries(["lead/call-back"]));

      return newRow;
    },
    [queryClient, setCallBackAtAndNotes]
  );

  const handleProcessRowUpdateError = useCallback((error: Error) => {
    console.error(error);
  }, []);

  return (
    <Card sx={{ height: "87vh" }}>
      <DataGridServerIndexBasedPaginated
        ref={dataGridRef}
        apiEndpoint="lead/call-back"
        autosizeOnMount={false}
        initialState={{
          sorting: {
            sortModel: [
              {
                field: "lead.call_back_at",
                sort: "desc"
              }
            ]
          }
        }}
        columns={columns}
        checkboxSelection={false}
        slots={{
          toolbar: null
        }}
        editMode="cell"
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        getRowClassName={({ row }) => {
          if (!row.lead.call_back_at) {
            const olderThan48Hrs = compareAsc(parseISO(row.created_at), subDays(new Date(), 2)) < 0;
            return olderThan48Hrs ? "old" : "";
          }

          return isPast(parseISO(row.lead.call_back_at)) ? "old" : "";
        }}
      />
    </Card>
  );
};
