import React, { useDeferredValue, useMemo, useState } from "react";
import { Card, Tab, Tabs } from "@mui/material";
import LeadListWithQuery from "../../lead/LeadListByQuery.tsx";
import OrderListWithQuery from "../../customer/OrderListByQuery.tsx";
import { LeadCallBackList } from "./LeadCallBackList.tsx";

interface SalesRepDashboardTablesParams {
  sales_rep_user_uuids: string[];
}

export const SalesRepDashboardTables = ({
  sales_rep_user_uuids
}: SalesRepDashboardTablesParams) => {
  const [tab, setTab] = useState("call_backs");
  const deferredTab = useDeferredValue(tab);

  const handleTabsOnChange = (event: React.SyntheticEvent, value: string) => {
    setTab(value);
  };

  const tableComponent = useMemo(() => {
    return {
      call_backs: (
        <LeadCallBackList
          upsertFilterItems={[
            {
              id: "sales_rep.uuid",
              field: "lead.sales_rep_user.uuid",
              operator: "isAnyOf",
              value: sales_rep_user_uuids
            }
          ]}
        />
      ),
      leads: (
        <LeadListWithQuery
          isInSalesRepDashboardPage
          upsertFilterItems={[
            {
              id: "sales_rep.uuid",
              field: "sales_rep_user.uuid",
              operator: "isAnyOf",
              value: sales_rep_user_uuids
            }
          ]}
        />
      ),
      orders: (
        <OrderListWithQuery
          key="orders"
          upsertFilterItems={[
            {
              id: "sales_rep.uuid",
              field: "sales_rep.uuid",
              operator: "isAnyOf",
              value: sales_rep_user_uuids
            },
            {
              id: "order_type",
              field: "order_type",
              operator: "is",
              value: "order"
            },
            {
              id: "order_is_invoiced_at",
              field: "is_invoiced_at",
              operator: "isEmpty"
            }
          ]}
        />
      ),
      invoiced: (
        <OrderListWithQuery
          key="invoiced"
          upsertFilterItems={[
            {
              id: "sales_rep.uuid",
              field: "sales_rep.uuid",
              operator: "isAnyOf",
              value: sales_rep_user_uuids
            },
            {
              id: "invoiced_is_invoiced_at",
              field: "is_invoiced_at",
              operator: "isNotEmpty"
            }
          ]}
        />
      )
    }[deferredTab];
  }, [deferredTab, sales_rep_user_uuids]);

  return (
    <>
      <Card
        sx={{
          my: 3,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <Tabs
          value={tab}
          onChange={handleTabsOnChange}
          TabIndicatorProps={{ sx: { display: { sm: "block", xs: "none" } } }}
          sx={{
            "& .MuiTabs-flexContainer": {
              flexWrap: "wrap"
            }
          }}
        >
          <Tab iconPosition="end" label="Leads" value="leads" />
          <Tab iconPosition="end" label="Orders" value="orders" />
          <Tab iconPosition="end" label="Invoiced" value="invoiced" />
          <Tab iconPosition="end" label="Call backs" value="call_backs" />
        </Tabs>
      </Card>

      {tableComponent}
    </>
  );
};
