import { get } from "lodash";

export function createErrorInfo(error: Error) {
  const responseErrorCode = get(error, "response.status");
  const errorCode = get(error, "code", responseErrorCode);
  const responseErrorMessage = get(error, "response.data.message");
  const responseRequestId = get(error, "response.headers.x-request-id");
  const errorMessage =
    responseErrorMessage ??
    error.message ??
    "Something unexpected happened and our engineers were notified.";

  return {
    responseErrorCode,
    errorCode,
    responseErrorMessage,
    responseRequestId,
    errorMessage
  };
}
