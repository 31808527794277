import {
  useMutationAbstract,
  useMutationAddModel,
  useMutationBulkDeleteModelV2,
  useMutationDeleteModel,
  useMutationEditModel,
  useQueryFormOptionsModel,
  useQueryModels,
  useQueryOneModel
} from "../hooks/useMutationFormAbstract";
import axios from "../utils/axios";

export const QUERY_KEY = "product";

export const useQueryProducts = () => useQueryModels(QUERY_KEY);

export const useQueryProductFormOptions = () =>
  useQueryFormOptionsModel<{ product_lines: any; inventory_items: any; customer_groups: any }>(
    QUERY_KEY
  );

export const useQueryOneProduct = (uuid?: string) => useQueryOneModel<any>(QUERY_KEY, uuid);

export const useMutationAddProduct = (uuid: string) => useMutationAddModel(QUERY_KEY, uuid);

export const useMutationEditProduct = (uuid: string) => useMutationEditModel(QUERY_KEY, uuid);

export const useMutationDeleteProduct = () => useMutationDeleteModel(QUERY_KEY);

export const useMutationBulkDeleteProduct = () => useMutationBulkDeleteModelV2(QUERY_KEY);

export const useMutationImportXlsxProductConfig = (uuid: string) => {
  return useMutationAbstract({
    mutationKey: [QUERY_KEY],
    mutationFn: data =>
      axios.post(`/api/${QUERY_KEY}/${uuid}/import-config`, data).then(({ data }) => data)
  });
};

export const useMutationImportXlsxProductPriceGrid = (uuid: string) => {
  return useMutationAbstract({
    mutationKey: [QUERY_KEY],
    mutationFn: data =>
      axios.post(`/api/${QUERY_KEY}/${uuid}/import-price-grid`, data).then(({ data }) => data)
  });
};
