import { toDecimalFromFloat } from "aldrin-erp-main-shared-functions";
import { useDataGridFilterModelQueryParams } from "../../hooks/useDataGridFilterModelQueryParams";
import { gridFilterModelSelector, useGridApiContext, useGridSelector } from "@mui/x-data-grid-pro";
import { TextField } from "@mui/material";
import { useQueryOrderTransactionsStatistics } from "../../api/OrderTransaction.ts";

export const ToolbarStatistics = () => {
  const apiRef = useGridApiContext();
  const filterModel = useGridSelector(apiRef, gridFilterModelSelector);
  const filterQueryParams = useDataGridFilterModelQueryParams(filterModel);
  const { data: stats } = useQueryOrderTransactionsStatistics(filterQueryParams);

  return (
    <>
      {stats?.rows?.map((row: any) => (
        <TextField
          key={row.payment_method_name}
          label={row.payment_method_name}
          inputProps={{
            readOnly: true
          }}
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            disableUnderline: true
          }}
          value={toDecimalFromFloat(Number(row.sum_amount) ?? 0.0)}
          size="small"
          variant="standard"
          sx={{ width: 100 }}
        />
      ))}

      <TextField
        label="Period Total"
        inputProps={{
          readOnly: true
        }}
        InputLabelProps={{
          shrink: true
        }}
        InputProps={{
          disableUnderline: true
        }}
        value={toDecimalFromFloat(Number(stats?.all_sum_amount) ?? 0.0)}
        size="small"
        variant="standard"
        sx={{ width: 100 }}
      />
    </>
  );
};
