import OrderFittingList from "./OrderFittingList.tsx";
import asyncComponent from "../../components/Async.tsx";

const BookedList = asyncComponent(() => import("./BookedList.tsx"));

const routes = [
  {
    path: "/order-fitting",
    element: <OrderFittingList />,
    children: [
      {
        path: "",
        element: <BookedList />
      }
    ]
  }
];

export default routes;
