import {
  useMutationAddModel,
  useMutationBulkDeleteModelV2,
  useMutationDeleteModel,
  useMutationEditModel,
  useQueryModels,
  useQueryOneModel
} from "../hooks/useMutationFormAbstract";

export const QUERY_KEY = "user-role";

export const useQueryUserRoles = () => useQueryModels(QUERY_KEY);

export const useQueryOneUserRole = (uuid?: string) => useQueryOneModel<any>(QUERY_KEY, uuid);

export const useMutationAddUserRole = (uuid: string) => useMutationAddModel(QUERY_KEY, uuid);

export const useMutationEditUserRole = (uuid: string) => useMutationEditModel(QUERY_KEY, uuid);

export const useMutationDeleteUserRole = () => useMutationDeleteModel(QUERY_KEY);

export const useMutationBulkDeleteUserRole = () => useMutationBulkDeleteModelV2(QUERY_KEY);
