import React from "react";
import { useTheme } from "@mui/material/styles";
import ListItemButton from "@mui/material/ListItemButton";
import { Drawer } from "@mui/material";
import { NavLink } from "react-router-dom";
import styled from "@emotion/styled";
import Logo from "../../vendor/logo-brand.svg?react";
import LogoRev from "../../vendor/logo-menu-reverse.svg?react";
import { DrawerHeader } from "./DrawerHeader.tsx";
import { drawerWidth } from "../../layouts/DashboardV2.tsx";
import { useSidebarItems } from "../../hooks/useSidebarItems.ts";
import SidebarNav from "./SidebarNav.tsx";
import SidebarFooter from "./SidebarFooter.tsx";

const Brand = styled(ListItemButton)<{
  component?: React.ReactNode;
  to?: string;
}>`
  font-size: ${props => props.theme.typography.h5.fontSize};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  color: ${props => props.theme.sidebar.header.color};
  background-color: ${props => props.theme.sidebar.header.background};
  font-family: ${props => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${props => props.theme.spacing(6)};
  padding-right: ${props => props.theme.spacing(6)};
  justify-content: center;
  cursor: pointer;
  flex-grow: 0;

  ${props => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${props => props.theme.sidebar.header.background};
  }
`;

const BrandIconRev = styled(LogoRev)`
  margin-right: ${props => props.theme.spacing(2)};
  color: ${props => props.theme.sidebar.header.brand.color};
  fill: ${props => props.theme.sidebar.header.brand.color};
  height: 100px;
`;

const BrandIcon = styled(Logo)`
  margin-right: ${props => props.theme.spacing(2)};
  color: ${props => props.theme.sidebar.header.brand.color};
  fill: ${props => props.theme.sidebar.header.brand.color};
  height: 100px;
`;

export const SidebarV2 = ({ openSidebar }: { openSidebar: boolean }) => {
  const theme = useTheme();
  const items = useSidebarItems();

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box"
        }
      }}
      variant="persistent"
      anchor="left"
      open={openSidebar}
    >
      <DrawerHeader>
        <Brand component={NavLink as any} to="/">
          {theme.palette.mode === "dark" ? <BrandIcon /> : <BrandIconRev></BrandIconRev>}
        </Brand>
      </DrawerHeader>
      <SidebarNav items={items} />
      <SidebarFooter />
    </Drawer>
  );
};
