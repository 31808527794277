import { useJsApiLoader } from "@react-google-maps/api";
import { GoogleMapsAPIKey } from "../config";

// Note: update options here to avoid error: Loader must not be called again with different options.
const options: Parameters<typeof useJsApiLoader>[0] = {
  googleMapsApiKey: GoogleMapsAPIKey,
  libraries: ["places"]
};

export const useGlobalGoogleMapJsApiLoader = () => useJsApiLoader(options);
