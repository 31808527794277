import OrderReceivableList from "./OrderReceivableList";

const routes = [
  {
    path: "/order-receivable",
    element: <OrderReceivableList />
  }
];

export default routes;
