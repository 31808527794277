import styled from "@emotion/styled";

export const TableStyled = styled.div`
  table {
    border-collapse: collapse;
    font-family: helvetica, serif;
  }

  td,
  th {
    border: 1px solid;
    padding: 10px;
    min-width: 200px;
    background: white;
    box-sizing: border-box;
    text-align: left;
  }

  .table-container {
    position: relative;
    max-height: 300px;
    width: 500px;
    overflow: scroll;
  }

  thead th {
    position: sticky;
    top: 0;
    z-index: 2;
    background: hsl(20, 50%, 70%);
  }

  thead th:first-of-type {
    left: 0;
    z-index: 3;
  }

  tfoot {
    position: sticky;
    bottom: 0;

    z-index: 2;
  }

  tfoot td {
    position: sticky;
    bottom: 0;
    z-index: 2;
    background: hsl(20, 50%, 70%);
  }

  tfoot td:first-of-type {
    z-index: 3;
  }

  tbody {
    overflow: scroll;
    height: 200px;
  }

  /* MAKE LEFT COLUMN FIXEZ */

  tr > :first-of-type {
    position: sticky;
    background: hsl(0, 0%, 86%);
    left: 0;
  }

  /* don't do this */

  tr > :first-of-type {
    box-shadow: inset 0px 1px black;
  }
`;
