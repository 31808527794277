import {
  useMutationAddModel,
  useMutationBulkDeleteModelV2,
  useMutationDeleteModel,
  useMutationEditModel,
  useQueryModels,
  useQueryOneModel
} from "../hooks/useMutationFormAbstract";

export const QUERY_KEY = "product-sold-by";

export const useQueryProductSoldBy = () => useQueryModels(QUERY_KEY);

export const useQueryOneProductSoldBy = (uuid?: string) => useQueryOneModel<any>(QUERY_KEY, uuid);

export const useMutationAddProductSoldBy = (uuid: string) => useMutationAddModel(QUERY_KEY, uuid);

export const useMutationEditProductSoldBy = (uuid: string) => useMutationEditModel(QUERY_KEY, uuid);

export const useMutationDeleteProductSoldBy = () => useMutationDeleteModel(QUERY_KEY);

export const useMutationBulkDeleteProductSoldBy = () => useMutationBulkDeleteModelV2(QUERY_KEY);
