import React, { Suspense } from "react";

import styled from "@emotion/styled";
import { useParams } from "react-router-dom";
import {
  Alert as MuiAlert,
  Box,
  Card,
  Divider as MuiDivider,
  Stack,
  Tab,
  Tabs,
  Typography
} from "@mui/material";
import { spacing } from "@mui/system";
import { useQueryOneCustomer } from "../../api/Customer";
import PageTitleWithLoading from "../../components/PageTitleWithLoading";
import CustomerDetailsCardV2 from "./CustomerDetailsCardV2";
import CustomerStatisticsCard from "./CustomerStatisticsCard.tsx";
import { PrintDebtorStatement } from "./PrintDebtorStatement.tsx";
import OrderListWithQuery from "./OrderListByQuery";
import Loader from "../../components/Loader.tsx";
import useSetPageTitle from "../../hooks/useSetPageTitle.ts";
import EventsTimelineCard from "./EventLogsCard.tsx";
import { Can } from "../../casl.ts";
import LeadListWithQuery from "../lead/LeadListByQuery.tsx";

const Divider = styled(MuiDivider)(spacing);
const Alert = styled(MuiAlert)(spacing);

function CustomerDetails() {
  const { uuid } = useParams<string>();
  const { data } = useQueryOneCustomer(uuid);
  useSetPageTitle("Customer");
  const [tab, setTab] = React.useState("leads");

  const handleTabsOnChange = (event: React.SyntheticEvent, value: string) => {
    setTab(value);
  };

  const ordersFilterItems = [
    {
      id: "customer.uuid",
      field: "customer.uuid",
      operator: "equals",
      value: uuid
    },
    {
      id: "order_type",
      field: "order_type",
      operator: "is",
      value: "order"
    },
    {
      id: "is_invoiced_at",
      field: "is_invoiced_at",
      operator: "isEmpty"
    }
  ];

  const invoicedFilterItems = [
    {
      id: "customer.uuid",
      field: "customer.uuid",
      operator: "equals",
      value: uuid
    },
    {
      id: "is_invoiced_at",
      field: "is_invoiced_at",
      operator: "isNotEmpty"
    }
  ];

  const serviceFilterItems = [
    {
      id: "customer.uuid",
      field: "customer.uuid",
      operator: "equals",
      value: uuid
    },
    {
      id: "order_type",
      field: "order_type",
      operator: "is",
      value: "service"
    },
    {
      id: "is_invoiced_at",
      field: "is_invoiced_at",
      operator: "isEmpty"
    }
  ];

  const warrantyFilterItems = [
    {
      id: "customer.uuid",
      field: "customer.uuid",
      operator: "equals",
      value: uuid
    },
    {
      id: "order_type",
      field: "order_type",
      operator: "is",
      value: "warranty"
    },
    {
      id: "is_invoiced_at",
      field: "is_invoiced_at",
      operator: "isEmpty"
    }
  ];

  const quotesFilterItems = [
    {
      id: "customer.uuid",
      field: "customer.uuid",
      operator: "equals",
      value: uuid
    },
    {
      id: "order_type",
      field: "order_type",
      operator: "is",
      value: "quote"
    },
    {
      id: "is_quote_finalized_at",
      field: "is_quote_finalized_at",
      operator: "isNotEmpty"
    }
  ];

  const leadsFilterItems = [
    {
      id: "customer.uuid",
      field: "customer.uuid",
      operator: "equals",
      value: uuid
    }
  ];

  return (
    <>
      <Stack direction="row" spacing={6} alignItems={"center"} useFlexGap flexWrap="wrap">
        <PageTitleWithLoading title={data?.email} />
        <Stack
          direction="row"
          spacing={2}
          divider={<span>|</span>}
          alignItems="center"
          useFlexGap
          flexWrap="wrap"
          marginLeft={20}
          mx={2}
          pt={3}
        >
          <Typography variant="h4">{data?.customer_group?.name}</Typography>
          <Typography
            variant="h4"
            textTransform={data?.customer_type === "cod" ? "uppercase" : "capitalize"}
          >
            {data?.customer_type}
          </Typography>
        </Stack>
      </Stack>

      <Divider my={6} />

      {Boolean(data?.is_tester) && (
        <Alert severity="warning" my={4}>
          Payments related to this customer will be marked as TEST mode
        </Alert>
      )}

      <Stack
        direction="row"
        spacing={6}
        justifyContent="flex-start"
        alignItems="stretch"
        useFlexGap
        flexWrap="wrap"
      >
        <Card>
          <CustomerDetailsCardV2 hideDetailsButton />
        </Card>
        <Card
          sx={{
            display: "grid",
            justifyContent: "space-between",
            alignItems: "self-end"
          }}
        >
          <Card>
            <Suspense fallback={<Loader />}>
              <CustomerStatisticsCard customer_uuid={data?.uuid} />
            </Suspense>
          </Card>
          <PrintDebtorStatement customer_uuid={data?.uuid} />
        </Card>
      </Stack>
      <Card
        sx={{
          mb: 3,
          mt: 3,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <Tabs value={tab} onChange={handleTabsOnChange}>
          <Tab iconPosition="end" label="Leads" value="leads" />
          <Tab iconPosition="end" label="Orders" value="orders" />
          <Tab iconPosition="end" label="Invoice" value="invoiced" />

          <Tab iconPosition="end" label="Service" value="service" />
          <Tab iconPosition="end" label="Warranty" value="warranty" />
          <Tab iconPosition="end" label="Quotes" value="quotes" />
        </Tabs>
      </Card>

      <Suspense fallback={<Loader />}>
        {
          {
            orders: <OrderListWithQuery key="orders" upsertFilterItems={ordersFilterItems} />,
            invoiced: <OrderListWithQuery key="invoiced" upsertFilterItems={invoicedFilterItems} />,
            service: <OrderListWithQuery key="service" upsertFilterItems={serviceFilterItems} />,
            warranty: <OrderListWithQuery key="warranty" upsertFilterItems={warrantyFilterItems} />,
            quotes: <OrderListWithQuery key="quotes" upsertFilterItems={quotesFilterItems} />,
            leads: (
              <LeadListWithQuery upsertFilterItems={leadsFilterItems} isInCustomerDetailsPage />
            )
          }[tab]
        }
      </Suspense>

      <Can I="Read" a="event_store">
        <Box sx={{ mt: 4 }}>
          <EventsTimelineCard stream="customers" aggregate_id={uuid} />
        </Box>
      </Can>
    </>
  );
}

export default CustomerDetails;
