import { Dispatch, SetStateAction, createContext, useState } from "react";
import { Helmet } from "react-helmet-async";

const PageTitleContext = createContext<
  | {
      pageTitle?: string;
      setPageTitle: Dispatch<SetStateAction<string | undefined>>;
    }
  | undefined
>(undefined);

interface PageTitleProviderProps {
  children: React.ReactNode;
}

function PageTitleProvider({ children }: PageTitleProviderProps) {
  const [pageTitle, setPageTitle] = useState<string | undefined>();

  return (
    <PageTitleContext.Provider value={{ pageTitle, setPageTitle }}>
      <Helmet title={pageTitle} />

      {children}
    </PageTitleContext.Provider>
  );
}

export { PageTitleProvider, PageTitleContext };
