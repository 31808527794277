import SignIn from "./SignIn";
import SetPassword from "./SetPassword";

const routes = [
  {
    path: "sign-in",
    element: <SignIn />
  },
  {
    path: "set-password/:uuid",
    element: <SetPassword />
  }
];

export default routes;
