import { useParams } from "react-router-dom";
import Loader from "../../components/Loader";
import {
  Box,
  Button,
  Card as MuiCard,
  CardActions,
  CardContent,
  Divider as MuiDivider,
  Stack,
  Typography as MuiTypography
} from "@mui/material";
import React from "react";
import styled from "@emotion/styled";
import { spacing, SpacingProps } from "@mui/system";
import { TableStyled } from "../../components/TableStyled";
import { useQueryOneInventoryItem } from "../../api/InventoryItem";
import { useXlsxRead } from "../../hooks/useXlsxRead";
import { useXlsxSheetToHTML } from "../../hooks/useXlsxSheetToHTML";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Spacer = styled.div(spacing);

interface TypographyProps extends SpacingProps {
  component?: string;
}
const Typography = styled(MuiTypography)<TypographyProps>(spacing);

function PriceGridCard({ inventory_item_uuid }: { inventory_item_uuid?: string }) {
  const { uuid } = useParams();
  const { data: inventoryItem, isFetching } = useQueryOneInventoryItem(inventory_item_uuid ?? uuid);
  const location_url = inventoryItem?.price_grid_active?.file?.location_url;
  const { data: xlsxWorkbook } = useXlsxRead(location_url);
  const { data: xlsxHTMLContent } = useXlsxSheetToHTML(
    xlsxWorkbook?.SheetNames?.[1],
    xlsxWorkbook?.Sheets?.[xlsxWorkbook?.SheetNames?.[1]]
  );

  if (isFetching) {
    return <Loader />;
  }

  return (
    <Card>
      <CardContent sx={{ maxHeight: "400px", overflowY: "auto" }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">Price Grid</Typography>
        </Stack>

        <Divider mb={5} mt={2} />

        <Spacer mb={4} />

        <Box sx={{ overflowY: "auto", width: "100%" }}>
          {xlsxHTMLContent && <TableStyled dangerouslySetInnerHTML={{ __html: xlsxHTMLContent }} />}
        </Box>
      </CardContent>

      {!!location_url && (
        <CardActions>
          <Button variant="contained" size="small" href={location_url} download>
            Download current
          </Button>
        </CardActions>
      )}
    </Card>
  );
}

export default PriceGridCard;
