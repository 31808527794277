import { toDecimalFromFloat } from "aldrin-erp-main-shared-functions";
import { useDataGridFilterModelQueryParams } from "../../hooks/useDataGridFilterModelQueryParams";
import { gridFilterModelSelector, useGridApiContext, useGridSelector } from "@mui/x-data-grid-pro";
import { useQueryOrdersStatistics } from "../../api/Order";
import { TextField } from "@mui/material";
import { useQueryParamAvailability } from "./OrderList.tsx";

export const ToolbarStatistics = ({
  additionalQueryParams
}: {
  additionalQueryParams?: Record<string, string | undefined | null>;
}) => {
  const apiRef = useGridApiContext();
  const filterModel = useGridSelector(apiRef, gridFilterModelSelector);
  const filterQueryParams = useDataGridFilterModelQueryParams(filterModel);
  const [availability] = useQueryParamAvailability();
  const { data: stats } = useQueryOrdersStatistics({
    ...filterQueryParams,
    ...additionalQueryParams,
    availability
  });

  return (
    <>
      <TextField
        label="Total Value"
        inputProps={{
          readOnly: true
        }}
        InputLabelProps={{
          shrink: true
        }}
        InputProps={{
          disableUnderline: true
        }}
        value={toDecimalFromFloat(Number(stats?.totalValue) ?? 0.0)}
        size="small"
        variant="standard"
        sx={{ width: 100 }}
      />
    </>
  );
};
