import React from "react";
import styled from "@emotion/styled";
import { Divider as MuiDivider, Paper as MuiPaper } from "@mui/material";
import { spacing } from "@mui/system";
import { useMutationEditCustomer } from "../../api/Customer";
import CustomerForm from "./CustomerForm";
import PageTitleWithLoading from "../../components/PageTitleWithLoading";
import { useParams } from "react-router-dom";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const CustomerEdit = () => {
  const { uuid } = useParams<string>();

  const mutation = useMutationEditCustomer(uuid ?? "");

  return (
    <Paper p={4}>
      <PageTitleWithLoading title="Edit Customer" />

      <Divider my={6} />

      <CustomerForm mutation={mutation} uuid={uuid} isEdit={true} />
    </Paper>
  );
};

export default CustomerEdit;
