import React, { useEffect } from "react";
import { useSnackbar } from "notistack";
import { useQueryHealthChecks } from "../api/HealthChecker";
import { useLocation } from "react-router-dom";

interface HealthCheckerType {
  status: "ok" | "error";
  // healthChecksStatus: string[];
  // mainEventHandlerStatus:
  //   | "online"
  //   | "stopping"
  //   | "stopped"
  //   | "launching"
  //   | "errored"
  //   | "one-launch-status";
  isSuspendMutations: boolean;
}

const HealthCheckerContext = React.createContext<HealthCheckerType | undefined>(undefined);

interface HealthCheckerProviderProps {
  children: React.ReactNode;
}

function HealthCheckerProvider({ children }: HealthCheckerProviderProps) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { data, isSuccess } = useQueryHealthChecks();
  const isSuspendMutations = data?.details
    ?.filter((check: any) => ["evtstore-in-sync-main", "database"].includes(check.name))
    ?.reduce((unhealthy: boolean, check: any) => {
      return unhealthy || check.status !== "fulfilled";
    }, !isSuccess);

  const status = !isSuspendMutations ? "ok" : "error";
  const location = useLocation();

  useEffect(() => {
    if (
      !isSuspendMutations ||
      location.pathname.startsWith("/guest/order") ||
      location.pathname.startsWith("/pages")
    ) {
      closeSnackbar("suspend-mutations");
      return;
    }

    enqueueSnackbar(
      "Modifications are temporarily suspended as we are trying to fix something in the backend. For now, you can only view data.",
      {
        key: "suspend-mutations",
        variant: "warning",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center"
        },
        persist: true,
        preventDuplicate: true
      }
    );
  }, [location, isSuspendMutations, enqueueSnackbar, closeSnackbar]);

  return (
    <HealthCheckerContext.Provider value={{ status, isSuspendMutations }}>
      {children}
    </HealthCheckerContext.Provider>
  );
}

export { HealthCheckerProvider, HealthCheckerContext };
