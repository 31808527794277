import PageList from "./PageList";
import PageAdd from "./PageAdd";
import PageEdit from "./PageEdit";

const routes = [
  {
    path: "/pages",
    element: <PageList />
  },
  {
    path: "/pages/add",
    element: <PageAdd />
  },
  {
    path: "/pages/:uuid/edit",
    element: <PageEdit />
  }
];

export default routes;
