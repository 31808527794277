import React from "react";
import { Card } from "@mui/material";
import useSetPageTitle from "../../hooks/useSetPageTitle.ts";
import { DataGridServerIndexBasedPaginated } from "../../components/data-grid-v2/DataGridServerIndexBasedPaginated.tsx";
import { DataGridToolbar } from "./DataGridToolbar.tsx";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import { useOrderListColumns } from "./components/useOrderListColumns.tsx";

export const useQueryParamAvailability = () =>
  useQueryParam("availability", withDefault(StringParam, null), {
    removeDefaultsFromUrl: true
  });

const OrderList = () => {
  useSetPageTitle("Orders");

  const [availability] = useQueryParamAvailability();

  const columns = useOrderListColumns({ availability });

  return (
    <Card sx={{ height: "87vh" }}>
      <DataGridServerIndexBasedPaginated
        apiEndpoint="order"
        autosizeOnMount={false}
        initialState={{
          columns: {
            columnVisibilityModel: {
              is_quote_finalized_at: false,
              "customer.uuid": false,
              "customer.customer_group.id": false,
              created_at: true,
              is_invoiced_at: false,
              quote_accepted_at: false,
              active: false,
              deleted_at: false,
              "sales_rep.uuid": false
            }
          },
          sorting: {
            sortModel: [
              {
                field: "reference_number",
                sort: "desc"
              }
            ]
          }
        }}
        additionalQueryParams={{
          availability
        }}
        columns={columns}
        checkboxSelection={false}
        slots={{
          toolbar: DataGridToolbar
        }}
      />
    </Card>
  );
};

export default OrderList;
