import { openobserveRum } from "@openobserve/browser-rum";
import { useEffect, useRef } from "react";
import { useIdle } from "@uidotdev/usehooks";

export const useOpenObserveRecordSessionReplay = () => {
  const idle = useIdle(30000);
  const startedRef = useRef(idle);

  useEffect(() => {
    if (!idle) {
      !startedRef.current && openobserveRum.startSessionReplayRecording();
      startedRef.current = true;
    }

    return () => {
      startedRef.current && openobserveRum.stopSessionReplayRecording();
      startedRef.current = false;
    };
  }, [idle]);
};
